import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { getCookie } from '../utils/helpers';
var AxiosInstance = axios.create({
    // Change it back to "/" after setting  docker service for server"
    baseURL: process.env.REACT_APP_BASE_API_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});
AxiosInstance.interceptors.response.use(function (response) { return response.data; }, function (error) {
    if (error.message === 'Request aborted') {
        return Promise.reject(error.message);
    }
    Sentry.captureException(error.response || error.message);
    return Promise.reject(error.response || error.message);
});
AxiosInstance.interceptors.request.use(function (config) {
    var newConfig = config;
    if (config.method === 'post')
        newConfig.headers.common['X-CSRFToken'] = getCookie('csrftoken');
    return newConfig;
}, function (error) {
    Sentry.captureException(error);
    return Promise.reject(error);
});
export default AxiosInstance;
