var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function attr_4(props) {
    // flower color
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 65 65" }),
        React.createElement("path", { d: "M57.1,25.6 C61.1,25.6 64.2,28.8 64.2,32.7 C64.2,36.6 61,39.8 57.1,39.8 C53.1,39.8 35.9,32.7 35.9,32.7 C35.9,32.7 53.2,25.6 57.1,25.6 Z", id: "Shape" }),
        React.createElement("path", { d: "M44.7,10.1 C47.5,7.3 52,7.3 54.8,10.1 C57.6,12.9 57.6,17.4 54.8,20.2 C52,23 34.8,30.1 34.8,30.1 C34.8,30.1 41.9,12.8 44.7,10.1 Z", id: "Shape" }),
        React.createElement("path", { d: "M54.8,45.2 C57.6,48 57.6,52.5 54.8,55.3 C52,58.1 47.5,58.1 44.7,55.3 C41.9,52.5 34.8,35.4 34.8,35.4 C34.8,35.4 52,42.5 54.8,45.2 Z", id: "Shape" }),
        React.createElement("path", { d: "M25,7.8 C25,3.9 28.2,0.7 32.1,0.7 C36,0.7 39.2,3.9 39.2,7.8 C39.2,11.7 32.1,28.9 32.1,28.9 C32.1,28.9 25,11.7 25,7.8 Z", id: "Shape" }),
        React.createElement("path", { d: "M25.9,39.2 C23.2,45.3 19.8,52.5 18.3,53.9 C17.3,54.9 16,55.4 14.7,55.4 C13.3,55.4 12,54.9 11.1,53.9 C10.1,52.9 9.6,51.7 9.6,50.3 C9.6,48.9 10.1,47.7 11.1,46.7 C12.5,45.3 19.7,41.9 25.9,39.2 L25.9,39.2 Z M29.7,35.4 C29.7,35.4 12.5,42.5 9.7,45.3 C6.9,48.1 6.9,52.6 9.7,55.3 C11.1,56.7 12.9,57.4 14.7,57.4 C16.5,57.4 18.3,56.7 19.7,55.3 C22.5,52.5 29.7,35.4 29.7,35.4 L29.7,35.4 Z", id: "Shape" }),
        React.createElement("path", { d: "M7.4,27.6 C9.4,27.6 16.9,30.3 23.2,32.7 C16.9,35.1 9.4,37.8 7.4,37.8 C4.6,37.8 2.2,35.5 2.2,32.7 C2.3,29.9 4.6,27.6 7.4,27.6 L7.4,27.6 Z M7.4,25.6 C3.4,25.6 0.2,28.8 0.2,32.7 C0.2,36.6 3.4,39.8 7.4,39.8 C11.4,39.8 28.6,32.7 28.6,32.7 C28.6,32.7 11.4,25.6 7.4,25.6 L7.4,25.6 Z", id: "Shape" }),
        React.createElement("path", { d: "M32.4,41.8 C34.9,48 37.6,55.5 37.6,57.5 C37.6,60.3 35.3,62.6 32.4,62.6 C29.6,62.6 27.2,60.3 27.2,57.5 C27.3,55.5 30,48 32.4,41.8 L32.4,41.8 Z M32.4,36.5 C32.4,36.5 25.2,53.6 25.2,57.6 C25.2,61.6 28.4,64.7 32.4,64.7 C36.4,64.7 39.6,61.5 39.6,57.6 C39.6,53.7 32.4,36.5 32.4,36.5 L32.4,36.5 Z", id: "Shape" }),
        React.createElement("path", { d: "M14.7,10 C16.1,10 17.4,10.5 18.3,11.5 C19.7,12.9 23.2,20.1 25.9,26.2 C19.7,23.5 12.5,20.1 11.1,18.7 C10.1,17.7 9.6,16.5 9.6,15.1 C9.6,13.7 10.1,12.5 11.1,11.5 C12,10.5 13.3,10 14.7,10 L14.7,10 Z M14.7,8 C12.9,8 11.1,8.7 9.7,10.1 C6.9,12.9 6.9,17.4 9.7,20.1 C12.5,22.8 29.7,30 29.7,30 C29.7,30 22.5,12.9 19.8,10.1 C18.3,8.7 16.5,8 14.7,8 L14.7,8 Z", id: "Shape" })));
}
