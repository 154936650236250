var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function dappled_shade(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 65 65" }),
        React.createElement("circle", { id: "Oval", cx: "33.1", cy: "3.3", r: "2.9" }),
        React.createElement("ellipse", { id: "Oval_1_", transform: "matrix(0.866 -0.5 0.5 0.866 -1.1101 10.2209)", cx: "18.5", cy: "7.2", rx: "2.9", ry: "2.9" }),
        React.createElement("ellipse", { id: "Oval_2_", transform: "matrix(0.5 -0.866 0.866 0.5 -11.4934 15.6537)", cx: "7.8", cy: "17.8", rx: "2.9", ry: "2.9" }),
        React.createElement("circle", { id: "Oval_3_", cx: "3.8", cy: "32.3", r: "2.9" }),
        React.createElement("ellipse", { id: "Oval_4_", transform: "matrix(0.866 -0.5 0.5 0.866 -22.4038 10.1307)", cx: "7.7", cy: "46.9", rx: "2.9", ry: "2.9" }),
        React.createElement("ellipse", { id: "Oval_5_", transform: "matrix(0.5 -0.866 0.866 0.5 -40.7178 44.6251)", cx: "18.3", cy: "57.6", rx: "2.9", ry: "2.9" }),
        React.createElement("circle", { id: "Oval_6_", cx: "32.8", cy: "61.6", r: "2.9" }),
        React.createElement("ellipse", { id: "Oval_7_", transform: "matrix(0.866 -0.5 0.5 0.866 -22.4941 31.4244)", cx: "47.4", cy: "57.7", rx: "2.9", ry: "2.9" }),
        React.createElement("ellipse", { id: "Oval_8_", transform: "matrix(0.5 -0.866 0.866 0.5 -11.7464 73.8495)", cx: "58.1", cy: "47.1", rx: "2.9", ry: "2.9" }),
        React.createElement("circle", { id: "Oval_9_", cx: "62.1", cy: "32.6", r: "2.9" }),
        React.createElement("ellipse", { id: "Oval_10_", transform: "matrix(0.866 -0.5 0.5 0.866 -1.2004 31.5147)", cx: "58.2", cy: "18", rx: "2.9", ry: "2.9" }),
        React.createElement("ellipse", { id: "Oval_11_", transform: "matrix(0.5 -0.866 0.866 0.5 17.4781 44.8781)", cx: "47.6", cy: "7.3", rx: "2.9", ry: "2.9" }),
        React.createElement("path", { id: "Shape", d: "M55.2,30.3c-1.1-11-10.2-19.7-21.4-20.2c-0.3,0-0.6,0-0.8,0c-4.9,0-9.5,1.6-13.2,4.3c-1,0.7-1.9,1.5-2.8,2.4\n    c-1.3,1.3-2.4,2.8-3.3,4.3c-0.5,0.8-0.9,1.6-1.2,2.4c-0.6,1.4-1.1,2.8-1.4,4.3c-0.2,0.8-0.3,1.6-0.4,2.4c-0.1,0.7-0.1,1.4-0.1,2.2\n    c0,0.8,0,1.5,0.1,2.2C11.8,45.9,21.4,54.8,33,54.8c0.3,0,0.6,0,0.8,0c4.6-0.2,8.8-1.7,12.3-4.3c1-0.7,1.9-1.5,2.8-2.4\n    c1.3-1.3,2.4-2.8,3.3-4.3c0.5-0.8,0.9-1.6,1.2-2.4c0.6-1.4,1.1-2.8,1.4-4.3c0.2-0.8,0.3-1.6,0.4-2.4c0.1-0.7,0.1-1.4,0.1-2.2\n    S55.3,31,55.2,30.3z M50.4,37H33.8v4.3h14.8c-0.5,0.8-1,1.7-1.6,2.4H33.8V48h8.1c-2.6,1.5-5.7,2.4-9,2.4\n    c-9.2,0-16.8-6.9-17.9-15.9h35.8C50.7,35.4,50.6,36.2,50.4,37z M15,30.3c0.1-0.8,0.3-1.6,0.5-2.4h18.3v-4.3H17.3\n    c0.5-0.8,1-1.7,1.6-2.4h14.9v-4.3H24c2.6-1.5,5.7-2.4,9-2.4c9.2,0,16.8,6.9,17.9,15.9H15V30.3z" })));
}
