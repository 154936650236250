import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
var useStyles = makeStyles(function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var palette = _a.palette, spacing = _a.spacing, breakpoints = _a.breakpoints;
    return createStyles({
        mainWrapper: {
            height: 'auto',
            marginTop: '1.5em',
        },
        containerGrid: (_b = {
                width: '100%',
                maxWidth: 1104,
                textAlign: 'center',
                margin: 'auto',
                padding: spacing(0, 3.75),
                paddingBottom: 0
            },
            _b[breakpoints.down('xxsMin')] = {
                padding: spacing(0, 2),
            },
            _b[breakpoints.up('lg')] = {
                padding: 0,
            },
            _b[breakpoints.between('xsMedMax', 'sm')] = {
                maxWidth: 768,
            },
            _b),
        promotionsContainer: (_c = {
                margin: spacing(0, 'auto'),
                display: 'flex',
                flexWrap: 'wrap',
                maxWidth: 1104,
                padding: 0
            },
            _c[breakpoints.down('xsMax')] = {
                padding: 0,
                position: 'relative',
                zIndex: 1,
                marginTop: spacing(6.875),
            },
            _c),
        promotionLink: {
            display: 'block',
            position: 'relative',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '100%',
            margin: 0,
        },
        promotionFirst: (_d = {
                height: 550,
                marginBottom: spacing(2),
                borderBottomRightRadius: 4,
                borderBottomLeftRadius: 4,
                overflow: 'hidden'
            },
            _d[breakpoints.up('sm')] = {
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4,
            },
            _d[breakpoints.between('xsMedMax', 'sm')] = {
                height: 400,
            },
            _d[breakpoints.down('xsMedMin')] = {
                height: 320,
            },
            _d),
        promotionRest: (_e = {
                width: 'calc(50% - 8px) !important',
                height: 300,
                marginBottom: spacing(2),
                borderRadius: 4,
                overflow: 'hidden',
                flexBasis: 'unset',
                WebkitFlexBasis: 'unset',
                '&:nth-child(even)': {
                    marginRight: spacing(1),
                },
                '&:nth-child(odd)': {
                    marginLeft: spacing(1),
                }
            },
            _e[breakpoints.down('xsMax')] = {
                height: 200,
            },
            _e),
        promotionTitle: (_f = {
                backgroundColor: "".concat(palette.background.default, "B3"),
                padding: '0.5em',
                position: 'absolute',
                right: 0,
                bottom: '0.5em',
                fontSize: spacing(3),
                lineHeight: 1.2,
                letterSpacing: '0.05em',
                fontWeight: 300,
                color: palette.primary.dark,
                width: '50%',
                textAlign: 'right'
            },
            _f[breakpoints.down('xsMax')] = {
                bottom: 0,
                fontSize: spacing(2.125),
                width: '100%',
                height: 70,
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
            },
            _f),
        promotionTitleFirst: (_g = {
                height: '170px',
                bottom: '2.5em',
                fontSize: '2em',
                maxWidth: 'fit-content',
                boxSizing: 'inherit'
            },
            _g[breakpoints.up('sm')] = {
                fontSize: '3em',
                bottom: '1.5em',
            },
            _g[breakpoints.down('xsMedMin')] = {
                fontSize: '2em',
                bottom: '3em',
            },
            _g[breakpoints.up('lg')] = {
                bottom: '3em',
            },
            _g[breakpoints.between('xsMin', 'xsMax')] = {
                height: 'auto !important',
            },
            _g),
        featuredPlantsWrapper: {
            width: '100%',
        },
        browsePlantsText: (_h = {
                fontSize: spacing(3),
                margin: spacing(6, 0, 2.5, 0),
                color: palette.primary.light,
                fontWeight: 300,
                textAlign: 'left'
            },
            _h[breakpoints.down('xsMax')] = {
                margin: spacing(2.5, 0, 4, 0),
                fontSize: spacing(2.5),
            },
            _h),
        featuredPlantsContainer: (_j = {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'stretch',
                margin: spacing(2.5, -1, 4, -1)
            },
            _j[breakpoints.between('xsMin', 'xsMax')] = {
                margin: spacing(2.5, 0, 4, 0),
                display: 'block',
            },
            _j),
        featuredPlant: (_k = {
                width: '25%'
            },
            _k[breakpoints.down('mdMax')] = {
                width: '33%',
            },
            _k[breakpoints.between('xsMin', 'xsMax')] = {
                width: '100%',
            },
            _k),
        featuredPlantLink: (_l = {
                background: palette.background.default,
                borderRadius: 5,
                color: "".concat(palette.primary.dark, " !important"),
                display: 'block',
                fontSize: '1.1em',
                height: 80,
                margin: spacing(1),
                overflow: 'hidden',
                position: 'relative',
                textAlign: 'left',
                border: "1px solid ".concat(palette.secondary.contrastText)
            },
            _l[breakpoints.down('xsMax')] = {
                height: 'auto',
            },
            _l[breakpoints.between('xsMin', 'xsMax')] = {
                margin: spacing(0, 0, 1, 0),
                '&:hover': {
                    '& $arrow': {
                        borderLeftColor: palette.orangeColors.light,
                    },
                },
            },
            _l[breakpoints.between('sm', 'mdMax')] = {
                height: 60,
                fontSize: '0.9em !important',
            },
            _l),
        featuredPlantImage: (_m = {
                height: '100%',
                float: 'left'
            },
            _m[breakpoints.down('xsMax')] = {
                height: 'auto',
                width: '100%',
                float: 'none',
            },
            _m[breakpoints.between('xsMin', 'xsMax')] = {
                display: 'none',
            },
            _m),
        tableDiv: (_o = {
                height: '100%',
                display: 'table'
            },
            _o[breakpoints.down('xsMax')] = {
                padding: spacing(1, 1, 2, 1),
                display: 'block',
                marginTop: -2,
                fontSize: spacing(1.5),
                fontWeight: 'lighter',
            },
            _o[breakpoints.between('xsMin', 'xsMax')] = {
                marginTop: 0,
                padding: spacing(2),
            },
            _o),
        cellDiv: {
            display: 'table-cell',
            verticalAlign: 'middle',
        },
        contentDiv: (_p = {
                fontWeight: 'lighter',
                padding: spacing(0, 1)
            },
            _p[breakpoints.down('xsMax')] = {
                fontSize: '14px !important',
                padding: 0,
            },
            _p),
        arrow: (_q = {},
            _q[breakpoints.between('xsMin', 'xsMax')] = {
                position: 'absolute',
                borderLeft: "7px solid ".concat(palette.greyColors.dark),
                borderTop: '5px solid transparent',
                borderBottom: '5px solid transparent',
                display: 'block',
                width: 0,
                height: 0,
                right: spacing(1.5),
                top: spacing(2.25),
            },
            _q),
        categoryButtonContainer: {
            display: 'flex',
            justifyContent: 'center',
            margin: spacing(2.5, 0),
        },
        promotionImage: {
            width: '100%',
            height: 'auto',
            display: 'block',
        },
    });
});
export default useStyles;
