var classes = {
    plantInfoWrapper: function (theme) { return ({
        position: 'relative',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
    }); },
    plantInfoWrapperItems: function (theme) { return ({
        paddingBottom: theme.spacing(9),
    }); },
    plantImage: function (theme) { return ({
        backgroundColor: 'transparent',
        width: 83,
        height: 100,
    }); },
    plantInfo: function (theme) {
        var _a;
        return (_a = {
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                paddingLeft: theme.spacing(1.75)
            },
            _a[theme.breakpoints.down('sm')] = {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                paddingLeft: theme.spacing(1.75),
            },
            _a);
    },
    plantInfoCart: function (theme) { return ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(1.75),
    }); },
    plantName: function (theme) { return ({
        color: theme.palette.primary.dark,
        padding: 0,
        fontSize: theme.spacing(2.25),
        fontWeight: 700,
        textTransform: 'none',
        textAlign: 'left',
        lineHeight: '23px',
        letterSpacing: '0.06em',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    }); },
    plantSize: function (theme) { return ({
        color: theme.palette.blueColors.main,
        paddingTop: theme.spacing(0.75),
        fontSize: theme.spacing(1.75),
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0.06em',
    }); },
    removeButton: function (theme) { return ({
        padding: 0,
        marginTop: theme.spacing(1.5),
        textTransform: 'none',
        color: theme.palette.blueColors.main,
        fontWeight: 400,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    }); },
    quantityPrice: function (theme) {
        var _a;
        return (_a = {
                flexDirection: 'row',
                justifyContent: 'space-between'
            },
            _a[theme.breakpoints.down('sm')] = {
                justifyContent: 'flex-start',
                paddingTop: theme.spacing(2.5),
            },
            _a);
    },
    quantityPriceCart: function (theme) { return ({
        justifyContent: 'flex-start',
        paddingTop: theme.spacing(2.5),
    }); },
    plantPrice: function (theme) {
        var _a;
        return (_a = {
                color: theme.palette.primary.dark,
                fontSize: theme.spacing(1.75),
                fontWeight: 400,
                lineHeight: '30px'
            },
            _a[theme.breakpoints.down('sm')] = {
                paddingLeft: theme.spacing(5),
                color: theme.palette.primary.dark,
                fontSize: theme.spacing(1.75),
                fontWeight: 400,
                lineHeight: '30px',
            },
            _a);
    },
    plantPriceCart: function (theme) { return ({
        paddingLeft: theme.spacing(5),
        color: theme.palette.primary.dark,
        fontSize: theme.spacing(1.75),
        fontWeight: 400,
        lineHeight: '30px',
    }); },
};
export default classes;
