var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function attr_14(props) {
    // flower season
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 68 62" }),
        React.createElement("path", { d: "M66.3,61.8h-64c-0.8,0-1.5-0.7-1.5-1.5v-58c0-0.8,0.7-1.5,1.5-1.5h64c0.8,0,1.5,0.7,1.5,1.5v58\n  C67.8,61.1,67.1,61.8,66.3,61.8z M3.8,58.8h61v-55h-61V58.8z" }),
        React.createElement("path", { d: "M66.3,14.1h-64c-0.8,0-1.5-0.7-1.5-1.5V2.3c0-0.8,0.7-1.5,1.5-1.5h64c0.8,0,1.5,0.7,1.5,1.5v10.3\n  C67.8,13.4,67.1,14.1,66.3,14.1z M3.8,11.1h61V3.8h-61V11.1z" }),
        React.createElement("path", { className: "st0", d: "M44.7,36.9c5.2-1.5,6.9-4.7,6.8-7.3c-0.1-2.8-2.5-5-5.4-5c-1.3,0-3.8,0.4-6.6,3.2c1-4,0-6.5-0.7-7.6\n  c-1-1.6-2.7-2.6-4.5-2.6c-1.8,0-3.5,1-4.5,2.6c-0.7,1.1-1.7,3.6-0.7,7.6c-2.9-2.7-5.3-3.2-6.6-3.2c-3,0-5.3,2.1-5.4,5\n  c-0.1,2.6,1.6,5.7,6.8,7.3c-5.2,1.5-6.9,4.7-6.8,7.3c0.1,2.8,2.5,5,5.4,5c1.3,0,3.8-0.4,6.6-3.2c-1,4,0,6.5,0.7,7.6\n  c1,1.6,2.7,2.6,4.5,2.6c1.8,0,3.5-1,4.5-2.6c0.7-1.1,1.7-3.6,0.7-7.6c2.9,2.7,5.3,3.2,6.6,3.2c3,0,5.3-2.1,5.4-5\n  C51.6,41.6,49.9,38.5,44.7,36.9z M39.4,36.9c0,2.9-2.3,5.2-5.2,5.2s-5.2-2.3-5.2-5.2s2.3-5.2,5.2-5.2S39.4,34.1,39.4,36.9z\n   M36.7,30.4c-1.6-0.6-3.4-0.6-5,0c-2.2-5.2-1.2-7.9-0.5-9.1c0.7-1.1,1.8-1.7,3-1.7s2.3,0.6,3,1.7C38,22.5,38.9,25.2,36.7,30.4z\n   M27.4,35.8c-6.5-0.8-8.7-3.8-8.6-6.1c0.1-1.9,1.6-3.3,3.6-3.3c1.4,0,4.2,0.7,7.4,5C28.5,32.6,27.6,34.1,27.4,35.8z M27.4,38.1\n  c0.3,1.7,1.1,3.2,2.5,4.3c-3.3,4.3-6.1,5-7.4,5c-2,0-3.5-1.4-3.6-3.3C18.7,41.8,20.9,38.9,27.4,38.1z M31.8,43.5\n  c1.6,0.6,3.4,0.6,5,0c2.2,5.2,1.2,7.9,0.5,9.1c-0.7,1.1-1.8,1.7-3,1.7s-2.3-0.6-3-1.7C30.5,51.4,29.6,48.7,31.8,43.5z M41.2,35.8\n  c-0.3-1.7-1.1-3.2-2.5-4.3c3.3-4.3,6.1-5,7.4-5c2,0,3.5,1.4,3.6,3.3C49.8,32.1,47.6,35,41.2,35.8z M41.2,38.1\n  c6.5,0.8,8.7,3.8,8.6,6.1c-0.1,1.9-1.6,3.3-3.6,3.3c-1.4,0-4.2-0.7-7.4-5C40,41.3,40.9,39.7,41.2,38.1z" }),
        React.createElement("ellipse", { className: "st0", cx: "34.3", cy: "37", rx: "4.8", ry: "4.8" })));
}
