var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
export default function MobileNavigation(props) {
    var navigation = props.navigation, mobileOpen = props.mobileOpen, navigationLinks = props.navigationLinks, setMobileOpen = props.setMobileOpen;
    var _a = React.useState(-1), mainNavItemHovered = _a[0], setMainNavItemHovered = _a[1];
    var _b = React.useState(-1), secondaryNavItemHovered = _b[0], setSecondaryNavItemHovered = _b[1];
    var _c = React.useState(mobileOpen), navMobileOpen = _c[0], setNavMbileOpen = _c[1];
    React.useEffect(function () {
        setNavMbileOpen(mobileOpen);
    }, [mobileOpen]);
    function handleNavItemClick(item) {
        window.location.href = item.url;
        setNavMbileOpen(false);
        setMobileOpen(false);
        document.body.style.overflow = 'auto';
    }
    function onNavLinkClick(link) {
        window.location.href = link;
        setNavMbileOpen(false);
        setMobileOpen(false);
        document.body.style.overflow = 'auto';
    }
    var listItemSX = {
        py: 1.5,
        px: 3.5,
        justifyContent: 'space-between',
    };
    var primaryTypographySX = {
        color: 'primary.light',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.28,
        letterSpacing: '0.06em',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    };
    return (React.createElement(Collapse, { in: navMobileOpen, timeout: 100, unmountOnExit: true, sx: {
            bgcolor: 'background.paper',
            position: 'fixed',
            width: '100%',
            zIndex: 1000,
            overflowY: 'scroll',
            maxHeight: '85%',
            mt: -0.125,
        } },
        React.createElement(List, { component: "nav", disablePadding: true }, navigation === null || navigation === void 0 ? void 0 : navigation.map(function (item, index) { return (React.createElement(React.Fragment, { key: item.key },
            React.createElement(Divider, { sx: { width: '100%', borderColor: 'secondary.dark' } }),
            React.createElement(ListItem, { disableGutters: true, sx: listItemSX, component: item.link !== '' ? 'a' : null, href: item.link },
                React.createElement(ListItemText, { primary: item.title, onClick: function () { return handleNavItemClick(item); }, primaryTypographyProps: {
                        noWrap: true,
                        sx: __assign(__assign({}, primaryTypographySX), { textTransform: 'uppercase' }),
                    } })))); })),
        React.createElement(Collapse, { in: mobileOpen, timeout: 100, unmountOnExit: true, sx: { bgcolor: 'background.paper' } },
            React.createElement(List, { component: "nav", disablePadding: true }, navigationLinks.map(function (navItem) { return (React.createElement(ListItem, { disableGutters: true, sx: listItemSX, key: navItem.link, onClick: function () { return onNavLinkClick(navItem.link); }, component: "a", href: navItem.link },
                React.createElement(ListItemText, { primary: navItem.text, primaryTypographyProps: {
                        noWrap: true,
                        sx: __assign(__assign({}, primaryTypographySX), { fontWeight: 700 }),
                    } }))); })))));
}
