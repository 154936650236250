import { createTheme } from '@mui/material/styles';
var white = '#FFFFFF';
var textBlack = '#000000';
var textDark = '#5B6A74';
var borderLight = '#D8D8D8';
var mainGrey = '#5C6A74';
var mainGreyLight = '#7E8B94';
var newGrey = '#F7F6F4';
var lightGrey = '#7F8B94';
var borderDark = '#979797';
var backgroundGrey = '#F0F0F0';
var darkGrey = '#333333';
var borderLightGrey = '#ECECEC';
var redColor = '#FF0000';
var mainGreyDark = '#404A52';
var darkBlue = '#586468';
var greyDark = '#5D6A75';
var backgroundLightGrey = '#C4C4C4';
var plOrange = '#E47736';
var textGrey = '#B1BCB6';
var orange = '#FF8000';
// const textLight = '#9b9b9b';
// const borderBlue = '#27AAE1';
// const borderGreen = '#6BC781';
// const green = '#34AB00';
// const orangeHover = '#ff9a32';
// const orangeActive = '#e36400';
// const background = '#EFF0F0';
// const bgLight = '#F1F2F2';
// const navBg = '#5c6a75';
// const linkBlue = '#5C6A75';
var theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            xxsMin: 320,
            xsMin: 321,
            xsMedMin: 568,
            xsMedMax: 569,
            xsMax: 767,
            sm: 768,
            mdMin: 939,
            md: 940,
            mdMedMin: 992,
            mdMedMax: 1104,
            mdMax: 1143,
            lg: 1144,
            xl: 1920,
        },
        step: 0,
    },
    typography: {
        fontFamily: 'system-ui, sans-serif',
    },
    palette: {
        primary: {
            main: textDark,
            dark: textBlack,
            light: mainGrey,
            contrastText: mainGreyLight,
        },
        secondary: {
            main: greyDark,
            light: lightGrey,
            dark: borderDark,
            contrastText: borderLight,
        },
        greyColors: {
            main: backgroundGrey,
            light: borderLightGrey,
            dark: darkGrey,
            contrastText: mainGreyDark,
        },
        newGreyColors: {
            main: backgroundLightGrey,
            light: textGrey,
        },
        redColors: {
            main: redColor,
        },
        blueColors: {
            main: darkBlue,
        },
        orangeColors: {
            main: plOrange,
            light: orange,
        },
        background: {
            default: white,
            paper: newGrey,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                lineHeight: 1,
            },
        },
    },
});
export default theme;
