var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import isEqual from 'lodash/isEqual';
import ShippingMethodsRadioGroup from './ShippingMethodsRadioGroup';
import CartPlant from './CartPlant';
import AvatarImage from '../CommonComponents/AvatarImage';
import CustomLoader from '../CommonComponents/CustomLoader';
import { numberFormat } from '../../utils/helpers';
var classes = {
    nurseryItemWrapper: function (theme) {
        var _a;
        return (_a = {
                marginBottom: theme.spacing(2),
                backgroundColor: theme.palette.background.default,
                padding: theme.spacing(5.5, 2, 2)
            },
            _a[theme.breakpoints.down('sm')] = {
                padding: theme.spacing(1, 2, 2),
            },
            _a);
    },
    nurseryInfo: function (theme) { return ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingLeft: theme.spacing(1.5),
    }); },
    nurseryName: function (theme) { return ({
        '&.MuiTypography-body1': {
            color: theme.palette.blueColors.main,
            fontSize: theme.spacing(2.75),
            fontWeight: 700,
            lineHeight: '30px',
            letterSpacing: '0.06em',
        },
    }); },
    plantPreviewImage: function (theme) { return ({
        width: 75,
        height: 75,
        fontSize: theme.spacing(2),
        fontWeight: 700,
        lineHeight: '20px',
        color: theme.palette.blueColors.main,
        backgroundColor: theme.palette.newGreyColors.main,
    }); },
    nurseryInfoHeader: function (theme) { return ({
        paddingBottom: theme.spacing(1.25),
        borderBottom: "1px solid ".concat(theme.palette.blueColors.main),
        marginBottom: theme.spacing(2),
        '&:hover': {
            cursor: 'pointer',
        },
    }); },
    nurseryShppingInfo: function (theme) { return ({
        '&.MuiTypography-body1': {
            color: theme.palette.blueColors.main,
            paddingTop: theme.spacing(0.75),
            fontSize: theme.spacing(1.75),
            fontWeight: 400,
            lineHeight: '18px',
            letterSpacing: '0.06em',
        },
    }); },
    divider: function (theme) { return ({
        margin: theme.spacing(1, 0),
        borderColor: theme.palette.blueColors.main,
        width: '100%',
    }); },
    nurserySubtotal: function (theme) { return ({
        fontSize: theme.spacing(2),
        color: theme.palette.blueColors.main,
        lineHeight: '20px',
        fontWeight: 400,
    }); },
    nurseryTotal: function (theme) { return ({
        fontSize: theme.spacing(2),
        color: theme.palette.blueColors.main,
        lineHeight: '20px',
        fontWeight: 700,
    }); },
    nurseryTotalPrice: function (theme) { return ({
        fontWeight: 700,
    }); },
    nurseryItemWrapperCart: function (theme) { return ({
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(1, 2, 2),
    }); },
};
var CartNursery = /** @class */ (function (_super) {
    __extends(CartNursery, _super);
    function CartNursery(props) {
        var _this = _super.call(this, props) || this;
        _this.setShippingPrice = function (price, indexValue) {
            var updateNursery = _this.props.updateNursery;
            var newState = __assign({}, _this.state);
            newState.nursery.selectedIndex = indexValue;
            newState.nursery.shippingPrice = price;
            _this.setState(newState);
            if (updateNursery)
                updateNursery(newState.nursery, newState.nursery.shippingPrice);
        };
        _this.removeItem = function (plant) { return __awaiter(_this, void 0, void 0, function () {
            var changeQuantity, nursery, newPlant;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        changeQuantity = this.props.changeQuantity;
                        nursery = this.state.nursery;
                        nursery.shippingLoading = true;
                        newPlant = plant;
                        newPlant.quantity = 0;
                        return [4 /*yield*/, changeQuantity(newPlant, nursery)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.goToPlant = function (plantURL) {
            window.location.href = plantURL;
        };
        _this.changeNurseryQuantity = function (plant) { return __awaiter(_this, void 0, void 0, function () {
            var changeQuantity, nursery;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        changeQuantity = this.props.changeQuantity;
                        nursery = this.state.nursery;
                        nursery.shippingLoading = true;
                        return [4 /*yield*/, changeQuantity(plant, nursery)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.state = {
            nursery: null,
        };
        return _this;
    }
    CartNursery.prototype.componentDidMount = function () {
        var _a = this.props, nursery = _a.nursery, shopifyCart = _a.shopifyCart, showTotals = _a.showTotals;
        this.setState({
            nursery: nursery,
            shopifyCart: shopifyCart,
            showTotals: showTotals,
        });
    };
    CartNursery.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        var _this = this;
        var updateNursery = this.props.updateNursery;
        if (!isEqual(this.props, nextProps)) {
            this.setState({
                nursery: nextProps.nursery,
                shopifyCart: nextProps.shopifyCart,
                showTotals: nextProps.showTotals,
            }, function () {
                if (updateNursery)
                    updateNursery(nextProps.nursery, nextProps.nursery.shippingPrice);
                if (!nextProps.nursery.shipping_options)
                    _this.setShippingPrice(0);
            });
        }
    };
    CartNursery.prototype.render = function () {
        var _this = this;
        var _a;
        var _b = this.state, nursery = _b.nursery, showTotals = _b.showTotals, shopifyCart = _b.shopifyCart;
        var selectedNurseries = this.props.selectedNurseries;
        if (nursery) {
            return (React.createElement(Grid, { container: true, justifyContent: "center", direction: "column", sx: [classes.nurseryItemWrapper,
                    shopifyCart && classes.nurseryItemWrapperCart] },
                React.createElement(Grid, { container: true, justifyContent: "flex-start", wrap: "nowrap", sx: classes.nurseryInfoHeader, onClick: function () { return _this.goToPlant("/nurseries/".concat(nursery.shopify_handle, "/")); } },
                    React.createElement(AvatarImage, { key: nursery.name, customClassName: classes.plantPreviewImage, src: nursery.avatar, alt: nursery.name }),
                    React.createElement(Grid, { sx: classes.nurseryInfo },
                        React.createElement(Typography, { sx: classes.nurseryName }, nursery.name),
                        React.createElement(Typography, { sx: classes.nurseryShppingInfo }, nursery.shipping_info_long !== '' ? nursery.shipping_info_long : nursery.shipping_info))),
                React.createElement(Grid, { container: true, justifyContent: "flex-start" }, (_a = nursery === null || nursery === void 0 ? void 0 : nursery.items) === null || _a === void 0 ? void 0 :
                    _a.map(function (plant) { return (React.createElement(CartPlant, { key: "".concat(plant.name, "-").concat(plant.shopify_title), plant: plant, shopifyCart: shopifyCart, goToPlant: _this.goToPlant, removeItem: _this.removeItem, changeNurseryQuantity: _this.changeNurseryQuantity })); }),
                    showTotals && nursery.shipping_options && (React.createElement(ShippingMethodsRadioGroup, { methods: nursery.shipping_options, setShippingPrice: this.setShippingPrice, selectedNurseries: selectedNurseries })),
                    React.createElement(Divider, { sx: classes.divider }),
                    React.createElement(Grid, { container: true, justifyContent: "space-around", direction: "column" },
                        React.createElement(Grid, { container: true, direction: "row", justifyContent: "space-between" },
                            React.createElement(Typography, { sx: classes.nurserySubtotal }, !showTotals ? "".concat(nursery.name, " subtotal") : 'Nursery subtotal'),
                            nursery.shippingLoading && !shopifyCart ? (React.createElement(CustomLoader, { key: nursery.name, loaderClassName: null, loaderAttributes: { size: 20, thickness: 5 } })) : (React.createElement(Typography, null,
                                "$",
                                numberFormat(nursery.subtotal)))),
                        showTotals && (React.createElement(React.Fragment, null,
                            React.createElement(Grid, { container: true, direction: "row", justifyContent: "space-between" },
                                React.createElement(Typography, { sx: classes.nurserySubtotal }, "Nursery shipping"),
                                nursery.shippingLoading ? (React.createElement(CustomLoader, { key: nursery.name, loaderClassName: null, loaderAttributes: { size: 20, thickness: 5 } })) : (React.createElement(Typography, null,
                                    "$",
                                    numberFormat(nursery.shippingPrice)))),
                            React.createElement(Grid, { container: true, direction: "row", justifyContent: "space-between" },
                                React.createElement(Typography, { sx: classes.nurseryTotal }, "Nursery total"),
                                nursery.shippingLoading ? (React.createElement(CustomLoader, { key: nursery.name, loaderClassName: null, loaderAttributes: { size: 20, thickness: 5 } })) : (React.createElement(Typography, { sx: classes.nurseryTotalPrice },
                                    "$",
                                    numberFormat(nursery.total))))))))));
        }
        return null;
    };
    return CartNursery;
}(React.Component));
export default CartNursery;
