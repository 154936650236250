var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
var classes = {
    loaderRoot: function (theme) { return ({
        color: theme.palette.secondary.main,
        padding: theme.spacing(0, 0.65),
    }); },
};
export default function CustomLoader(props) {
    var loaderClassName = props.loaderClassName, loaderAttributes = props.loaderAttributes, other = __rest(props, ["loaderClassName", "loaderAttributes"]);
    return (React.createElement(CircularProgress, __assign({ sx: loaderClassName || classes.loaderRoot, size: loaderAttributes.size, thickness: loaderAttributes.thickness }, other)));
}
CustomLoader.defaultProps = {
    loaderClassName: '',
    loaderAttributes: {
        size: 20,
        thickness: 3.6,
    },
};
