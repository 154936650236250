var useStyles = {
    searchAutoComplete: function (theme) { return ({
        background: theme.palette.background.default,
        cursor: 'default',
        overflow: 'hidden',
        overflowY: 'scroll',
        textAlign: 'left',
        position: 'absolute',
        left: 0,
        right: 0,
        maxHeight: 300,
        zIndex: 1000,
        border: "1px solid ".concat(theme.palette.secondary.contrastText),
        borderBottomLeftRadius: theme.spacing(0.625),
        borderBottomRightRadius: theme.spacing(0.625),
        top: theme.spacing(5),
    }); },
    autoCompleteItemWrapper: function (theme) {
        var _a, _b;
        return (_a = {
                display: 'block',
                fontSize: theme.spacing(1.5),
                whiteSpace: 'nowrap',
                lineHeight: 1.5,
                padding: theme.spacing(0),
                '&:nth-child(n+2)': {
                    '& #searchQueryType': {
                        '&:not(:empty)': {
                            marginTop: theme.spacing(2),
                        },
                    },
                }
            },
            _a[theme.breakpoints.up('sm')] = {
                padding: theme.spacing(0.125, 0.625),
            },
            _a['&.Mui-selected'] = (_b = {},
                _b[theme.breakpoints.up('sm')] = {
                    backgroundColor: theme.palette.greyColors.main,
                },
                _b),
            _a);
    },
    searchQueryType: function (theme) {
        var _a;
        return (_a = {
                color: '#8e8e8e',
                display: 'block',
                letterSpacing: 3,
                padding: theme.spacing(0, 1.875),
                margin: theme.spacing(1, 0),
                fontSize: 10,
                textTransform: 'uppercase'
            },
            _a[theme.breakpoints.up('sm')] = {
                margin: 0,
                letterSpacing: 3,
                fontSize: 10,
                textTransform: 'uppercase',
                display: 'inline-block',
                minWidth: 110,
                padding: theme.spacing(0, 0.75),
            },
            _a);
    },
    searchQueryName: function (theme) {
        var _a;
        return (_a = {
                fontSize: 12,
                '& > em': {
                    fontWeight: 'bold',
                }
            },
            _a[theme.breakpoints.down('sm')] = {
                lineHeight: 2.8,
                fontSize: theme.spacing(1.75),
                display: 'block',
                padding: theme.spacing(0, 1.875),
                '&:hover': {
                    backgroundColor: theme.palette.greyColors.main,
                },
            },
            _a);
    },
};
export default useStyles;
