import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ItemAvailability from './ItemAvailability';
import AvatarImage from '../CommonComponents/AvatarImage';
import QuantityPicker from '../CommonComponents/QuantityPicker';
import CustomButton from '../CommonComponents/CustomButton';
import { numberFormat } from '../../utils/helpers';
import RemoveItem from '../../themes/icons/RemoveItem';
import classes from './CartPlantStyles';
export default function CartPlant(props) {
    var plant = props.plant, shopifyCart = props.shopifyCart, goToPlant = props.goToPlant, removeItem = props.removeItem, changeNurseryQuantity = props.changeNurseryQuantity;
    return (React.createElement(Grid, { container: true, direction: "row", justifyContent: "flex-start", sx: [classes.plantInfoWrapper,
            !shopifyCart && (plant.quantity_been_reduced
                || plant.shopify_quantity === 0) && classes.plantInfoWrapperItems], wrap: "nowrap" },
        React.createElement(AvatarImage, { onClick: function () { return goToPlant(plant.url); }, key: "".concat(plant.name, "-").concat(plant.shopify_title), customClassName: classes.plantImage, src: plant.photo, alt: plant.name, sqaure: true }),
        React.createElement(Grid, { sx: [classes.plantInfo, shopifyCart && classes.plantInfoCart] },
            React.createElement(Grid, { container: true, direction: "column", alignItems: "flex-start" },
                React.createElement(CustomButton, { onClick: function () { return goToPlant(plant.url); }, buttonClassSX: classes.plantName, buttonText: plant.name }),
                React.createElement(Typography, { sx: classes.plantSize }, plant.shopify_title),
                !shopifyCart && (React.createElement(CustomButton, { buttonClassSX: classes.removeButton, onClick: function () { return removeItem(plant); }, buttonText: "Remove", startIcon: React.createElement(RemoveItem, null) }))),
            React.createElement(Grid, { container: true, direction: "row", sx: [classes.quantityPrice, shopifyCart && classes.quantityPriceCart] },
                React.createElement(QuantityPicker, { plant: plant, changeQuantity: changeNurseryQuantity }),
                React.createElement(Typography, { sx: [classes.plantPrice, shopifyCart && classes.plantPriceCart] },
                    "$",
                    numberFormat(plant.price)))),
        !shopifyCart && (plant.quantity_been_reduced
            || plant.shopify_quantity === 0) && React.createElement(ItemAvailability, { plant: plant })));
}
