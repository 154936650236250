var sentryConfig = function (env) { return ({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: env,
    beforeSend: function (event) {
        if (event && event.exception && event.exception.values) {
            event.exception.values.filter(function (value) { return value.value !== 'Request aborted' && value.value !== 'Failed to fetch' && value.value !== 'cancelled'; });
        }
        return event;
    },
    tracesSampleRate: 0,
    // see https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry for the latest ignored errors and urls to deny
    ignoreErrors: [
        'top.GLOBALS',
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        'fb_xd_fragment',
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        'conduitPage',
        '_avast_submit',
        'Failed to fetch',
        'Network Error',
        'Network request failed',
        'Request aborted',
        'cancelled',
    ],
    denyUrls: [
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^safari-extension:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
}); };
export default sentryConfig;
