var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import isEqual from 'lodash/isEqual';
import api from '../../services/api';
import ConfirmationBackdrop from '../CommonComponents/ConfirmationBackdrop';
import { validateEmail } from '../../utils/helpers';
var PdpEmail = /** @class */ (function (_super) {
    __extends(PdpEmail, _super);
    function PdpEmail(props) {
        var _this = _super.call(this, props) || this;
        _this.isNumeric = function (n) { return !Number.isNaN(Number(n)) && Number.isFinite(Number(n)); };
        _this.clearErrors = function () {
            var _a = document.forms.namedItem('request_plant'), email = _a.email, quantity = _a.quantity, userLocation = _a.userLocation;
            userLocation.classList.remove('input-invalid');
            quantity.classList.remove('input-invalid');
            email.classList.remove('input-invalid');
            document.getElementById('email-feedback').innerHTML = '';
            document.getElementById('user_location-feedback').innerHTML = '';
            document.getElementById('quantity-feedback').innerHTML = '';
        };
        _this.clearForm = function () {
            _this.setState({
                comment: '',
                quantity: 1,
                location: 'United States',
                email: '',
            });
        };
        _this.submitForm = function (e) {
            e.preventDefault();
            var newsletterOptIn = _this.state.newsletterOptIn;
            var hasHtml5Validation = typeof document.createElement('input').checkValidity === 'function';
            if (hasHtml5Validation) {
                var _a = document.forms.namedItem('request_plant'), email = _a.email, quantity = _a.quantity, userLocation = _a.userLocation;
                if (email.value === '') {
                    email.classList.add('input-invalid');
                    document.getElementById('email-feedback').innerHTML = 'Email is required';
                }
                else if (!validateEmail(email.value)) {
                    email.classList.add('input-invalid');
                    document.getElementById('email-feedback').innerHTML = 'Email is invalid';
                }
                else if (quantity.value === '' || Number(quantity.value) <= 0) {
                    quantity.classList.add('input-invalid');
                    document.getElementById('quantity-feedback').innerHTML = 'Quantity is required';
                }
                else if (!_this.isNumeric(parseInt(quantity.value, 10))) {
                    quantity.classList.add('input-invalid');
                    document.getElementById('quantity-feedback').innerHTML = 'Please enter a number';
                }
                else if (userLocation.value === '') {
                    userLocation.classList.add('input-invalid');
                    document.getElementById('user_location-feedback').innerHTML = 'User Location is required';
                }
                else {
                    _this.clearErrors();
                    _this.setState({
                        loadButton: true,
                    });
                    if (newsletterOptIn) {
                        var data = {
                            email: email.value,
                            status: 'subscribed',
                        };
                        api.newsLetter.subscribe(data).then(function (res) {
                            if (res.success
                                || (!res.success && res.message === 'Member Exists')) {
                                _this.setState({
                                    userSubscribed: true,
                                });
                            }
                        });
                    }
                    api.plant
                        .requestPlants(new FormData(e.target))
                        .then(function () {
                        _this.setState({
                            loadButton: false,
                            confirmOpen: true,
                        });
                    });
                    _this.clearForm();
                }
            }
        };
        _this.updateInput = function (e, val) {
            e.preventDefault();
            var newsletterOptIn = _this.state.newsletterOptIn;
            var newState = __assign({}, _this.state);
            e.target.classList.remove('input-invalid');
            if (document.getElementById("".concat(e.target.name, "-feedback"))) {
                document.getElementById("".concat(e.target.name, "-feedback")).innerHTML = '';
            }
            newState[val] = val === 'newsletterOptIn' ? !newsletterOptIn : e.target.value;
            _this.setState(newState);
        };
        _this.closeModal = function (e) {
            e.preventDefault();
            var closeModal = _this.props.closeModal;
            _this.setState({
                confirmOpen: false,
            });
            if (closeModal) {
                closeModal();
            }
        };
        _this.confirmModal = function () {
            var _a = _this.state, confirmOpen = _a.confirmOpen, userSubscribed = _a.userSubscribed;
            var plantName = _this.props.plantName;
            var mainMessage = 'Your Message Was Sent!';
            var secondMessages = ["We'll contact you if we locate a source for ".concat(plantName, "."), userSubscribed && 'You are now subscribed to the Plantlust newsletter.'];
            if (confirmOpen) {
                return (React.createElement(ConfirmationBackdrop, { open: confirmOpen, mainMessage: mainMessage, secondMessages: secondMessages, close: _this.closeModal }));
            }
            return null;
        };
        _this.updateQuantity = function (e, sign) {
            e.preventDefault();
            var quantity = _this.state.quantity;
            switch (sign) {
                case 'plus':
                    quantity += 1;
                    _this.setState({
                        quantity: quantity,
                    });
                    break;
                case 'minus':
                    quantity -= 1;
                    _this.setState({
                        quantity: quantity,
                    });
                    break;
                default:
                    break;
            }
        };
        _this.state = {
            comment: '',
            quantity: 1,
            location: 'United States',
            email: '',
            newsletterOptIn: true,
            confirmOpen: false,
            loadButton: false,
            userSubscribed: false,
        };
        return _this;
    }
    PdpEmail.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        if (isEqual(this.state, nextState)
            && isEqual(this.props, nextProps)) {
            return false;
        }
        return true;
    };
    PdpEmail.prototype.render = function () {
        var _this = this;
        var _a = this.props, plantId = _a.plantId, plantName = _a.plantName, _b = _a.isNotifyModal, isNotifyModal = _b === void 0 ? false : _b;
        var _c = this.state, loadButton = _c.loadButton, quantity = _c.quantity, email = _c.email, location = _c.location, comment = _c.comment, newsletterOptIn = _c.newsletterOptIn;
        return (React.createElement("div", { className: "pdp_email" },
            React.createElement("form", { name: "request_plant", method: "POST", onSubmit: this.submitForm },
                React.createElement("input", { type: "hidden", name: "plant_id", value: plantId, readOnly: true }),
                React.createElement("input", { type: "hidden", name: "plant_name", value: plantName, readOnly: true }),
                !isNotifyModal && (React.createElement("h2", null,
                    "Special request for",
                    ' ',
                    plantName)),
                React.createElement("p", { className: "subtitle" },
                    "We'll notify you if we locate",
                    ' ',
                    plantName,
                    ' ',
                    "and are able to ship to your area."),
                React.createElement("div", { className: "row email" },
                    React.createElement("label", { htmlFor: "email" },
                        "YOUR E-MAIL",
                        React.createElement("input", { type: "text", id: "email", name: "email", onChange: function (e) {
                                _this.updateInput(e, 'email');
                            }, value: email, maxLength: 255 })),
                    React.createElement("label", { htmlFor: "email", id: "email-feedback", className: "feedback" })),
                React.createElement("div", { className: "row quantity" },
                    React.createElement("label", { htmlFor: "quantity" },
                        "QUANTITY WANTED",
                        React.createElement("input", { type: "number", id: "quantity", name: "quantity", value: quantity, min: 1, onChange: function (e) { return _this.updateInput(e, 'quantity'); }, maxLength: 8 }),
                        React.createElement("div", { className: "plus_minus" },
                            React.createElement("input", { type: "image", src: "".concat(process.env.STATIC_PATH, "/img/photo_plus@2x.png"), alt: "plus", className: "plus", onClick: function (e) { return _this.updateQuantity(e, 'plus'); } }),
                            React.createElement("input", { type: "image", src: "".concat(process.env.STATIC_PATH, "/img/photo_minus@2x.png"), alt: "minus", className: "plus", onClick: function (e) { return _this.updateQuantity(e, 'minus'); } }))),
                    React.createElement("label", { id: "quantity-feedback", className: "feedback" })),
                React.createElement("div", { className: "row location" },
                    React.createElement("label", { htmlFor: "location" },
                        "YOUR LOCATION",
                        React.createElement("input", { type: "text", id: "location", name: "userLocation", value: location, maxLength: 128, onChange: function (e) { return _this.updateInput(e, 'location'); } })),
                    React.createElement("label", { id: "user_location-feedback", className: "feedback" })),
                React.createElement("div", { className: "row comments" },
                    React.createElement("label", { htmlFor: "comments" },
                        "COMMENTS (OPTIONAL)",
                        React.createElement("textarea", { name: "comments", id: "comments", value: comment, maxLength: 4096, onChange: function (e) { return _this.updateInput(e, 'comment'); } }))),
                React.createElement("div", { className: "row newsletter" },
                    React.createElement("input", { type: "checkbox", id: "newsletter", className: newsletterOptIn ? 'pdp_checked' : 'pdp_unchecked', name: "newsletter_optIn", checked: newsletterOptIn, onChange: function () { }, onClick: function (e) { return _this.updateInput(e, 'newsletterOptIn'); } }),
                    React.createElement("label", { htmlFor: "newsletter" },
                        "Join the Mailing List",
                        ' ',
                        React.createElement("span", null,
                            "Pssst... get the scoop on new plant arrivals and deals",
                            ' ',
                            React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://plantlust.com/blog/privacy-policy/" }, "Privacy Policy")))),
                React.createElement("div", { className: "row submit" }, loadButton ? (React.createElement("button", { className: "btn primary loading", type: "submit" }, "Send")) : (React.createElement("button", { className: "btn primary", type: "submit", id: "submit_form" }, "Send")))),
            this.confirmModal()));
    };
    return PdpEmail;
}(React.Component));
export default PdpEmail;
