import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
var useStyles = makeStyles(function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var palette = _a.palette, spacing = _a.spacing, breakpoints = _a.breakpoints;
    return createStyles({
        navigationWrapper: (_b = {},
            _b[breakpoints.down('mdMedMin')] = {
                display: 'none',
            },
            _b),
        mainNavItems: (_c = {},
            _c[breakpoints.down('mdMax')] = {
                padding: spacing(0, '0.75em'),
            },
            _c),
        secondaryNavItemsPadded: (_d = {
                padding: spacing(3, 0)
            },
            _d[breakpoints.down('mdMax')] = {
                padding: spacing(3, 2),
            },
            _d),
        navSectionWrapper: {
            width: '100%',
            margin: spacing(3, 0),
            alignContent: 'center',
        },
        navTitle: (_e = {
                fontFamily: 'system-ui, sans-serif',
                fontSize: spacing(3),
                margin: spacing(2.5, 0),
                color: palette.primary.light,
                fontWeight: 700
            },
            _e[breakpoints.down('xsMax')] = {
                marginBottom: spacing(2.5),
                fontSize: spacing(2.5),
            },
            _e),
        navItemsContainer: (_f = {
                display: 'flex',
                flexWrap: 'wrap'
            },
            _f[breakpoints.between('xsMin', 'xsMax')] = {
                margin: spacing(2.5, 0, 4, 0),
            },
            _f),
        navItemWrapper: (_g = {
                width: '25%'
            },
            _g[breakpoints.down('md')] = {
                width: '33%',
            },
            _g[breakpoints.between('xsMin', 'xsMax')] = {
                width: '50%',
            },
            _g),
        navItem: {
            margin: spacing(0.5, 1.5, 0.5, 0),
        },
        navItemImage: {
            height: 180,
            borderRadius: 4,
            width: '100%',
            float: 'left',
            marginBottom: 12,
            objectFit: 'cover',
        },
        navItemTitle: (_h = {
                color: '#000',
                fontFamily: 'system-ui, sans-serif',
                fontWeight: 100,
                lineHeight: 'normal',
                letterSpacing: 1.68,
                textAlign: 'left',
                marginBottom: 12,
                fontSize: spacing(2.5)
            },
            _h[breakpoints.between('sm', 'mdMedMin')] = {
                fontSize: spacing(2),
            },
            _h[breakpoints.down('xsMax')] = {
                fontSize: spacing(1.8),
            },
            _h),
        navButton: {
            display: 'flex',
            justifyContent: 'center',
            margin: spacing(2.5, 0, 0, 0),
        },
        navEmptyContainer: {
            width: '100%',
            margin: spacing(3, 0),
            background: palette.blueColors.main,
        },
        navContainerWithImage: (_j = {
                background: '#fff !important',
                display: 'flex',
                borderRadius: 4,
                border: '1px solid #d5d3cf',
                minHeight: 200
            },
            _j[breakpoints.down('xsMedMax')] = {
                display: 'block',
            },
            _j),
        navTextContainer: (_k = {
                padding: spacing(4, 8),
                color: palette.background.default
            },
            _k[breakpoints.between('sm', 'mdMedMin')] = {
                padding: spacing(3, 6),
            },
            _k[breakpoints.down('xsMax')] = {
                padding: spacing(2, 4),
            },
            _k),
        navTextContainerWithImage: (_l = {
                color: '#000 !important',
                width: '60% !important'
            },
            _l[breakpoints.down('xsMedMax')] = {
                width: '80% !important',
            },
            _l),
        shortTitle: {
            fontSize: 14,
            fontWeight: 200,
            letterSpacing: 4.34,
            textTransform: 'uppercase',
        },
        longTitle: (_m = {
                fontFamily: 'system-ui, sans-serif',
                fontSize: 40,
                lineHeight: '45px',
                fontWeight: 300,
                letterSpacing: 0.39,
                margin: spacing(4, 0)
            },
            _m[breakpoints.between('sm', 'mdMedMin')] = {
                margin: spacing(3, 0),
                fontSize: spacing(3),
                lineHeight: spacing(3.3),
            },
            _m[breakpoints.down('xsMax')] = {
                margin: spacing(2, 0),
                fontSize: spacing(2.5),
                lineHeight: spacing(3),
            },
            _m),
        imgContainer: (_o = {
                width: '30%'
            },
            _o[breakpoints.down('xsMedMax')] = {
                width: '100%',
            },
            _o),
        navEmptyImage: (_p = {
                borderRadius: spacing(0.5, 0, 0, 0.5),
                height: '100%',
                width: '100%',
                objectFit: 'cover'
            },
            _p[breakpoints.down('xsMedMax')] = {
                borderRadius: spacing(0.5, 0.5, 0, 0),
            },
            _p),
        navigateNext: {
            width: 48,
            height: 48,
            margin: spacing(2, 0, 0, 0),
        },
        navigateNextWithImage: (_q = {
                display: 'flex'
            },
            _q[breakpoints.down('xsMedMax')] = {
                display: 'inline',
            },
            _q),
        credit: {
            fontSize: 14,
            letterSpacing: 0.9,
            fontWeight: 100,
        },
        navPageTitle: {
            fontSize: 36,
            fontWeight: 700,
            lineHeight: 'normal',
            letterSpacing: '2.16px',
            textAlign: 'left',
            marginBottom: spacing(2.5),
        },
        navPageDescription: {
            fontSize: 16,
            fontWeight: 300,
            lineHeight: '28px',
            letterSpacing: 1.013,
            textAlign: 'left',
        },
        navPageWrapper: {
            flexDirection: 'column',
        },
    });
});
export default useStyles;
