var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function even_moisture(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 65 64" }),
        React.createElement("path", { d: "M63.2,10.1 L34.1,10.1 L34.1,1.5 C34.1,0.7 33.5,0.1 32.7,0.1 C31.9,0.1 31.3,0.7 31.3,1.5 L31.3,10.1 L2.1,10.1 C1.3,10.1 0.6,10.8 0.6,11.6 C0.6,12.4 1.3,13.1 2.1,13.1 L12.4,13.1 L3.9,27.8 C2.9,29.6 2.3,31.7 2.3,33.8 C2.2,40.4 7.5,45.8 14.1,45.9 C20.7,45.8 26,40.4 25.9,33.8 C25.9,31.7 25.4,29.7 24.4,27.8 L16,13 L31,13 L31,61 C31,61 31,61.1 31,61.1 L17,61.1 C16.2,61.2 15.6,62 15.8,62.8 C15.9,63.4 16.4,64 17,64 L48.3,64 C49.1,64 49.8,63.3 49.8,62.5 C49.8,61.7 49.1,61 48.3,61 L34,61 C34,61 34,60.9 34,60.9 L34,13.1 L49.2,13.1 L40.7,27.9 C39.7,29.7 39.1,31.8 39.1,33.9 C39.1,40.4 44.4,45.7 50.9,45.7 C57.4,45.7 62.7,40.4 62.7,33.9 C62.7,33.9 62.7,33.9 62.7,33.9 C62.7,31.8 62.2,29.8 61.2,28 L52.8,13.1 L63.1,13.1 C63.9,13.1 64.6,12.4 64.6,11.6 C64.7,10.8 64,10.1 63.2,10.1 Z M5.5,31.1 C5.7,30.4 6,29.8 6.3,29.2 L14.3,15.1 L22.3,29.2 C22.6,29.8 22.9,30.4 23,31.1 L5.5,31.1 Z M42.3,31.2 C42.5,30.5 42.8,29.9 43.1,29.3 L51,15.2 L59,29.3 C59.3,29.9 59.6,30.6 59.8,31.2 L42.3,31.2 Z" })));
}
