var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function attr16(props) {
    // texture
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 65 65" }),
        React.createElement("path", { d: "M63.8,37.9 L57.7,31.8 L62.5,27 C63.2,26.3 63.2,25.2 62.5,24.5 C61.8,23.8 60.7,23.8 60,24.5 L55.2,29.3 L46.3,20.4 L51.3,15.4 C52,14.7 52,13.6 51.3,12.9 C50.6,12.2 49.5,12.2 48.8,12.9 L43.8,17.9 L35,9 L39.8,4.2 C40.5,3.5 40.5,2.4 39.8,1.7 C39.1,1 38,1 37.3,1.7 L32.5,6.5 L27.2,1.2 C26.5,0.5 25.4,0.5 24.7,1.2 C24,1.9 24,3 24.7,3.7 L30.1,9.1 L21.2,18 L16,12.8 C15.3,12.1 14.2,12.1 13.5,12.8 C12.8,13.5 12.8,14.6 13.5,15.3 L18.7,20.5 L9.8,29.4 L4.5,24.1 C3.8,23.4 2.7,23.4 2,24.1 C1.3,24.8 1.3,25.9 2,26.6 L7.4,32 L0.8,38.7 C0.1,39.4 0.1,40.5 0.8,41.2 C1.1,41.5 1.6,41.7 2.1,41.7 C2.6,41.7 3,41.5 3.4,41.2 L10,34.5 L18.8,43.4 L12.3,49.9 C11.6,50.6 11.6,51.7 12.3,52.4 C12.6,52.7 13.1,52.9 13.6,52.9 C14.1,52.9 14.5,52.7 14.9,52.4 L21.4,45.9 L30.3,54.8 L23.7,61.5 C23,62.2 23,63.3 23.7,64 C24,64.3 24.5,64.5 25,64.5 C25.5,64.5 25.9,64.3 26.3,64 L32.9,57.3 L39,63.4 C39.3,63.7 39.8,63.9 40.3,63.9 C40.8,63.9 41.2,63.7 41.6,63.4 C42.3,62.7 42.3,61.6 41.6,60.9 L35.5,54.8 L44.4,45.9 L50.7,52.2 C51,52.5 51.5,52.7 52,52.7 C52.5,52.7 52.9,52.5 53.3,52.2 C54,51.5 54,50.4 53.3,49.7 L46.9,43.4 L55.8,34.5 L61.9,40.6 C62.2,40.9 62.7,41.1 63.2,41.1 C63.7,41.1 64.1,40.9 64.5,40.6 C64.4,39.7 64.4,38.6 63.8,37.9 Z M32.5,11.4 L41.4,20.3 L32.5,29.2 L23.6,20.3 L32.5,11.4 Z M12.3,31.7 L21.2,22.8 L30.1,31.7 L21.2,40.6 L12.3,31.7 Z M32.5,52.1 L23.6,43.2 L32.5,34.3 L41.3,43.2 L32.5,52.1 Z M43.8,40.7 L35,31.8 L43.9,22.9 L52.8,31.8 L43.8,40.7 Z", fillRule: "nonzero" })));
}
