/* eslint-disable no-nested-ternary */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import useStyles from './NavigationStyles';
import NavigationLinkSection from './NavigationLinkSection';
var NavigationSection = function (_a) {
    var _b, _c;
    var data = _a.data, childrenType = _a.childrenType;
    var classes = useStyles();
    var params = useParams();
    var isSecondaryNavPage = !!params.secondaryNav;
    var mapData = childrenType === 'primary'
        ? data : childrenType === 'secondary'
        ? (_b = data.secondary) === null || _b === void 0 ? void 0 : _b.slice(0, 4) : isSecondaryNavPage
        ? data.tertiary : (_c = data.tertiary) === null || _c === void 0 ? void 0 : _c.slice(0, 4);
    var getModifiedLink = function (link, menuId) {
        var updatedLink = !link.startsWith('/nurseries/')
            ? link.includes('?')
                ? "".concat(link, "&").concat(menuId)
                : "".concat(link, "?").concat(menuId)
            : link;
        return updatedLink;
    };
    var LinkCard = function (_a) {
        var item = _a.item;
        var link = item.link, imageSrc = item.promo_hero, title = item.promo_title, cardTitle = item.title, url = item.url, menuId = item.menu_id;
        var modifiedLink = childrenType === 'primary'
            ? url
            : childrenType === 'secondary'
                ? url
                : link ? getModifiedLink(link, menuId) : '';
        return modifiedLink ? (React.createElement("div", { key: item.title, className: classes.navItem },
            React.createElement("a", { href: modifiedLink, title: title },
                React.createElement("img", { src: imageSrc || "".concat(process.env.STATIC_PATH, "/img/placeholder_plant_196x196.jpg"), alt: title, className: classes.navItemImage }),
                React.createElement(Typography, { className: classes.navItemTitle }, cardTitle)))) : (React.createElement("div", { key: item.title, className: classes.navItem },
            React.createElement("img", { src: item.promo_hero
                    ? item.promo_hero
                    : "".concat(process.env.STATIC_PATH, "/img/placeholder_plant_196x196.jpg"), alt: item.promo_title, className: classes.navItemImage }),
            React.createElement(Typography, { className: classes.navItemTitle }, item.title)));
    };
    return (mapData === null || mapData === void 0 ? void 0 : mapData.length) > 0 ? (React.createElement(Grid, { className: classes.navSectionWrapper },
        !isSecondaryNavPage && childrenType !== 'primary' && (React.createElement(Typography, { variant: "h2", className: classes.navTitle }, data.title)),
        React.createElement("div", { className: classes.navItemsContainer }, mapData.map(function (item) { return (React.createElement("div", { key: item.key, className: classes.navItemWrapper },
            React.createElement(LinkCard, { item: item }))); })),
        !isSecondaryNavPage && childrenType !== 'primary' && (React.createElement("div", { className: classes.navButton },
            React.createElement("a", { href: data.url },
                React.createElement("button", { type: "button", className: "btn primary inverted" },
                    "All", " ".concat(data.title))))))) : (childrenType !== 'primary' && React.createElement(NavigationLinkSection, { item: data }));
};
export default NavigationSection;
