/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import useStyles from './NavigationStyles';
import { breakpoints } from '../../utils/helpers';
var NavigationLinkSection = function (_a) {
    var item = _a.item, isSecondaryNav = _a.isSecondaryNav;
    var imgSrc = item.promo_hero, title = item.title, longTitle = item.long_nav_title, link = item.link, credit = item.promo_credit, menuId = item.menu_id, promoTitle = item.promo_title, promoCreditLink = item.promo_credit_link;
    var _b = useState(0), imageHeight = _b[0], setImageHeight = _b[1];
    var classes = useStyles();
    var isSmallScreen = window.innerWidth <= breakpoints.screen_med_xs;
    var modifiedLink = !isSecondaryNav ? link
        && !link.startsWith('/nurseries/') ? link.includes('?') ? "".concat(link, "&").concat(menuId) : "".concat(link, "?").concat(menuId) : link : promoCreditLink;
    useEffect(function () {
        // Calculate the height of the left div after it has been rendered
        var leftDiv = document.getElementById("text-div-".concat(item.key));
        if (leftDiv) {
            var height = isSmallScreen ? leftDiv.offsetHeight > 300 ? 300 : leftDiv.offsetHeight : leftDiv.offsetHeight;
            setImageHeight(height);
        }
    }, []);
    return (React.createElement("div", { className: clsx(classes.navEmptyContainer, imgSrc && classes.navContainerWithImage) },
        imgSrc
            && (React.createElement("div", { style: { height: imageHeight }, className: classes.imgContainer },
                React.createElement("img", { style: { height: imageHeight }, src: imgSrc, alt: title, className: classes.navEmptyImage }))),
        React.createElement("div", { id: "text-div-".concat(item.key), className: clsx(classes.navTextContainer, imgSrc && classes.navTextContainerWithImage) },
            React.createElement(Typography, { textAlign: (!imgSrc || isSmallScreen) ? 'center' : 'left', className: classes.shortTitle }, title),
            React.createElement(Typography, { textAlign: (!imgSrc || isSmallScreen) ? 'center' : 'left', className: classes.longTitle }, isSecondaryNav ? promoTitle : longTitle),
            credit && React.createElement(Typography, { textAlign: (!imgSrc || isSmallScreen) ? 'center' : 'left', className: classes.credit }, credit),
            modifiedLink && (React.createElement("a", { href: modifiedLink },
                React.createElement("img", { src: imgSrc ? "".concat(process.env.STATIC_PATH, "/img/nav_dark_grey.png") : "".concat(process.env.STATIC_PATH, "/img/nav_right.png"), alt: "Navigate Next", className: clsx(classes.navigateNext, imgSrc && classes.navigateNextWithImage) }))))));
};
export default NavigationLinkSection;
NavigationLinkSection.defaultProps = {
    isSecondaryNav: false,
};
