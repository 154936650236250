import React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
var classes = {
    root: function (theme) { return ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    }); },
};
export default function Loader() {
    return (React.createElement(Box, { sx: classes.root },
        React.createElement(Avatar, { alt: "Plant Lust", sx: {
                width: 300,
                height: 150,
                '& > img': {
                    objectFit: 'contain',
                },
            }, variant: "square", src: "".concat(process.env.STATIC_PATH, "/img/pl_logo_dark.svg") })));
}
