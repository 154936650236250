var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function dry_in_summer(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 65 77" }),
        React.createElement("path", { d: "M44.8,35.2 L44.8,35.2 C40.5,31.7 35.2,29.6 29.7,29.2 L29.7,27.4 C29.7,26.3 28.8,25.4 27.7,25.4 C26.6,25.4 25.7,26.3 25.7,27.4 L25.7,29.2 C20.2,29.6 14.9,31.7 10.6,35.2 C6.2,38.8 3,43.8 1.6,49.3 L0.7,52.7 L2.9,50 C4.5,48.1 7.5,47 10.7,47 C13.9,47 17,48.2 18.6,50.2 L19.2,50.7 L19.7,50.1 C21.2,48.5 23.3,47.5 25.5,47.2 L25.5,69.6 C25.5,73.4 28.6,76.5 32.4,76.5 C36.2,76.5 39.3,73.4 39.3,69.6 C39.3,68.5 38.4,67.6 37.3,67.6 C36.2,67.6 35.3,68.5 35.3,69.6 C35.4,71.2 34.2,72.6 32.6,72.7 C31,72.8 29.6,71.6 29.5,70 C29.5,69.9 29.5,69.8 29.5,69.7 L29.5,47.3 C31.8,47.6 33.9,48.6 35.4,50.3 L36,50.8 L36.5,50.2 C38.1,48.3 41.1,47.2 44.3,47.2 C47.5,47.2 50.6,48.4 52.1,50.2 L54.4,52.9 L53.5,49.5 C52.4,43.8 49.2,38.8 44.8,35.2 Z M46.5,43 C42.9,42.6 39.2,43.3 36,45.2 C33.5,43.6 30.5,42.8 27.5,42.9 C24.5,42.9 21.6,43.7 19,45.2 C15.9,43.3 12.2,42.5 8.5,43 C15.7,32.6 30.1,30 40.5,37.2 C43,38.8 45,40.7 46.5,43 Z", id: "Shape" }),
        React.createElement("path", { d: "M49.3,7.9 C44.7,7.9 41,11.6 41,16.2 C41,20.8 44.7,24.5 49.3,24.5 C53.9,24.5 57.6,20.8 57.6,16.2 C57.5,11.6 53.8,7.9 49.3,7.9 Z M53.9,16.1 C53.9,18.7 51.8,20.7 49.2,20.7 C46.6,20.7 44.6,18.6 44.6,16 C44.6,13.4 46.7,11.4 49.2,11.4 C51.8,11.5 53.9,13.6 53.9,16.1 Z" }),
        React.createElement("circle", { id: "Oval", cx: "49.5", cy: "3.3", r: "2.5" }),
        React.createElement("path", { d: "M40.3,9.3 C41.7,9.3 42.8,8.2 42.8,6.8 C42.8,5.4 41.7,4.3 40.3,4.3 C39.6,4.3 39,4.6 38.6,5 L38.6,5 C37.6,6 37.6,7.5 38.6,8.5 C39,9.1 39.7,9.4 40.3,9.3 Z" }),
        React.createElement("circle", { id: "Oval", cx: "36.4", cy: "15.9", r: "2.5" }),
        React.createElement("path", { d: "M41.8,23.3 C40.8,22.3 39.3,22.3 38.3,23.3 C37.3,24.3 37.3,25.8 38.3,26.8 C38.8,27.3 39.4,27.5 40,27.5 C40.7,27.5 41.3,27.2 41.7,26.8 C42.7,25.9 42.7,24.3 41.8,23.3 L41.8,23.3 Z" }),
        React.createElement("circle", { id: "Oval", cx: "49.1", cy: "29", r: "2.5" }),
        React.createElement("path", { d: "M56.5,23.6 L56.5,23.6 C55.5,24.6 55.5,26.1 56.5,27.1 C57.5,28.1 59,28.1 60,27.1 C61,26.1 61,24.6 60,23.6 C59,22.7 57.5,22.7 56.5,23.6 L56.5,23.6 Z" }),
        React.createElement("circle", { id: "Oval", cx: "62.1", cy: "16.3", r: "2.5" }),
        React.createElement("path", { d: "M58.5,9.6 L58.5,9.6 C59.9,9.6 61,8.5 61,7.1 C61,5.7 59.9,4.6 58.5,4.6 C57.8,4.6 57.2,4.9 56.8,5.3 C55.8,6.3 55.8,7.8 56.8,8.8 C57.2,9.4 57.9,9.6 58.5,9.6 L58.5,9.6 Z" })));
}
