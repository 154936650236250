import { makeObservable, observable, action } from 'mobx';
var Store = /** @class */ (function () {
    function Store() {
        this.searchTerm = '';
        this.origSearchTerm = '';
        this.spellingSuggestion = { spelling_suggestion: '', url: '' };
        this.isNursery = false;
        this.activeZone = '0';
        this.filteredAttrs = [];
        this.filteredAttrValues = {};
        this.nursery = '';
        this.nurserySlug = '';
        makeObservable(this, {
            searchTerm: observable,
            setSearchTerm: action,
        });
        makeObservable(this, {
            origSearchTerm: observable,
            setOrigSearchTerm: action,
        });
        makeObservable(this, {
            spellingSuggestion: observable,
            setSpellingSuggestion: action,
        });
        makeObservable(this, {
            isNursery: observable,
            setIsNursery: action,
        });
        makeObservable(this, {
            activeZone: observable,
            setActiveZone: action,
        });
        makeObservable(this, {
            filteredAttrs: observable,
            setFilteredAttrs: action,
        });
        makeObservable(this, {
            filteredAttrValues: observable,
            setFilteredAttrValues: action,
        });
        makeObservable(this, {
            nursery: observable,
            setNursery: action,
        });
        makeObservable(this, {
            nurserySlug: observable,
            setNurserySlug: action,
        });
    }
    Store.prototype.setSearchTerm = function (term) {
        this.searchTerm = term;
    };
    Store.prototype.setOrigSearchTerm = function (term) {
        this.origSearchTerm = term;
    };
    Store.prototype.setSpellingSuggestion = function (spelling) {
        this.spellingSuggestion = spelling;
    };
    Store.prototype.setIsNursery = function (value) {
        this.isNursery = value;
    };
    Store.prototype.setActiveZone = function (zone) {
        this.activeZone = zone;
    };
    Store.prototype.setFilteredAttrs = function (attrs) {
        this.filteredAttrs = attrs;
    };
    Store.prototype.setFilteredAttrValues = function (attrs) {
        this.filteredAttrValues = attrs;
    };
    Store.prototype.setNursery = function (value) {
        this.nursery = value;
    };
    Store.prototype.setNurserySlug = function (value) {
        this.nurserySlug = value;
    };
    return Store;
}());
var store = new Store();
export default store;
