var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function drought_tolerant(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 41 65" }),
        React.createElement("path", { d: "M25.8,43.8 C29.5,43.4 33,41.9 35.8,39.5 C39.1,36.4 40.9,32.1 40.7,27.6 L40.7,18.2 C40.7,15.5 38.4,13.2 35.7,13.2 C33,13.2 30.8,15.4 30.7,18.2 L30.7,27.6 C30.8,29.3 30.3,30.9 29.1,32.1 C28.2,32.8 27.1,33.4 26,33.6 C25.8,33.7 25.5,33.6 25.3,33.5 C25.1,33.4 25,33.1 25,32.9 L25,5.1 C25,3.7 24.4,2.3 23.4,1.4 C22.4,0.4 21,-6.66133815e-16 19.6,0.1 C17,0.4 15.1,2.7 15.1,5.3 L15.1,29 C15.1,29.2 15,29.5 14.8,29.6 C14.6,29.7 14.4,29.8 14.2,29.8 C13.2,29.6 12.2,29.2 11.4,28.6 C11.1,28.3 10.4,27.7 10.4,25.7 L10.4,15.9 C10.4,13.1 8.2,10.9 5.4,10.9 C5.2,10.9 5.1,10.9 4.9,10.9 C2.3,11.2 0.4,13.5 0.4,16.1 L0.4,25.7 C0.3,29.7 1.9,33.5 4.9,36.2 C7.6,38.4 11,39.8 14.5,40 C14.9,40 15.2,40.4 15.2,40.8 L15.2,61.3 L6.5,61.3 C5.7,61.3 5,61.9 5,62.8 C5,63.7 5.6,64.3 6.5,64.3 L34.8,64.3 C35.6,64.3 36.3,63.7 36.3,62.8 C36.3,61.9 35.7,61.3 34.8,61.3 L34.8,61.3 L25.2,61.3 L25.2,44.7 C25.2,44.2 25.5,43.9 25.8,43.8 Z M16.7,37.2 C13.8,37.1 9.7,36.4 6.8,33.9 C4.5,31.8 3.2,28.8 3.3,25.6 L3.3,16 C3.3,14.9 4.1,13.9 5.2,13.8 C6.3,13.7 7.4,14.5 7.5,15.7 C7.5,15.8 7.5,15.8 7.5,15.9 L7.5,25.7 C7.4,27.6 8.1,29.5 9.5,30.8 C11.4,32.5 14.4,32.9 16.5,33 L16.6,33 C17.4,33 18.1,32.4 18.1,31.5 L18.1,31.5 L18.1,5 C18.1,3.8 19,2.9 20.2,2.9 C20.3,2.9 20.4,2.9 20.5,2.9 C21.6,3.1 22.4,4 22.4,5.1 L22.4,35.4 C22.4,35.8 22.6,36.2 22.9,36.5 C23.2,36.8 23.6,36.9 24,36.9 C26.2,36.7 29.2,36.1 31.3,34.2 C33.1,32.5 34,30.1 33.9,27.6 L33.9,18.3 C33.9,17.2 34.8,16.2 36,16.2 C37.1,16.2 38,17 38.1,18.1 C38.1,18.1 38.1,18.2 38.1,18.2 L38.1,27.6 C38.2,31.3 36.8,34.8 34.1,37.3 C31,40.1 26.8,40.9 23.8,41.1 C23,41.2 22.4,41.8 22.4,42.6 L22.4,61.2 L18.3,61.2 L18.3,38.7 C18.1,37.9 17.5,37.2 16.7,37.2 Z" })));
}
