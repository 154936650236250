var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function icn_clear_filter(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "-1 -1 18 18" }),
        React.createElement("path", { d: "M8,16.5c-4.7,0-8.5-3.8-8.5-8.5c0-4.7,3.8-8.5,8.5-8.5c4.7,0,8.5,3.8,8.5,8.5C16.5,12.7,12.7,16.5,8,16.5z\n     M8,0.5C3.9,0.5,0.5,3.9,0.5,8s3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5S12.1,0.5,8,0.5z" }),
        React.createElement("rect", { x: "4", y: "7.5", transform: "matrix(0.7071 -0.7071 0.7071 0.7071 -3.3137 8)", width: "8", height: "1" }),
        React.createElement("rect", { x: "7.5", y: "4", transform: "matrix(0.7071 -0.7071 0.7071 0.7071 -3.3137 8)", width: "1", height: "8" })));
}
