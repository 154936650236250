var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function sun(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 64 65" }),
        React.createElement("path", { d: "M32,55.2 C19.7,55.2 9.6,45.2 9.6,32.8 C9.6,20.5 19.6,10.4 32,10.4 C44.4,10.4 54.4,20.4 54.4,32.8 C54.4,45.2 44.4,55.2 32,55.2 Z M32,14.8 C22.1,14.8 14,22.9 14,32.8 C14,42.7 22.1,50.8 32,50.8 C41.9,50.8 50,42.7 50,32.8 C50.1,22.9 42,14.8 32,14.8 Z", id: "Shape" }),
        React.createElement("circle", { id: "Oval", cx: "32.1", cy: "3.7", r: "2.9" }),
        React.createElement("circle", { id: "Oval", transform: "translate(17.618000, 7.574100) rotate(-30.000728) translate(-17.618000, -7.574100) ", cx: "17.618", cy: "7.5741", r: "2.8999362" }),
        React.createElement("circle", { id: "Oval", transform: "translate(6.911500, 18.152500) rotate(-59.999272) translate(-6.911500, -18.152500) ", cx: "6.9115", cy: "18.1525", r: "2.8999362" }),
        React.createElement("circle", { id: "Oval", cx: "2.9", cy: "32.7", r: "2.9" }),
        React.createElement("circle", { id: "Oval", transform: "translate(6.716100, 47.312600) rotate(-30.000728) translate(-6.716100, -47.312600) ", cx: "6.7161", cy: "47.3126", r: "2.8999362" }),
        React.createElement("circle", { id: "Oval", transform: "translate(17.389700, 57.947700) rotate(-59.999272) translate(-17.389700, -57.947700) ", cx: "17.3897", cy: "57.9477", r: "2.8999362" }),
        React.createElement("circle", { id: "Oval", cx: "31.9", cy: "61.9", r: "2.9" }),
        React.createElement("circle", { id: "Oval", transform: "translate(46.491200, 58.078000) rotate(-30.000728) translate(-46.491200, -58.078000) ", cx: "46.4912", cy: "58.078", r: "2.8999362" }),
        React.createElement("circle", { id: "Oval", transform: "translate(57.184800, 47.469600) rotate(-59.999272) translate(-57.184800, -47.469600) ", cx: "57.1848", cy: "47.4696", r: "2.8999362" }),
        React.createElement("circle", { id: "Oval", cx: "61.1", cy: "33", r: "2.9" }),
        React.createElement("circle", { id: "Oval", transform: "translate(57.306500, 18.389500) rotate(-30.000728) translate(-57.306500, -18.389500) ", cx: "57.3065", cy: "18.3895", r: "2.8999362" }),
        React.createElement("circle", { id: "Oval", transform: "translate(46.706700, 7.674400) rotate(-59.999272) translate(-46.706700, -7.674400) ", cx: "46.7067", cy: "7.6744", r: "2.8999362" })));
}
