import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
export default function NavigationList(props) {
    var navigation = props.navigation;
    var navigationWrapperSX = function (theme) {
        var _a;
        return (_a = {
                height: '70px'
            },
            _a[theme.breakpoints.down('mdMedMin')] = {
                display: 'none',
            },
            _a);
    };
    var mainNavItemsSX = function (theme) {
        var _a;
        return (_a = {},
            _a[theme.breakpoints.down('mdMax')] = {
                padding: theme.spacing(0, '0.75em'),
            },
            _a);
    };
    return (React.createElement(Box, { sx: navigationWrapperSX }, (navigation === null || navigation === void 0 ? void 0 : navigation.length) > 0 && (React.createElement(Box, { sx: [{
                zIndex: 101,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                position: 'relative',
            }, navigationWrapperSX] },
        React.createElement(Grid, { container: true, sx: [{
                    maxWidth: 1104,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }, mainNavItemsSX], wrap: "nowrap" }, navigation === null || navigation === void 0 ? void 0 : navigation.map(function (item, index) { return (React.createElement(ListItem, { key: item.title, sx: {
                py: '12px',
                pl: 0,
                pr: 0.25,
                width: 'max-content',
                cursor: item.link !== '' ? 'pointer' : 'default',
                ':hover': {
                    borderBottom: function (theme) { return "2px solid ".concat(theme.palette.primary.dark); },
                    pb: '10px',
                },
                '&.Mui-selected': {
                    backgroundColor: 'transparent !important',
                    borderBottom: function (theme) { return "2px solid ".concat(theme.palette.primary.dark); },
                },
            }, disableGutters: true, dense: true },
            React.createElement("a", { href: (item === null || item === void 0 ? void 0 : item.url) ? item.url : '' },
                React.createElement(ListItemText, { primary: item.title, primaryTypographyProps: {
                        noWrap: true,
                        sx: {
                            color: 'primary.light',
                            textTransform: 'uppercase',
                            lineHeight: 1.28,
                            fontSize: 18,
                            fontWeight: item.bold && 700,
                            letterSpacing: '0.02em',
                            WebkitUserSelect: 'none',
                            MozUserSelect: 'none',
                            msUserSelect: 'none',
                            userSelect: 'none',
                        },
                        component: 'p',
                    } })))); })),
        React.createElement(Divider, { sx: { width: '100%' } })))));
}
