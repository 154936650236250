import React from 'react';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import parse from 'html-react-parser';
import classes from './AutoCompleteStyles';
export default function AutoCompleteItem(props) {
    var query = props.query, queryLabel = props.queryLabel, queryType = props.queryType, _a = props.isFirst, isFirst = _a === void 0 ? false : _a, _b = props.isSelected, isSelected = _b === void 0 ? false : _b, index = props.index, submitQuery = props.submitQuery, handleAutocompleteItemSelect = props.handleAutocompleteItemSelect;
    function highlightQuery(queryLabelString) {
        var newString = queryLabelString;
        var newQuery = query.trim().toLowerCase().replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        var matchIndex = queryLabelString.toLowerCase().replace(/[-\\^$*+?.()|[\\]{}]/g, '\\$&').indexOf(newQuery);
        if (matchIndex !== -1) {
            var regEx = new RegExp(newQuery, 'ig');
            var partialMatch = queryLabelString.substr(matchIndex, newQuery.length);
            newString = queryLabelString.replace(regEx, "<em>".concat(partialMatch, "</em>"));
        }
        return newString;
    }
    function handleMouseOver() {
        handleAutocompleteItemSelect(index);
    }
    return (React.createElement(ListItem, { onClick: function (e) { return submitQuery(e, queryLabel, queryType); }, onMouseDown: function (e) { return submitQuery(e, queryLabel, queryType); }, onMouseOver: handleMouseOver, onKeyDown: function (e) { return submitQuery(e, queryLabel, queryType); }, selected: isSelected, disableGutters: true, dense: true, sx: classes.autoCompleteItemWrapper },
        React.createElement(Typography, { id: "searchQueryType", component: "span", sx: classes.searchQueryType }, !isFirst ? '' : queryType),
        React.createElement(Typography, { component: "span", sx: classes.searchQueryName }, parse(highlightQuery(queryLabel) || ''))));
}
