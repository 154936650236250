import queryString from 'query-string';
import * as Sentry from '@sentry/browser';
export function getCookie(name) {
    var cookieValue = '';
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i += 1) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === "".concat(name, "=")) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
export var breakpoints = {
    screen_med_xs: 569,
    screen_xs: 768,
    screen_md: 940,
    screen_lg: 1144,
};
export function setScrollPos() {
    var scrollPosition = 0;
    try {
        scrollPosition = parseInt(localStorage.getItem('scrollPosition'), 10);
    }
    catch (error) {
        Sentry.captureException(error);
    }
    if (scrollPosition > 0) {
        setTimeout(function () {
            window.scrollTo(0, scrollPosition);
        }, 250);
    }
}
export function validateEmail(email) {
    return email.match(/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/);
}
export function numberFormat(value) {
    return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }).format(value);
}
export function cleanSearch(searchString) {
    var search = '';
    if (searchString !== '') {
        var newString = searchString.split('?');
        search = newString[1];
        var hashArr_1 = search.split('&');
        hashArr_1.forEach(function (item, i) {
            if (item.indexOf('page=') !== -1) {
                hashArr_1.splice(i, 1);
            }
        });
        if (hashArr_1.length === 0) {
            return '';
        }
        hashArr_1.forEach(function (item, i) {
            if (i === 0) {
                search = item;
            }
            else {
                search += "&".concat(item);
            }
        });
    }
    return search;
}
export function getUrlQuery(urlQuerystring) {
    var query = urlQuerystring.split('?')[1];
    var params = queryString.parse(query);
    if ('names' in params) {
        return "names=".concat(params.names);
    }
    if ('attribute' in params && 'val' in params) {
        return "attribute=".concat(params.attribute, "&val=").concat(params.val);
    }
    if ('attribute_slug' in params && 'val_slug' in params) {
        return "attribute_slug=".concat(params.attribute_slug, "&val_slug=").concat(params.val_slug);
    }
    if ('tags' in params) {
        return "tags=".concat(params.tags);
    }
    if ('raw' in params) {
        return "raw=".concat(params.raw);
    }
    if ('nurseries' in params) {
        return "nurseries=".concat(params.nurseries);
    }
    if ('genus' in params) {
        return "genus=".concat(params.genus);
    }
    if ('all_plants' in params) {
        return "all_plants=".concat(params.all_plants);
    }
    var hashArr = query.split('&');
    return hashArr[0];
}
export function resetParam(queryStringToReset, param, value) {
    var newString = '';
    var params = queryStringToReset.split('&');
    if (queryStringToReset.indexOf(param) !== -1 && value) {
        // only add non-duplicates to new array
        var newArray = [];
        newArray = params.map(function (item) {
            if (item.indexOf(param) === -1) {
                return item;
            }
            return "".concat(param, "=").concat(value);
        });
        // create new query string
        newArray.forEach(function (item, i) {
            if (i === 0) {
                newString += item;
            }
            else {
                newString += "&".concat(item);
            }
        });
        return newString;
    }
    if (!value) {
        // remove query
        // only add non-duplicates to new array
        var newArray_1 = [];
        params.forEach(function (item) {
            if (item.indexOf(param) === -1) {
                newArray_1.push(item);
            }
        });
        // add values to searchString
        newArray_1.forEach(function (item, i) {
            if (i === 0) {
                newString += item;
            }
            else {
                newString += "&".concat(item);
            }
        });
        return newString;
    }
    if (queryStringToReset === '') {
        newString = "".concat(param, "=").concat(value);
        return newString;
    }
    // if not a duplicate, just add the query to the existing queryString
    newString = "".concat(queryStringToReset, "&").concat(param, "=").concat(value);
    return newString;
}
export function newSearchPath(path, search, number) {
    var searchString = '';
    if (number === 1) {
        searchString = resetParam(search, 'page', false);
    }
    else {
        searchString = resetParam(search, 'page', number);
    }
    if (path.length - 1 === path.lastIndexOf('/')) {
        return "".concat(path, "?").concat(searchString);
    }
    return "".concat(path, "/?").concat(searchString);
}
export function getQuery(query, queryType) {
    // format search terms for API
    var searchParams;
    var cleanQuery = encodeURIComponent(query.trim());
    var cleanQueryType = encodeURIComponent(queryType.trim());
    switch (queryType) {
        case 'Plant name':
            searchParams = "names=".concat(cleanQuery);
            break;
        case 'Flower Color':
        case 'Plant Type':
        case 'Foliage Color':
        case 'Soil Needs':
        case 'Water Needs':
        case 'Wildlife':
        case 'Fruit':
        case 'Special Situation':
        case 'Sun Exposure':
        case 'Flower Season':
        case 'Texture':
        case 'Foliage Season':
            searchParams = "attribute=".concat(cleanQueryType, "&val=").concat(cleanQuery);
            break;
        case 'Tags':
            searchParams = "tags=".concat(cleanQuery);
            break;
        case 'Nursery':
            searchParams = "nurseries=".concat(cleanQuery);
            break;
        case 'Genus':
            searchParams = "genus=".concat(cleanQuery);
            break;
        case 'all_plants':
            searchParams = 'all_plants=True';
            break;
        case null:
            searchParams = '';
            break;
        default:
            searchParams = "raw=".concat(cleanQuery);
    }
    // exception for when the user hits the search btn without entering anything
    if (query === 'all plants' && queryType === 'raw') {
        searchParams = 'all_plants=True';
    }
    return searchParams;
}
// Merge two arrays taking one element from each array by order and returning a new array.
export function mergeArrays(arr1, arr2) {
    var merged = [];
    var len = Math.max(arr1.length, arr2.length);
    for (var i = 0; i < len; i += 1) {
        if (i < arr1.length) {
            merged.push(arr1[i]);
        }
        if (i < arr2.length) {
            merged.push(arr2[i]);
        }
    }
    return merged;
}
export function cumulativeOffset(element) {
    var top = 0;
    var left = 0;
    var newElement = element;
    do {
        top += newElement.offsetTop || 0;
        left += newElement.offsetLeft || 0;
        newElement = newElement.offsetParent;
    } while (newElement);
    return {
        top: top,
        left: left,
    };
}
export var setMetaPropData = function (property, value) {
    var element = window.document.querySelector("meta[property=\"".concat(property, "\"]"));
    if (element) {
        element.content = value;
    }
    else {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        var newElement = document.createElement('meta');
        newElement.setAttribute('property', property);
        newElement.content = value;
        document.getElementsByTagName('head')[0].appendChild(newElement);
    }
};
export var setMetaNameData = function (property, value) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    var element = document.getElementsByTagName('meta')[property];
    // Create a temporary element to extract text content
    var tempElement = document.createElement('div');
    tempElement.innerHTML = value;
    var textContent = tempElement.textContent || tempElement.innerText || '';
    if (element) {
        element.content = textContent;
    }
    else {
        var newElement = document.createElement('meta');
        newElement.setAttribute('name', property);
        newElement.content = textContent;
        document.getElementsByTagName('head')[0].appendChild(newElement);
    }
};
export var isPDPPage = function () {
    var numberPattern = /^\d+/;
    if (typeof window !== 'undefined' && window.location.pathname.includes('/plants/')) {
        var urlType = window.location.pathname.split('/plants/')[1];
        return numberPattern.test(urlType);
    }
    return false;
};
export var disclaimerText = 'We independently publish plant data to search multiple nurseries at once. We may earn a commission on qualifying purchases when you buy through our links.';
