/* eslint-disable max-len */
import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import api from '../../services/api';
import useStyles from './IndexPageStyles';
import IndexPageLoading from './IndexPageLoading';
import NavigationSection from '../Navigation/NavigationSection';
function IndexPage() {
    var classes = useStyles();
    var _a = React.useState(true), pageLoading = _a[0], setPageLoading = _a[1];
    var _b = React.useState([]), promotions = _b[0], setPromotions = _b[1];
    var _c = React.useState([]), plantTypes = _c[0], setPlantTypes = _c[1];
    var _d = React.useState([]), navigation = _d[0], setNavigation = _d[1];
    React.useEffect(function () {
        var indexDataEl = document.getElementById('index-data');
        if (indexDataEl) {
            var indexData = JSON.parse(indexDataEl.textContent);
            setPageLoading(false);
            setPromotions(indexData.promotions);
            setPlantTypes(indexData.plant_types);
            setNavigation(indexData.navigation);
        }
        else {
            api.home.getIndexData().then(function (indexData) {
                setPageLoading(false);
                setPromotions(indexData.promotions);
                setPlantTypes(indexData.plant_types);
                setNavigation(indexData.navigation);
            });
        }
    }, []);
    return pageLoading ? (React.createElement(IndexPageLoading, null)) : (React.createElement("div", { className: classes.mainWrapper },
        React.createElement(Grid, { container: true, className: classes.containerGrid },
            React.createElement(Grid, { container: true }, promotions && (promotions === null || promotions === void 0 ? void 0 : promotions.length) > 0 && (promotions === null || promotions === void 0 ? void 0 : promotions.slice(0, 3).map(function (promotion, index) { return (React.createElement(Grid, { key: "promo-".concat(promotion.short_title), item: true, xs: index === 0 ? 12 : 6, className: index === 0 ? classes.promotionFirst : classes.promotionRest }, promotion.promotion_large || promotion.promotion_medium ? (React.createElement("a", { href: promotion.url, className: classes.promotionLink },
                React.createElement("img", { src: index === 0 ? promotion.promotion_large : promotion.promotion_medium, alt: promotion.header_text, className: classes.promotionImage }),
                React.createElement("div", { className: clsx(index === 0 && classes.promotionTitleFirst, classes.promotionTitle) }, index === 0
                    ? promotion.header_text
                    : promotion.short_title))) : (React.createElement("a", { href: promotion.url, className: classes.promotionLink },
                React.createElement("div", { className: clsx(index === 0 && classes.promotionTitleFirst, classes.promotionTitle) }, index === 0
                    ? promotion.header_text
                    : promotion.short_title))))); }))),
            navigation && (navigation === null || navigation === void 0 ? void 0 : navigation.length) > 0 && (navigation === null || navigation === void 0 ? void 0 : navigation.map(function (item) { return (React.createElement(NavigationSection, { key: item.key, data: item, childrenType: "secondary" })); })),
            React.createElement(Grid, { className: classes.featuredPlantsWrapper },
                React.createElement(Typography, { variant: "h2", className: classes.browsePlantsText }, "Browse By Category:"),
                React.createElement("div", { className: classes.featuredPlantsContainer }, plantTypes.map(function (plant) { return (React.createElement("div", { key: plant.plant_type_title, className: classes.featuredPlant },
                    React.createElement("a", { href: plant.plant_type_url, title: plant.plant_type_title, className: classes.featuredPlantLink },
                        React.createElement("img", { src: plant.plant_type_thumbnail
                                ? plant.plant_type_thumbnail
                                : "".concat(process.env.STATIC_PATH, "/img/placeholder_plant_detail_page.png"), alt: plant.plant_type_title, className: classes.featuredPlantImage }),
                        React.createElement("div", { className: classes.tableDiv },
                            React.createElement("div", { className: classes.cellDiv },
                                React.createElement("div", { className: classes.contentDiv }, plant.plant_type_title))),
                        React.createElement("div", { className: classes.arrow })))); })),
                React.createElement("div", { className: classes.categoryButtonContainer },
                    React.createElement("a", { href: "/category/" },
                        React.createElement("button", { type: "button", className: "btn primary inverted" }, "All Categories")))))));
}
export default IndexPage;
