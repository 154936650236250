var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function icn_search_clear(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 16 16" }),
        React.createElement("circle", { style: { fill: '#DADBDC' }, cx: "8", cy: "8", r: "8" }),
        React.createElement("rect", { x: "4.6", y: "7.7", transform: "matrix(0.7071 -0.7071 0.7071 0.7071 -3.3807 8.1618)", style: { fill: '#979797' }, width: "7.1", height: "0.9" }),
        React.createElement("rect", { x: "7.7", y: "4.6", transform: "matrix(0.7071 -0.7071 0.7071 0.7071 -3.3807 8.1618)", style: { fill: '#979797' }, width: "0.9", height: "7.1" })));
}
