var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Grid from '@mui/material/Grid';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CustomButton from '../CommonComponents/CustomButton';
import CartNursery from '../Checkout/CartNursery';
var classes = {
    shopifyCart: function (theme) { return ({
        position: 'relative',
        zIndex: 99999,
        right: 0,
        top: 0,
        bottom: 0,
    }); },
    fullCart: function (theme) { return ({
        background: theme.palette.background.default,
        width: 370,
        transition: '0.3s',
    }); },
    cartTitle: function (theme) { return ({
        fontSize: theme.spacing(2.25),
        padding: theme.spacing(2.5),
        textAlign: 'left',
        fontWeight: 400,
        color: theme.palette.primary.main,
        borderBottom: "11px solid ".concat(theme.palette.background.paper),
    }); },
    closeButton: function (theme) { return ({
        fontSize: theme.spacing(3.75),
        color: '#767676',
        fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
        right: theme.spacing(2.5),
        top: theme.spacing(0.875),
        padding: 0,
        position: 'absolute',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    }); },
    cartItems: function (theme) { return ({
        position: 'absolute',
        top: theme.spacing(8.75),
        right: 0,
        left: 0,
        bottom: 125,
        height: 'auto',
        overflowY: 'scroll',
        backgroundColor: theme.palette.background.paper,
        '& > div > div:last-child': {
            marginBottom: 0,
        },
    }); },
    cartFooter: function (theme) { return ({
        position: 'absolute',
        bottom: 0,
        padding: theme.spacing(2.5),
        borderTop: "1px solid ".concat(theme.palette.secondary.dark),
        width: '100%',
        backgroundColor: theme.palette.background.default,
        boxShadow: '0px -0.5px 0.5px rgba(0, 0, 0, 0.25)',
    }); },
    shippingCalc: function (theme) { return ({
        fontSize: theme.spacing(1.5),
        fontWeight: 400,
        lineHeight: '15px',
        color: theme.palette.blueColors.main,
        textAlign: 'center',
        paddingBottom: theme.spacing(2.5),
        width: '100%',
    }); },
    checkoutButton: function (theme) { return ({
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.background.default,
        marginTop: theme.spacing(0.5),
        width: '100%',
        fontSize: theme.spacing(2),
        fontWeight: 700,
        height: theme.spacing(5.625),
        '&:hover': {
            backgroundColor: theme.palette.primary.contrastText,
        },
    }); },
    emptyCartText: function (theme) { return ({
        color: theme.palette.newGreyColors.light,
        textAlign: 'center',
        marginTop: theme.spacing(3.75),
    }); },
};
var ShopifyCart = /** @class */ (function (_super) {
    __extends(ShopifyCart, _super);
    function ShopifyCart(props) {
        var _this = _super.call(this, props) || this;
        _this.toggleMenu = function (open) { return function (event) {
            var closeCart = _this.props.closeCart;
            if (event
                && event.type === 'keydown'
                && (event.key === 'Tab'
                    || event.key === 'Shift')) {
                return;
            }
            _this.setState({
                menuOpen: open,
            }, function () {
                if (!open)
                    closeCart();
            });
        }; };
        _this.goToCart = function () {
            window.location.href = '/cart/';
        };
        _this.state = {
            menuOpen: false,
            count: 0,
            checkout: {
                shipping_zip: null,
                total: null,
                nurseries: [],
            },
        };
        return _this;
    }
    ShopifyCart.prototype.componentDidMount = function () {
        var _a = this.props, count = _a.count, checkout = _a.checkout;
        this.setState({
            count: count,
            checkout: checkout,
        });
    };
    ShopifyCart.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        var count = this.state.count;
        var checkout = this.props.checkout;
        if (count !== nextProps.count) {
            this.setState({
                count: nextProps.count,
            });
        }
        if (checkout !== nextProps.checkout) {
            this.setState({
                checkout: nextProps.checkout,
            });
        }
        if (nextProps.openCart) {
            this.setState({
                menuOpen: nextProps.openCart,
            });
        }
    };
    ShopifyCart.prototype.render = function () {
        var _a = this.state, count = _a.count, menuOpen = _a.menuOpen, checkout = _a.checkout;
        var changeQuantity = this.props.changeQuantity;
        var isMobile = typeof window !== 'undefined' && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        var cartItems;
        cartItems = checkout.nurseries.map(function (nursery) { return (nursery.items.length > 0 && (React.createElement(CartNursery, { key: nursery.name, nursery: nursery, shopifyCart: true, changeQuantity: changeQuantity }))); });
        var footer;
        if (count > 0) {
            footer = (React.createElement(Grid, { container: true, direction: "column", sx: classes.cartFooter },
                React.createElement(Typography, { sx: classes.shippingCalc }, "Shipping calculated at checkout."),
                React.createElement(CustomButton, { onClick: this.goToCart, buttonText: "Checkout", sx: classes.checkoutButton })));
        }
        else {
            cartItems = (React.createElement(Typography, { component: "p", sx: classes.emptyCartText }, "Your cart is empty."));
        }
        return (React.createElement(Box, { sx: classes.shopifyCart },
            React.createElement(SwipeableDrawer, { anchor: "right", elevation: 3, ModalProps: {
                    hideBackdrop: !isMobile,
                    disablePortal: true,
                    disableScrollLock: !isMobile,
                    disableRestoreFocus: true,
                    disableEnforceFocus: true,
                    disableAutoFocus: true,
                    BackdropProps: { invisible: true },
                }, PaperProps: {
                    sx: classes.fullCart,
                }, open: menuOpen, onClose: this.toggleMenu(false), onOpen: this.toggleMenu(true), transitionDuration: {
                    enter: 150,
                    exit: 150,
                }, swipeAreaWidth: 0, disableBackdropTransition: !isMobile, disableDiscovery: isMobile, style: { position: 'relative' } },
                React.createElement(Box, { sx: classes.fullCart },
                    React.createElement("div", null,
                        React.createElement(Typography, { variant: "h2", sx: classes.cartTitle }, count > 0 ? 'In your cart' : 'Your Cart'),
                        React.createElement(IconButton, { disableRipple: true, disableFocusRipple: true, "aria-label": "close", sx: classes.closeButton, onClick: this.toggleMenu(false) }, "x")),
                    React.createElement(Box, { sx: classes.cartItems },
                        React.createElement(Grid, { container: true, direction: "column", alignContent: "center" }, cartItems)),
                    footer))));
    };
    return ShopifyCart;
}(React.Component));
export default ShopifyCart;
