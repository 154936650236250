var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Avatar from '@mui/material/Avatar';
import NoPlantImage from '../../themes/icons/NoPlantImage';
var classes = {
    root: {
        cursor: 'pointer',
    },
};
export default function AvatarImage(props) {
    var customClassName = props.customClassName, sqaure = props.sqaure, children = props.children, other = __rest(props, ["customClassName", "sqaure", "children"]);
    return (React.createElement(Avatar, __assign({ sx: [customClassName, classes.root], variant: sqaure ? 'square' : 'circular' }, other), children || React.createElement(NoPlantImage, null)));
}
AvatarImage.defaultProps = {
    sqaure: false,
};
