import React from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';
import clsx from 'clsx';
import useStyles from './NurseryHeaderStyles';
import Location from '../../themes/icons/Location';
import Call from '../../themes/icons/Call';
import Email from '../../themes/icons/Email';
import Website from '../../themes/icons/Website';
var NurseryHeader = React.forwardRef(function (props, ref) {
    var data = props.data, promoHeight = props.promoHeight, scrolled = props.scrolled, mobNavOpen = props.mobNavOpen, heroes = props.heroes;
    var classes = useStyles({ scrolled: scrolled, mobNavOpen: mobNavOpen });
    var _a = React.useState({
        name: '',
        contributorType: [],
        location: '',
        locationShort: '',
        email: '',
        website: '',
        phone: '',
        avatar: '',
        photos: heroes,
        description: '',
        shopifyHandle: '',
    }), state = _a[0], setState = _a[1];
    var _b = React.useState(false), detailOpen = _b[0], setDetailOpen = _b[1];
    React.useEffect(function () {
        if (!isEmpty(data)) {
            setState({
                name: data.name,
                contributorType: data.contributor_type,
                location: data.location,
                locationShort: data.location_short,
                email: data.email,
                website: data.url,
                phone: data.phone,
                avatar: data.avatar,
                photos: heroes,
                description: data.description,
                shopifyHandle: data.shopify_handle,
            });
        }
    }, [data]);
    function toggleDiv() {
        setDetailOpen(function (prev) { return !prev; });
    }
    var contributorType = state.contributorType, name = state.name, shopifyHandle = state.shopifyHandle, avatar = state.avatar, location = state.location, locationShort = state.locationShort, email = state.email, website = state.website, phone = state.phone, photos = state.photos, description = state.description;
    var types = contributorType.map(function (item) { return React.createElement("li", { key: uniqueId(item) }, item); });
    var firstLetter = name.charAt(0);
    var nurseryUrl = "/nurseries/".concat(shopifyHandle, "/");
    var avatarEl = (React.createElement(Link, { underline: "none", href: nurseryUrl },
        React.createElement("span", null, firstLetter)));
    if (avatar) {
        avatarEl = (React.createElement(Link, { underline: "none", href: nurseryUrl },
            React.createElement("img", { src: avatar, alt: name })));
    }
    var locationEl;
    var emailEl;
    var websiteEl;
    var phoneEl;
    if (location) {
        var gmapsUrl = "https://www.google.com/maps/search/".concat(encodeURIComponent(location));
        locationEl = (React.createElement("li", { className: "location" },
            React.createElement(Location, null),
            React.createElement(Link, { underline: "none", href: gmapsUrl, target: "_blank", rel: "noopener noreferrer" }, locationShort)));
    }
    if (email) {
        emailEl = (React.createElement("li", { className: "email" },
            React.createElement(Email, null),
            React.createElement(Link, { underline: "none", href: "mailto:".concat(email) }, "email")));
    }
    if (website) {
        websiteEl = (React.createElement("li", { className: "website" },
            React.createElement(Website, null),
            React.createElement(Link, { underline: "none", href: website, target: "_blank", rel: "noopener noreferrer" }, "website")));
    }
    if (phone) {
        var phoneLink = "tel:".concat(phone);
        phoneEl = (React.createElement("li", { className: "phone" },
            React.createElement(Call, null),
            React.createElement(Link, { underline: "none", href: phoneLink }, phone)));
    }
    var images;
    if (photos && photos.length > 3) {
        images = photos.map(function (item) { return React.createElement("img", { src: item, key: uniqueId(item), alt: name }); });
    }
    var detailClass = clsx(classes.nureryDetails, detailOpen && ' open');
    return (React.createElement("div", { className: photos && photos.length < 4
            ? classes.navWrapperHidden
            : clsx(classes.navWrapper, classes.minHeight), style: { marginTop: window.innerWidth < 768 && promoHeight + 56 }, ref: ref },
        React.createElement(Grid, { container: true, justifyContent: "space-between", alignItems: "stretch", sx: {
                m: 'auto',
                maxWidth: 1104,
            }, flexWrap: "nowrap", className: classes.nurseryHeader },
            React.createElement(Grid, { className: classes.nurseryHeaderWrapper },
                React.createElement("div", { className: classes.nurseryHeaderGroup },
                    React.createElement("div", { className: classes.nurseryAvatar }, avatarEl),
                    React.createElement(Typography, { variant: "h1", className: classes.nurseryTypographyH1 },
                        React.createElement(Link, { underline: "none", href: nurseryUrl }, name))),
                React.createElement(Grid, { container: true, justifyContent: "center", flexDirection: "column", wrap: "nowrap", className: detailClass },
                    React.createElement(Button, { onClick: toggleDiv, onKeyDown: toggleDiv, fullWidth: true, disableElevation: true, disableFocusRipple: true, disableRipple: true, endIcon: detailOpen ? React.createElement(KeyboardArrowUpIcon, null) : React.createElement(KeyboardArrowDownIcon, null) }, "More Nursery info"),
                    React.createElement("ul", { className: classes.nurseryHeaderTypeList }, types),
                    React.createElement(Typography, { className: classes.nurseryHeaderDescription }, description),
                    React.createElement("ul", { className: classes.nurseryHeaderInfoList },
                        locationEl,
                        emailEl,
                        websiteEl,
                        phoneEl))),
            React.createElement("div", { className: classes.nurseryHeaderImages }, images))));
});
export default NurseryHeader;
