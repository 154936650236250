var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function dry_when_dormant(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 -7 65 77" }),
        React.createElement("path", { d: "M54,8.6h-6.3c-0.5,0-0.9-0.3-1.1-0.7c-0.2-0.4-0.1-0.9,0.3-1.2l5-5h-4.1c-0.6,0-1-0.4-1-1s0.4-1,1-1H54\n    c0.7,0,1,0.5,1.1,0.7c0.2,0.4,0.1,0.9-0.3,1.2l-5,5H54c0.6,0,1,0.4,1,1S54.6,8.6,54,8.6z M54,1.7L54,1.7L54,1.7z M53.4,0.2\n    C53.4,0.2,53.4,0.2,53.4,0.2L53.4,0.2z" }),
        React.createElement("path", { d: "M44.4,12h-5.2c-0.5,0-0.9-0.3-1.1-0.7c-0.2-0.4-0.1-0.9,0.3-1.2l3.8-3.8h-3c-0.6,0-1-0.4-1-1s0.4-1,1-1h5.1\n    c0.5,0,0.9,0.3,1.1,0.7c0.2,0.4,0.1,0.9-0.3,1.2L41.3,10h3.1c0.6,0,1,0.4,1,1S45,12,44.4,12z M44.3,6.3L44.3,6.3L44.3,6.3z" }),
        React.createElement("path", { d: "M54.6,19.6h-5.2c-0.5,0-0.9-0.3-1.1-0.7c-0.2-0.4-0.1-0.9,0.3-1.2l3.8-3.8h-3c-0.6,0-1-0.4-1-1s0.4-1,1-1\n    h5.1c0.7,0,1,0.5,1.1,0.7c0.2,0.4,0.1,0.9-0.3,1.2l-3.8,3.8h3.1c0.6,0,1,0.4,1,1S55.2,19.6,54.6,19.6z M54.5,13.9L54.5,13.9\n    L54.5,13.9z M53.9,12.4C53.9,12.4,53.9,12.4,53.9,12.4L53.9,12.4z" }),
        React.createElement("path", { d: "M44.8,26.2L44.8,26.2c-4.3-3.5-9.6-5.6-15.1-6v-1.8c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v1.8\n    c-5.5,0.4-10.8,2.5-15.1,6c-4.4,3.6-7.6,8.6-9,14.1l-0.9,3.4L2.9,41c1.6-1.9,4.6-3,7.8-3s6.3,1.2,7.9,3.2l0.6,0.5l0.5-0.6\n    c1.5-1.6,3.6-2.6,5.8-2.9v22.4c0,3.8,3.1,6.9,6.9,6.9s6.9-3.1,6.9-6.9c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2c0.1,1.6-1.1,3-2.7,3.1\n    c-1.6,0.1-3-1.1-3.1-2.7c0-0.1,0-0.2,0-0.3V38.3c2.3,0.3,4.4,1.3,5.9,3l0.6,0.5l0.5-0.6c1.6-1.9,4.6-3,7.8-3s6.3,1.2,7.8,3\n    l2.3,2.7l-0.9-3.4C52.4,34.8,49.2,29.8,44.8,26.2z M46.5,34c-3.6-0.4-7.3,0.3-10.5,2.2c-2.5-1.6-5.5-2.4-8.5-2.3\n    c-3,0-5.9,0.8-8.5,2.3c-3.1-1.9-6.8-2.7-10.5-2.2c7.2-10.4,21.6-13,32-5.8C43,29.8,45,31.7,46.5,34z" })));
}
