var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function attr_7(props) {
    // soil needs
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 63 65" }),
        React.createElement("path", { d: "M62.3,11.1 C62.4,10.4 62.2,9.8 61.7,9.3 C61.2,8.7 60.4,8.4 59.5,8.4 L54.3,8.4 C53.5,6.8 51.8,5.7 50.1,5.7 C49.4,5.7 48.7,5.9 48,6.2 C46.9,3.7 44.4,2 41.6,2 C40.3,2 39,2.4 37.8,3.1 L37.7,3.1 C36.4,3.1 35.1,3.5 34.1,4.2 C33.6,4.1 33.2,4 32.8,4 C31.7,4 30.8,4.4 30.1,4.7 C28.8,2 26.1,0.3 23.1,0.3 C20.7,0.3 18.6,1.3 17.1,3.2 C16.9,2.8 16.6,2.5 16.2,2.3 C15.1,1.8 13.7,2.7 12.8,4.3 C12.2,4.1 11.7,4 11.1,4 C8.7,4 6.7,5.8 6.4,8.3 L3.1,8.3 C1.5,8.3 0.2,9.5 0.2,11 L0.2,20 C0.2,21.5 1.5,22.7 3.1,22.7 L3.8,22.7 C3.7,23.2 3.7,23.7 3.9,24.2 L14.2,61.6 C14.6,63.2 16,64.2 17.6,64.2 L44.8,64.2 C46.4,64.2 47.9,63.1 48.2,61.6 L58.5,24.1 C58.6,23.6 58.6,23.1 58.6,22.6 L59.3,22.6 C60.9,22.6 62.2,21.4 62.2,19.9 L62.3,11.1 Z M55.8,23.5 L45.4,61 C45.4,61.2 45.1,61.4 44.9,61.4 L17.8,61.4 C17.6,61.4 17.4,61.3 17.3,61 L6.9,23.5 C6.8,23.2 7,23.1 7,23 L7,22.9 C7,22.8 7.2,22.7 7.4,22.7 L55.1,22.7 C55.5,22.7 55.6,22.9 55.6,22.9 L55.8,22.8 L55.8,22.8 L55.6,22.9 C55.8,23.1 55.9,23.4 55.8,23.5 Z M59.3,11.5 L59.3,19.8 L3.4,19.8 L3.4,11.5 L59.3,11.5 Z" })));
}
