var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function attr_5(props) {
    // water needs
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 45 64" }),
        React.createElement("path", { d: "M21,0.6 C21,0.6 20.9,0.7 20.9,0.7 L20.8,0.8 L3.2,31.2 C1.3,34.5 0.3,38.3 0.3,42.1 C0.3,54.2 10.2,64 22.3,64 C34.4,64 44.3,54.2 44.3,42.1 C44.3,38.3 43.3,34.5 41.4,31.3 L41.4,31.2 L23.8,0.8 C23.7,0.7 23.7,0.6 23.6,0.6 C23.6,0.6 23.5,0.5 23.5,0.5 C23.5,0.5 23.4,0.4 23.4,0.4 C23.4,0.4 23.3,0.3 23.3,0.3 L23.2,0.2 C23.1,0.2 23,0.1 23,0.1 C22.9,0.1 22.9,0.1 22.9,0 C22.9,0 22.8,0 22.8,0 C22.7,0 22.7,0 22.6,0 C22.6,0 22.5,0 22.5,0 C22.5,0 22.5,0 22.5,0 C22.4,0 22.4,0 22.4,0 C22.4,0 22.3,0 22.3,0 C22.2,0 22.2,0 22.1,0 L22,0 C21.9,0 21.8,0.1 21.8,0.1 L21.7,0.2 C21.6,0.2 21.6,0.3 21.5,0.3 C21.5,0.3 21.4,0.4 21.4,0.4 C21.1,0.5 21,0.6 21,0.6 Z M22.3,5 L38.5,32.9 C40.1,35.7 41,38.9 41,42.1 C41,47.1 39.1,51.7 35.5,55.2 C32,58.7 27.3,60.6 22.3,60.6 C12,60.6 3.6,52.3 3.6,42 C3.6,38.8 4.4,35.6 6.1,32.8 L22.3,5 Z" })));
}
