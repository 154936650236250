var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function mostly_shade(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "-8 -8 62 62" }),
        React.createElement("path", { d: "M44.1,21.1 C44,19.9 43.8,18.7 43.6,17.6 C43.3,16.5 43,15.5 42.6,14.5 C42.1,13.3 41.5,12.1 40.8,11 C40.1,9.9 39.3,8.9 38.5,7.9 C37.3,6.6 35.9,5.4 34.4,4.4 C30.9,2.1 26.7,0.7 22.3,0.7 C10.2,0.7 0.3,10.6 0.3,22.7 C0.3,34.8 10.2,44.7 22.3,44.7 C26.8,44.7 31,43.3 34.4,41 C35.9,40 37.3,38.8 38.5,37.5 C39.4,36.5 40.2,35.5 40.8,34.4 C41.5,33.3 42.1,32.1 42.6,30.9 C43,29.9 43.3,28.9 43.6,27.8 C43.9,26.6 44.1,25.5 44.1,24.3 C44.1,23.8 44.2,23.3 44.2,22.8 C44.2,22.1 44.1,21.6 44.1,21.1 Z M39.2,27.7 C38.9,28.8 38.5,29.8 37.9,30.8 L12.3,30.8 L12.3,34.3 L35.5,34.3 C34.5,35.5 33.3,36.5 32,37.4 L12.4,37.4 C7.6,34.2 4.4,28.8 4.4,22.6 C4.4,16.4 7.6,11 12.4,7.8 L32,7.8 C33.3,8.7 34.5,9.7 35.5,10.9 L12.3,10.9 L12.3,14.4 L38,14.4 C38.5,15.4 38.9,16.4 39.3,17.5 L12.3,17.5 L12.3,21 L39.9,21 C39.9,21.5 40,22 40,22.5 C40,23 40,23.5 39.9,24 L12.3,24 L12.3,27.5 L39.2,27.5 L39.2,27.7 Z", id: "s_mostly_shade", fill: "inherit", fillRule: "nonzero" })));
}
