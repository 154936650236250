var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function attr_2(props) {
    return (
    // sun exposure
    React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 65 65" }),
        React.createElement("path", { d: "M34.4,2.7 C34.4,1.5 33.5,0.6 32.3,0.6 C31.1,0.6 30.2,1.5 30.2,2.7 L30.2,12.2 C30.2,13.4 31.1,14.3 32.3,14.3 C33.5,14.3 34.4,13.4 34.4,12.2 L34.4,2.7 Z" }),
        React.createElement("path", { d: "M34.4,53 C34.4,51.8 33.5,50.9 32.3,50.9 C31.1,50.9 30.2,51.8 30.2,53 L30.2,62.5 C30.2,63.7 31.1,64.6 32.3,64.6 C33.5,64.6 34.4,63.7 34.4,62.5 L34.4,53 Z" }),
        React.createElement("path", { d: "M62.2,30.5 L52.7,30.5 C51.5,30.5 50.6,31.4 50.6,32.6 C50.6,33.8 51.5,34.7 52.7,34.7 L62.2,34.7 C63.4,34.7 64.3,33.8 64.3,32.6 C64.3,31.4 63.3,30.5 62.2,30.5 Z" }),
        React.createElement("path", { d: "M11.9,30.5 L2.4,30.5 C1.2,30.5 0.3,31.4 0.3,32.6 C0.3,33.8 1.2,34.7 2.4,34.7 L11.9,34.7 C12.5,34.7 13,34.5 13.4,34.1 C13.8,33.7 14,33.2 14,32.6 C14,31.4 13,30.5 11.9,30.5 Z" }),
        React.createElement("path", { d: "M55.5,11.5 C55.5,10.9 55.3,10.4 54.9,10 C54.5,9.6 54,9.4 53.4,9.4 C53.4,9.4 53.4,9.4 53.4,9.4 C52.8,9.4 52.3,9.6 51.9,10 L45.2,16.7 C44.8,17.1 44.6,17.6 44.6,18.2 C44.6,18.8 44.8,19.3 45.2,19.7 C46,20.5 47.4,20.5 48.2,19.7 L54.9,13 C55.3,12.6 55.5,12 55.5,11.5 Z" }),
        React.createElement("path", { d: "M19.3,48.5 C20.1,47.7 20.1,46.3 19.3,45.5 C18.9,45.1 18.4,44.9 17.8,44.9 C17.2,44.9 16.7,45.1 16.3,45.5 L9.6,52.2 C9.2,52.6 9,53.1 9,53.7 C9,54.3 9.2,54.8 9.6,55.2 C10.4,56 11.8,56 12.6,55.2 L19.3,48.5 Z" }),
        React.createElement("path", { d: "M45.2,48.5 L51.9,55.2 C52.7,56 54.1,56 54.9,55.2 C55.3,54.8 55.5,54.3 55.5,53.7 C55.5,53.1 55.3,52.6 54.9,52.2 L48.2,45.5 C47.8,45.1 47.2,44.9 46.7,44.9 C46.2,44.9 45.6,45.1 45.2,45.5 C44.8,45.9 44.6,46.4 44.6,47 C44.6,47.6 44.8,48.1 45.2,48.5 Z" }),
        React.createElement("path", { d: "M19.3,16.7 L12.6,10 C12.2,9.6 11.7,9.4 11.1,9.4 C10.5,9.4 10,9.6 9.6,10 C9.2,10.4 9,10.9 9,11.5 C9,12.1 9.2,12.6 9.6,13 L16.3,19.7 C17.1,20.5 18.5,20.5 19.3,19.7 C20.2,18.9 20.2,17.5 19.3,16.7 Z" }),
        React.createElement("path", { d: "M32.3,18.3 C24.3,18.3 17.8,24.8 17.8,32.8 C17.8,40.8 24.3,47.3 32.3,47.3 C40.3,47.3 46.8,40.8 46.8,32.8 C46.8,24.8 40.3,18.3 32.3,18.3 Z M22,32.7 C22,27 26.6,22.4 32.3,22.4 L32.3,43 C26.6,43 22,38.4 22,32.7 Z" })));
}
