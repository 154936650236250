var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function Website(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 24 24" }),
        React.createElement("rect", { id: "Rectangle", fill: "#FFFFFF", x: "0", y: "0", width: "24", height: "24" }),
        React.createElement("path", { d: "M12,2 L0,14 L0,15.4761791 L2.86567164,15.4761791 L2.86567164,22.6403582 L11.1044776,22.6403582 L11.1044776,18.3418507 L12.8955224,18.3418507 L12.8955224,22.6403582 L21.1346866,22.6403582 L21.1346866,15.4761791 L24,15.4761791 L24,14 L19.7014925,9.70113433 L19.7014925,2.93886567 L15.761194,2.93886567 L15.761194,5.76083582 L12,2 Z M12,3.51952239 L16.8358209,8.35534328 L16.8358209,4.01313433 L18.6268657,4.01313433 L18.6268657,10.1463881 L22.8823881,14.401194 L20.0597015,14.401194 L20.0597015,21.5657313 L13.9701493,21.5657313 L13.9701493,17.2668657 L10.0298507,17.2668657 L10.0298507,21.5657313 L3.94029851,21.5657313 L3.94029851,14.401194 L1.11797015,14.401194 L12,3.51952239 Z", id: "Page-1", fill: "#000000" })));
}
