var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function attr_8(props) {
    // fruit
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 49 65" }),
        React.createElement("path", { d: "M26.6,18.3 L26.4,18.3 L26.4,18.1 C26.2,14.9 26.8,6.9 36.1,3.3 C36.7,3 37.1,2.4 36.9,1.7 C36.8,1.3 36.5,1 36.2,0.8 C35.9,0.6 35.5,0.6 35.1,0.7 C27.2,3.8 24.7,9.6 24,14 L24,15 L23.8,14.2 C22.4,9 18.4,6.1 12.5,6.1 C8.4,6.1 4.7,7.4 4.7,7.4 L3.4,8 L3.8,9.5 C5.8,15.6 9.3,19.1 14.2,20 L14.8,20.1 L14.2,20.4 C5.7,24.2 0.2,32.5 0.2,41.6 C0.2,47.7 2.7,53.6 7.3,58 C11.9,62.4 17.9,64.9 24.2,64.9 C37.4,64.9 48.2,54.5 48.2,41.6 C48.3,29.7 38.8,19.5 26.6,18.3 Z M6.9,10 L6.8,9.8 L7,9.7 C7.6,9.5 9.7,8.8 12.6,8.8 C17.7,8.8 20.6,11.2 21.5,16.2 L21.5,16.4 L21.4,16.5 C20.4,16.9 18.5,17.4 16.6,17.4 C12.2,17.3 9,14.9 6.9,10 Z M3,41.5 C3,30.5 12.1,21.5 23.5,21.1 C23.9,21.1 24.3,21.4 24.3,21.9 L24.3,61.2 C24.3,61.6 23.9,62 23.5,62 C12.1,61.5 3,52.5 3,41.5 Z", id: "fruit", fill: "inherit", fillRule: "nonzero" })));
}
