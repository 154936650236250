import React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import AutoCompleteItem from './AutoCompleteItem';
import classes from './AutoCompleteStyles';
export default function AutoComplete(props) {
    var acData = props.acData, selectedIndex = props.selectedIndex, handleSubmit = props.handleSubmit, handleAutocompleteItemSelect = props.handleAutocompleteItemSelect;
    var items = Object.keys(acData.data).map(function (key, i) { return (React.createElement(AutoCompleteItem, { key: key, index: i, query: acData.query, isFirst: acData.data[key].first, queryType: acData.data[key].type, queryLabel: acData.suggestions[key], submitQuery: handleSubmit, handleAutocompleteItemSelect: handleAutocompleteItemSelect, isSelected: selectedIndex === i })); });
    return (React.createElement(Grid, { sx: classes.searchAutoComplete },
        React.createElement(List, null, items)));
}
