import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CustomButton from './CustomButton';
export default function ConfirmationBackdrop(props) {
    var open = props.open, close = props.close, mainMessage = props.mainMessage, secondMessages = props.secondMessages;
    return (React.createElement(Backdrop, { sx: {
            bgcolor: 'rgba(92, 106, 116, 0.85)',
            zIndex: 10000,
            flexDirection: 'column',
            p: 3.75,
        }, open: open, onClick: close, timeout: { enter: 10, exit: 100 }, unmountOnExit: true },
        React.createElement(Typography, { align: "center", color: "background.default", mb: 2, fontSize: 28, fontWeight: 300 }, mainMessage), secondMessages === null || secondMessages === void 0 ? void 0 :
        secondMessages.map(function (item) { return (React.createElement(Typography, { key: item, align: "center", color: "background.default", mb: 2, fontSize: 18, fontWeight: 300, lineHeight: 1.78 }, item)); }),
        React.createElement(CustomButton, { buttonText: "Close", size: "large", variant: "outlined", sx: {
                border: 1,
                borderColor: 'background.default',
                color: 'background.default',
                fontWeight: 600,
                textTransform: 'capitalize',
                py: 1.5,
                px: 3.5,
                ':hover': {
                    bgcolor: 'primary.contrastText',
                    borderColor: 'background.default',
                },
            } }),
        React.createElement(Button, { size: "large", variant: "outlined", disableFocusRipple: true, disableRipple: true, disableElevation: true, disableTouchRipple: true, focusRipple: true, sx: {
                border: 1,
                borderColor: 'background.default',
                color: 'background.default',
                fontWeight: 600,
                textTransform: 'capitalize',
                py: 1.5,
                px: 3.5,
                ':hover': {
                    bgcolor: 'primary.contrastText',
                    borderColor: 'background.default',
                },
            } }, "Close")));
}
ConfirmationBackdrop.defaultProps = {
    secondMessages: [],
};
