var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CustomLoader from './CustomLoader';
export default function QuantityPicker(props) {
    var _this = this;
    var plant = props.plant, changeQuantity = props.changeQuantity;
    var _a = React.useState(plant.quantity), quantity = _a[0], setQuantity = _a[1];
    var _b = React.useState(false), changeLoading = _b[0], setChangeLoading = _b[1];
    React.useEffect(function () {
        setQuantity(plant.quantity);
    }, [plant.quantity]);
    var updateQuantity = function (e) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    plant.quantity = parseInt(e.target.value, 10) || quantity;
                    if (!(quantity >= 0)) return [3 /*break*/, 2];
                    setChangeLoading(true);
                    return [4 /*yield*/, changeQuantity(plant)];
                case 1:
                    _a.sent();
                    if (plant.quantity !== 0)
                        setChangeLoading(false);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var changeQuantityValue = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var newQuantity;
        return __generator(this, function (_a) {
            newQuantity = parseInt(e.target.value, 10);
            if (newQuantity !== quantity)
                setQuantity(newQuantity);
            return [2 /*return*/];
        });
    }); };
    var keyPress = function (e) {
        if (e.key === 'Enter') {
            var newQuantity = parseInt(e.currentTarget.value, 10);
            setQuantity(newQuantity);
            updateQuantity(e);
        }
    };
    var changeQuantityOnClick = function (operation) { return __awaiter(_this, void 0, void 0, function () {
        var newQuantity;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newQuantity = 0;
                    if (operation === 'add') {
                        newQuantity = quantity + 1;
                    }
                    else if (operation === 'minus') {
                        if (quantity === 1)
                            newQuantity = 0;
                        else if (quantity < 2)
                            newQuantity = 1;
                        else
                            newQuantity = quantity - 1;
                    }
                    setQuantity(newQuantity);
                    plant.quantity = newQuantity;
                    setChangeLoading(true);
                    return [4 /*yield*/, changeQuantity(plant)];
                case 1:
                    _a.sent();
                    if (newQuantity > 0)
                        setChangeLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var inputButtonRootSX = {
        padding: 0.25,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& svg': {
            fill: 'primary.dark',
        },
    };
    return (React.createElement(Box, { sx: {
            width: 95,
            display: 'inline-flex',
            border: 1,
            borderColor: 'secondary.dark',
            borderRadius: 1,
        } },
        React.createElement(IconButton, { sx: inputButtonRootSX, "aria-label": "minus", size: "small", onClick: function () { return changeQuantityOnClick('minus'); }, disabled: changeLoading || plant.shopify_quantity === 0 },
            React.createElement(RemoveIcon, null)),
        changeLoading ? (React.createElement(CustomLoader, { loaderAttributes: { size: 22, thickness: 5 } }))
            : (React.createElement(Input, { value: quantity.toString(), sx: {
                    borderLeft: function (theme) { return "1px solid ".concat(theme.palette.secondary.dark); },
                    borderRight: function (theme) { return "1px solid ".concat(theme.palette.secondary.dark); },
                    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        display: 'none',
                    },
                    '& .MuiInput-input': {
                        minWidth: 37,
                        height: 16,
                        textAlign: 'center',
                        MozAppearance: 'textfield',
                    },
                }, onBlur: updateQuantity, onChange: changeQuantityValue, onKeyDown: keyPress, disabled: changeLoading || plant.shopify_quantity === 0, type: "number", disableUnderline: true, inputProps: {
                    min: 1,
                    pattern: '[0-9]*',
                    'aria-label': 'quantity',
                } })),
        React.createElement(IconButton, { sx: inputButtonRootSX, "aria-label": "add", size: "small", onClick: function () { return changeQuantityOnClick('add'); }, disabled: changeLoading || plant.shopify_quantity === 0 },
            React.createElement(AddIcon, null))));
}
