import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PdpEmail from '../PlantDetail/pdpEmail';
var classes = {
    itemAvailableRoot: function (theme) {
        var _a;
        return (_a = {
                minHeight: theme.spacing(5.5),
                backgroundColor: "".concat(theme.palette.orangeColors.main, "33"),
                width: '100%',
                position: 'absolute',
                bottom: theme.spacing(1.5),
                paddingLeft: theme.spacing(2.25),
                borderRadius: 2
            },
            _a[theme.breakpoints.down('sm')] = {
                padding: theme.spacing(1),
                paddingLeft: theme.spacing(2.25),
            },
            _a);
    },
    itemAvailableText: function (theme) {
        var _a;
        return (_a = {
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.primary.dark,
                fontSize: theme.spacing(2)
            },
            _a[theme.breakpoints.down('sm')] = {
                fontSize: theme.spacing(1.5),
            },
            _a);
    },
    notifyMeText: function (theme) {
        var _a;
        return (_a = {
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.primary.dark,
                fontSize: theme.spacing(2),
                textDecoration: 'underline',
                cursor: 'pointer'
            },
            _a[theme.breakpoints.down('sm')] = {
                fontSize: theme.spacing(1.5),
            },
            _a);
    },
};
export default function ItemAvailability(props) {
    var _a = React.useState(false), overly = _a[0], setOverly = _a[1];
    var plant = props.plant;
    var handleClick = function () {
        setOverly(function (prev) { return !prev; });
    };
    var emailOverlay = null;
    if (overly) {
        emailOverlay = (React.createElement("div", { className: "email_overlay", id: "emailOverlay" },
            React.createElement("div", { className: "email_overlay_container container" },
                React.createElement("span", { role: "button", className: "close-btn", onClick: handleClick, tabIndex: 0, onKeyDown: handleClick }, "close"),
                React.createElement(PdpEmail, { plantId: plant.plant_id, plantName: plant.name, closeModal: handleClick }))));
    }
    return (React.createElement(Grid, { container: true, sx: classes.itemAvailableRoot },
        React.createElement(Typography, { sx: classes.itemAvailableText },
            plant.shopify_quantity === 0 ? 'This plant is no longer in available from this nursery.'
                : plant.quantity_been_reduced && "Only ".concat(plant.shopify_quantity, " left, your cart contents have been adjusted."),
            "\u00A0"),
        React.createElement(Typography, { sx: classes.notifyMeText, onClick: handleClick }, plant.shopify_quantity === 0 ? 'Notify me when it\'s available'
            : plant.quantity_been_reduced && 'Notify me when more are available'),
        emailOverlay));
}
