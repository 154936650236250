import React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import parse from 'html-react-parser';
import isEmpty from 'lodash/isEmpty';
export default function PromoHeader(props) {
    var activePromo = props.activePromo, enablePromoHeader = props.enablePromoHeader, scrolled = props.scrolled;
    var promoHeader = React.useRef();
    React.useEffect(function () {
        function handleResize() {
            var _a;
            var promoHeight = (_a = promoHeader === null || promoHeader === void 0 ? void 0 : promoHeader.current) === null || _a === void 0 ? void 0 : _a.offsetHeight;
            enablePromoHeader(promoHeight);
        }
        if (activePromo) {
            handleResize();
            var timeout_1;
            window.onresize = function () {
                clearTimeout(timeout_1);
                timeout_1 = setTimeout(handleResize, 100);
            };
        }
        return function cleanUp() {
            if (!isEmpty(activePromo)) {
                var timeout_2;
                window.onresize = function () {
                    clearTimeout(timeout_2);
                    timeout_2 = setTimeout(handleResize, 100);
                };
            }
        };
    }, [activePromo, enablePromoHeader, promoHeader]);
    if (!isEmpty(activePromo) && !scrolled) {
        return (React.createElement(Grid, { container: true, ref: promoHeader, sx: {
                justifyContent: 'center',
                backgroundColor: 'secondary.light',
            } },
            React.createElement(Link, { key: activePromo.cta_url, sx: {
                    py: 2.5, color: '#FFFFFF !important',
                }, underline: "none", href: activePromo.cta_url, "aria-label": activePromo.promo_text }, parse(activePromo.promo_text || ''))));
    }
    return null;
}
