var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function high(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 76 65" }),
        React.createElement("path", { d: "M35.3,1.2L35.3,1.2l-0.2,0.2L17.4,31.8c-6,10.5-2.4,24,8.2,30c10.5,6,24,2.4,30-8.2\n    c1.9-3.3,2.9-7.1,2.9-11c0-3.8-1-7.5-2.9-10.8v-0.1L38.1,1.4l-0.2-0.2L37.8,1l-0.1-0.1l-0.2-0.1l-0.1-0.1l-0.2-0.1l-0.1-0.1H37\n    h-0.2h-0.3h-0.1h-0.2h-0.1l-0.2,0.1l-0.1,0.1l-0.2,0.1L35.5,1L35.3,1.2z M36.6,5.5l16.2,27.9c5.1,9,2,20.4-6.9,25.5\n    s-20.4,2-25.5-6.9c-3.3-5.7-3.3-12.8,0-18.5L36.6,5.5z" }),
        React.createElement("path", { d: "M2.6,15.5h-2c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h2c0.8,0,1.5,0.7,1.5,1.5S3.4,15.5,2.6,15.5z" }),
        React.createElement("path", { d: "M62.6,15.5h-4c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h4c0.8,0,1.5,0.7,1.5,1.5S63.4,15.5,62.6,15.5z\n     M50.6,15.5h-4c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h4c0.8,0,1.5,0.7,1.5,1.5S51.4,15.5,50.6,15.5z M38.6,15.5h-4\n    c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h4c0.8,0,1.5,0.7,1.5,1.5S39.4,15.5,38.6,15.5z M26.6,15.5h-4c-0.8,0-1.5-0.7-1.5-1.5\n    s0.7-1.5,1.5-1.5h4c0.8,0,1.5,0.7,1.5,1.5S27.4,15.5,26.6,15.5z M14.6,15.5h-4c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h4\n    c0.8,0,1.5,0.7,1.5,1.5S15.4,15.5,14.6,15.5z" }),
        React.createElement("path", { d: "M72.6,15.5h-2c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h2c0.8,0,1.5,0.7,1.5,1.5S73.4,15.5,72.6,15.5z" }),
        React.createElement("polygon", { points: "56.4,37.5 56.2,37.5 55.3,34.7 46.3,18.8 27.1,18.8 18.6,33.4 16.6,37.5 15.7,43.8\n    16.6,48.3 19.7,54 23.9,58.6 30.3,61.8 36.9,63.3 44.9,61.2 51.3,56.5 55.8,50.1 57.3,41.9" })));
}
