var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function plant_size(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 60 65" }),
        React.createElement("path", { d: "M38.0921667,39.128375 C38.0921667,40.217125 38.9805,41.1000417 40.0746667,41.1000417 L44.0396667,41.1000417 C45.1338333,41.1000417 46.0221667,40.217125 46.0221667,39.128375 L46.0221667,33.213375 L57.9171667,33.213375 L42.0571667,1.65858333 L26.1971667,33.213375 L38.0921667,33.213375 L38.0921667,39.128375 Z" }),
        React.createElement("path", { d: "M1.67077083,40.4606042 L16.3716042,40.4606042 C17.2924375,40.4606042 18.0399375,39.7158125 18.0399375,38.8003958 C18.0399375,37.8849792 17.2924375,37.1401875 16.3716042,37.1401875 L10.6868125,37.1401875 L10.6868125,3.32122917 L16.3716042,3.32122917 C17.2924375,3.32122917 18.0399375,2.5764375 18.0399375,1.66102083 C18.0399375,0.745604167 17.2924375,0.0008125 16.3716042,0.0008125 L1.67077083,0.0008125 C0.7499375,0.0008125 -0.000270833333,0.745604167 -0.000270833333,1.66102083 C-0.000270833333,2.5764375 0.7499375,3.32122917 1.67077083,3.32122917 L7.35014583,3.32122917 L7.35014583,37.1401875 L1.67077083,37.1401875 C0.7499375,37.1401875 -0.000270833333,37.8849792 -0.000270833333,38.8003958 C-0.000270833333,39.7158125 0.7499375,40.4606042 1.67077083,40.4606042" }),
        React.createElement("path", { d: "M57.915,65 C58.8358333,65 59.5833333,64.2552083 59.5833333,63.3397917 L59.5833333,48.7147917 C59.5833333,47.799375 58.8358333,47.0545833 57.915,47.0545833 C56.996875,47.0545833 56.2466667,47.799375 56.2466667,48.7147917 L56.2466667,54.3725 L27.8660417,54.3725 L27.8660417,48.7147917 C27.8660417,47.799375 27.1158333,47.0545833 26.1977083,47.0545833 C25.276875,47.0545833 24.529375,47.799375 24.529375,48.7147917 L24.529375,63.3397917 C24.529375,64.2552083 25.276875,65 26.1977083,65 C27.1158333,65 27.8660417,64.2552083 27.8660417,63.3397917 L27.8660417,57.6902083 L56.2466667,57.6902083 L56.2466667,63.3397917 C56.2466667,64.2552083 56.996875,65 57.915,65" })));
}
