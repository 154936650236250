var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function attr_3(props) {
    // foliage
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 65 64" }),
        React.createElement("path", { d: "M44.7,22 C48.9,21.9 53.6,19.5 57.7,15.4 C62.1,11 64.5,5.8 64.3,1.4 C64.3,0.8 63.7,0.3 63.1,0.2 C56.6,0.1 50.8,5.2 49.2,6.8 C45.1,10.9 42.7,15.6 42.6,19.7 L42.6,20.1 L38.8,23.9 L39.1,21 C39.2,20 39.2,19.2 39.2,18.3 C39.2,12.3 37.3,7.1 34.1,4.3 C33.8,4.1 33.5,4 33.2,4 C33,4 32.7,4.1 32.5,4.3 C29.4,7.1 27.5,12.3 27.5,18.3 C27.5,23.2 28.7,27.5 30.9,30.5 L31.4,31.2 L30.8,31.8 C28.5,34.2 26,36.6 23.9,38.7 L21.9,40.7 L22.2,37.8 C22.3,36.9 22.3,35.9 22.3,35.1 C22.3,29.1 20.4,23.8 17.3,21.1 C17.1,20.9 16.8,20.8 16.5,20.8 C16.5,20.8 16.4,20.8 16.4,20.8 C16.1,20.8 15.9,20.9 15.7,21.1 C12.5,23.9 10.7,29.1 10.6,35.1 C10.6,39.9 11.9,44.4 14,47.3 L14.5,48 L13.9,48.6 C10.5,52 7.1,55.4 3.7,58.8 L0.8,61.7 C0.5,61.9 0.4,62.3 0.4,62.7 C0.4,63.1 0.5,63.4 0.7,63.6 C0.9,63.8 1.3,63.9 1.6,63.9 C2,63.9 2.3,63.7 2.5,63.5 L13.1,52.9 L13.8,53.4 C16.8,55.6 21.3,56.9 26.1,56.9 C32.1,56.9 37.3,55 40.1,51.8 C40.5,51.4 40.5,50.6 40.1,50.1 C37.3,47 32.1,45.1 26.1,45.1 C25.1,45.1 24.1,45.2 23.1,45.3 L20.2,45.6 L29.9,35.9 L30.6,36.4 C33.6,38.6 38.1,39.9 42.9,39.9 C48.9,39.9 54.1,38 56.9,34.8 C57.3,34.4 57.3,33.6 56.9,33.1 C54.1,30 48.9,28.1 42.9,28.1 C41.9,28.1 40.9,28.2 39.9,28.3 L37,28.6 L44.2,21.4 L44.7,22 Z M16.5,46.6 L16.4,46.7 L16.3,46.6 C16.4,46.6 16.4,46.6 16.5,46.6 Z M26.1,48.1 L26.1,48.1 C29.5,48.1 33.1,49 35.8,50.5 L37.4,51.4 L35.8,52.3 C33.1,53.8 29.4,54.7 26.1,54.7 C22.7,54.7 19.1,53.8 16.3,52.3 L14.7,51.4 L16.3,50.5 C19.1,49.1 22.8,48.1 26.1,48.1 Z M30,18.5 C30,15.1 30.9,11.5 32.4,8.7 L33.3,7.1 L34.2,8.7 C35.7,11.4 36.6,15.1 36.6,18.5 C36.6,21.9 35.7,25.5 34.2,28.3 L33.3,29.9 L32.4,28.3 C30.9,25.5 30,21.9 30,18.5 Z" })));
}
