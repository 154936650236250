var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { observer } from 'mobx-react';
import { AutoComplete } from '../AutoComplete';
import ZoneFilter from '../Zones/zoneFilter';
import Pill from '../Nursery/NurseryPill';
import Store from '../../stores/store';
import { getQuery } from '../../utils/helpers';
import { AUTO_SEARCH } from '../../actions/constats';
import { IcnSearch, IcnSearchClear } from '../../themes/icons/attributes';
var CancelToken = axios.CancelToken;
var cancelRequest;
var SearchBar = /** @class */ (function (_super) {
    __extends(SearchBar, _super);
    function SearchBar(props) {
        var _this = _super.call(this, props) || this;
        _this.textInput = React.createRef();
        _this.submitButton = React.createRef();
        _this.collapse = function () {
            _this.setState({ acActive: false });
        };
        _this.handleInputChange = function (e) {
            var acActive = _this.state.acActive;
            var newValue = e.target.value;
            _this.setState({ inputValue: newValue }, function () {
                if (newValue.length >= 3) {
                    clearTimeout(_this.delay);
                    _this.delay = setTimeout(function () {
                        _this.triggerAutocomplete(newValue);
                    }, 50);
                }
                else if (acActive) {
                    if (cancelRequest)
                        cancelRequest();
                    _this.setState({
                        acActive: false,
                        selectedIndex: -1,
                    });
                }
            });
        };
        _this.triggerAutocomplete = function (key) {
            if (cancelRequest)
                cancelRequest();
            var cache = new Date().getTime();
            axios
                .get("".concat(AUTO_SEARCH + key, "&").concat(cache), {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    cancelToken: new CancelToken((function (c) {
                        cancelRequest = c;
                    })),
                },
            })
                .then(function (res) {
                _this.setState({
                    acData: res.data,
                    acActive: res.data.suggestions.length > 0,
                    selectedIndex: -1,
                });
            })
                .catch(function () { });
        };
        _this.clearSearch = function (e) {
            var _a;
            e.preventDefault();
            _this.setState({
                inputValue: '',
                acActive: false,
                selectedIndex: -1,
            });
            if (typeof window !== 'undefined')
                (_a = document === null || document === void 0 ? void 0 : document.getElementById('search_input')) === null || _a === void 0 ? void 0 : _a.focus({ preventScroll: true });
        };
        _this.handleSubmit = function (e, query, queryType) {
            var _a = _this.state, acData = _a.acData, selectedIndex = _a.selectedIndex, activeZone = _a.activeZone;
            var setQueries = _this.props.setQueries;
            var passQuery = query;
            var passQueryType = queryType;
            var url;
            e.preventDefault();
            if (cancelRequest)
                cancelRequest();
            var searchString = '';
            if (selectedIndex !== -1) {
                passQuery = acData.suggestions[selectedIndex];
                passQueryType = acData.data[selectedIndex].type;
            }
            Store.setOrigSearchTerm(passQuery);
            searchString = 'all_plants=True';
            if (passQuery)
                searchString = getQuery(passQuery, passQueryType);
            if (activeZone !== '0') {
                searchString += "&zone=".concat(activeZone);
            }
            var slug = '';
            if (passQueryType === 'Nursery') {
                slug = acData.data[selectedIndex].slug;
            }
            var selectedData = acData.data[selectedIndex];
            if (selectedData) {
                if (selectedData.url) {
                    url = selectedData.url;
                }
                else if (selectedData.url === '') {
                    searchString = "raw=".concat(acData.suggestions[selectedIndex]);
                }
            }
            _this.setState({
                acActive: false,
                inputValue: passQuery,
                selectedIndex: -1,
            });
            setQueries(searchString, passQuery, passQueryType, slug, url);
        };
        _this.clearNurserySearch = function () {
            var _a = _this.props, setQueries = _a.setQueries, showNurseryHeader = _a.showNurseryHeader, history = _a.history;
            Store.setIsNursery(false);
            Store.setNursery(null);
            Store.setNurserySlug(null);
            if (window.location.pathname.indexOf('/plants/') === -1) {
                if (window.location.search && window.location.search.split('?')[1]) {
                    var search = window.location.search.split('?')[1];
                    setQueries(search);
                }
                else {
                    history === null || history === void 0 ? void 0 : history.push('/search/', { getAPIData: true });
                }
            }
            else {
                var path = window.location.href.split('?')[0].split('/plants/')[1];
                history === null || history === void 0 ? void 0 : history.push("/plants/".concat(path));
            }
            _this.setState({
                nursery: '',
            });
            showNurseryHeader(false);
        };
        _this.handleAutocompleteDismiss = function (e) {
            if (e.target !== _this.textInput.current && e.target !== _this.submitButton.current) {
                _this.setState({
                    acActive: false,
                    selectedIndex: -1,
                }, function () { return _this.handleInputKeyDown(e); });
            }
        };
        _this.handleInputKeyDown = function (e) {
            var _a = _this.state, acActive = _a.acActive, acData = _a.acData, selectedIndex = _a.selectedIndex;
            if ((e.key === 'ArrowDown' || e.key === 'ArrowUp') && acActive) {
                e.preventDefault();
                var newState = {};
                if (e.key === 'ArrowDown') {
                    newState.selectedIndex = selectedIndex + 1;
                    if (newState.selectedIndex >= acData.suggestions.length) {
                        newState.selectedIndex = 0;
                    }
                }
                else if (e.key === 'ArrowUp') {
                    newState.selectedIndex = selectedIndex - 1;
                    if (newState.selectedIndex < 0) {
                        newState.selectedIndex = acData.suggestions.length - 1;
                    }
                }
                _this.setState(newState);
            }
        };
        _this.handleAutocompleteItemSelect = function (selectedIndex) {
            _this.setState({
                selectedIndex: selectedIndex,
            });
        };
        _this.passWidth = function (width) {
            _this.setState({
                pillWidth: "".concat(width, "px"),
            });
        };
        _this.state = {
            zonesData: [
                {
                    description: '',
                    order: 0,
                    zone: '',
                    color: '',
                },
            ],
            inputValue: '',
            acActive: false,
            acData: {
                data: [
                    {
                        first: false,
                        id: 0,
                        type: '',
                        raw: '',
                        slug: '',
                        url: '',
                    },
                ],
                query: '',
                suggestions: [],
            },
            activeZone: '0',
            selectedIndex: -1,
            nursery: '',
            pillWidth: '0',
        };
        if (typeof window !== 'undefined')
            document.addEventListener('click', _this.handleAutocompleteDismiss, false);
        return _this;
    }
    SearchBar.prototype.componentDidMount = function () {
        var _a = this.props, zones = _a.zones, activeZone = _a.activeZone, nursery = _a.nursery, searchTerm = _a.searchTerm;
        var newState = {};
        newState.zonesData = zones;
        newState.activeZone = activeZone;
        newState.nursery = nursery;
        if (isEqual(nursery, searchTerm)) {
            newState.inputValue = '';
        }
        else if (nursery !== null
            && searchTerm !== null
            && nursery.toLowerCase() === searchTerm.toLowerCase()) {
            newState.inputValue = '';
        }
        else {
            newState.inputValue = searchTerm;
            Store.setOrigSearchTerm(searchTerm);
        }
        if (!isEmpty(newState)) {
            this.setState(newState);
        }
    };
    SearchBar.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        if (!isEqual(this.props, nextProps)) {
            var newState = {};
            newState.zonesData = nextProps.zones;
            newState.activeZone = nextProps.activeZone;
            newState.nursery = nextProps.nursery;
            if (isEqual(nextProps.nursery, nextProps.searchTerm)) {
                newState.inputValue = '';
            }
            else if (nextProps.nursery !== null
                && nextProps.searchTerm !== null
                && nextProps.nursery.toLowerCase() === nextProps.searchTerm.toLowerCase()) {
                newState.inputValue = '';
            }
            else {
                newState.inputValue = nextProps.searchTerm;
                Store.setOrigSearchTerm(nextProps.searchTerm);
            }
            if (!isEmpty(newState)) {
                this.setState(newState);
            }
        }
    };
    SearchBar.prototype.componentWillUnmount = function () {
        document.removeEventListener('click', this.handleAutocompleteDismiss, false);
    };
    SearchBar.prototype.render = function () {
        var _this = this;
        var _a = this.state, acActive = _a.acActive, acData = _a.acData, selectedIndex = _a.selectedIndex, nursery = _a.nursery, pillWidth = _a.pillWidth, inputValue = _a.inputValue, activeZone = _a.activeZone, zonesData = _a.zonesData;
        var zoneSetHandler = this.props.zoneSetHandler;
        var autoCompleteEl;
        var inputClass = 'search__input';
        var wrapperClass = 'search__input-wrap';
        var pill;
        var indent;
        if (acActive) {
            autoCompleteEl = (React.createElement(AutoComplete, { acData: acData, selectedIndex: selectedIndex, handleSubmit: this.handleSubmit, handleAutocompleteItemSelect: this.handleAutocompleteItemSelect }));
            wrapperClass += ' ac__active';
        }
        if (nursery) {
            pill = (React.createElement(Pill, { clearNurserySearch: this.clearNurserySearch, nursery: nursery, passWidth: this.passWidth }));
            inputClass += ' pill_active';
            indent = {
                marginLeft: pillWidth,
            };
        }
        return (React.createElement("div", { className: "search__container" },
            React.createElement("form", { onSubmit: function (e) {
                    _this.handleSubmit(e, inputValue, 'raw');
                }, className: "search__form" },
                React.createElement("div", { className: "search__count" },
                    React.createElement("a", { href: "/", className: "home_link" },
                        React.createElement("img", { className: "logo", src: "https://d17vsf20mehj1i.cloudfront.net/static/img/pl_logo_dark.svg", alt: "" }))),
                React.createElement("div", { className: "search__center" },
                    React.createElement("div", { className: wrapperClass },
                        React.createElement("input", { autoComplete: "off", spellCheck: "false", autoCapitalize: "none", autoCorrect: "off", style: indent, id: "search_input", tabIndex: 0, onBlur: this.collapse, className: inputClass, type: "text", placeholder: "Search for Plants...", value: inputValue, onChange: this.handleInputChange, onKeyDown: this.handleInputKeyDown, ref: this.textInput }),
                        React.createElement("span", { className: "search__clear", onClick: this.clearSearch, role: "button", tabIndex: -1, onKeyDown: this.clearSearch },
                            React.createElement(IcnSearchClear, { className: "icn" })),
                        React.createElement("button", { className: "search__submit", type: "submit", value: "Submit", ref: this.submitButton },
                            React.createElement(IcnSearch, { className: "icn" })),
                        pill),
                    autoCompleteEl),
                React.createElement(ZoneFilter, { activeZone: activeZone, zones: zonesData, zoneSetHandler: zoneSetHandler }))));
    };
    SearchBar = __decorate([
        observer
    ], SearchBar);
    return SearchBar;
}(React.Component));
export default SearchBar;
