var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function regular(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 75 65" }),
        React.createElement("path", { d: "M3.5,31.1 L1.5,31.1 C0.7,31.1 0,31.8 0,32.6 C0,33.4 0.7,34.1 1.5,34.1 L3.5,34.1 C4.3,34.1 5,33.4 5,32.6 C5,31.8 4.3,31.1 3.5,31.1 Z" }),
        React.createElement("path", { d: "M42.9,34.1 L46.5,34.1 C47.3,34.1 48,33.4 48,32.6 C48,31.8 47.3,31.1 46.5,31.1 L42.9,31.1 C42.1,31.1 41.4,31.8 41.4,32.6 C41.4,33.4 42,34.1 42.9,34.1 Z" }),
        React.createElement("path", { d: "M28.5,34.1 L32.1,34.1 C32.9,34.1 33.6,33.4 33.6,32.6 C33.6,31.8 32.9,31.1 32.1,31.1 L28.5,31.1 C27.7,31.1 27,31.8 27,32.6 C27,33.4 27.7,34.1 28.5,34.1 Z" }),
        React.createElement("path", { d: "M60.8,31.1 L57.2,31.1 C56.9,31.1 56.6,31.2 56.4,31.4 L39,1.5 L38.8,1.3 L38.7,1.2 L38.6,1.1 L38.4,1 L38.3,0.9 L38.1,0.8 L38,0.8 L37.9,0.8 L37.7,0.8 L37.4,0.8 L37.3,0.8 L37,0.8 L36.9,0.8 L36.7,0.9 L36.6,1 L36.4,1.1 L36.3,1.2 L36.2,1.3 L36.1,1.4 L36,1.5 L18.6,31.5 C18.3,31.3 18,31.2 17.7,31.2 L14.1,31.2 C13.3,31.2 12.6,31.9 12.6,32.7 C12.6,33.5 13.3,34.2 14.1,34.2 L17.1,34.2 C12.3,45.4 17.5,58.3 28.7,63.1 C39.9,67.9 52.8,62.7 57.6,51.5 C60,46 60,39.7 57.6,34.2 L60.6,34.2 C61.4,34.2 62.1,33.5 62.1,32.7 C62.1,31.9 61.5,31.1 60.8,31.1 L60.8,31.1 Z M37.5,5.5 L53.7,33.4 C54.4,34.7 55,36.1 55.4,37.5 L19.6,37.5 C20,36.1 20.6,34.7 21.3,33.4 L37.5,5.5 Z" }),
        React.createElement("path", { d: "M73.5,31.1 L71.5,31.1 C70.7,31.1 70,31.8 70,32.6 C70,33.4 70.7,34.1 71.5,34.1 L73.5,34.1 C74.3,34.1 75,33.4 75,32.6 C75,31.8 74.3,31.1 73.5,31.1 Z" })));
}
