var footerSX = {
    footerWrapper: function (theme) {
        var _a;
        return (_a = {
                width: '100%',
                background: theme.palette.greyColors.main,
                borderTop: "1px solid ".concat(theme.palette.secondary.contrastText),
                padding: theme.spacing(4.5, 0)
            },
            _a[theme.breakpoints.down('sm')] = {
                padding: theme.spacing(5.875, 0),
            },
            _a);
    },
    footerContainer: function (theme) {
        var _a;
        return (_a = {
                maxWidth: 1104,
                padding: theme.spacing(0),
                margin: 'auto'
            },
            _a[theme.breakpoints.down('sm')] = {
                maxWidth: '100%',
            },
            _a);
    },
    newsletterSignup: function (theme) { return ({
        padding: theme.spacing(0, 2),
    }); },
    mailingListTypography: function (theme) { return ({
        textTransform: 'uppercase',
        color: theme.palette.greyColors.dark,
        fontSize: theme.spacing(1.75),
        fontWeight: 400,
        letterSpacing: '3.11px',
        lineHeight: '18px',
        marginBottom: theme.spacing(1.5),
    }); },
    mailingListText: function (theme) {
        var _a;
        return (_a = {
                fontSize: theme.spacing(2.25),
                fontWeight: 300,
                display: 'block',
                letterSpacing: '0.9px',
                lineHeight: '32px',
                marginBottom: theme.spacing(1.5)
            },
            _a[theme.breakpoints.down('sm')] = {
                fontSize: theme.spacing(1.75),
            },
            _a);
    },
    signupForm: function () { return ({
        WebkitBoxPack: 'center',
        msFlexPack: 'center',
        justifyContent: 'center',
        display: 'flex',
    }); },
    emailInputContainer: function (theme) { return ({
        marginRight: theme.spacing(1),
    }); },
    emailInputRoot: function (theme) {
        var _a;
        return (_a = {
                minWidth: 360,
                '& .Mui-error': {
                    color: "".concat(theme.palette.redColors.main, " !important"),
                    border: "2px solid ".concat(theme.palette.redColors.main),
                }
            },
            _a[theme.breakpoints.down('sm')] = {
                minWidth: 223,
            },
            _a);
    },
    emailInput: function (theme) { return ({
        backgroundColor: "".concat(theme.palette.background.default, " !important"),
        color: "".concat(theme.palette.blueColors.main, " !important"),
        border: "2px solid ".concat(theme.palette.greyColors.light),
        borderRadius: theme.spacing(0.5),
        fontSize: theme.spacing(1.75),
        fontWeight: 400,
        fontFamily: 'system-ui, sans-serif',
        letterSpacing: '0.06em',
        lineHeight: 1,
        padding: theme.spacing(1.25),
        height: theme.spacing(2.75),
        WebkitBoxShadow: "0 0 0 100px ".concat(theme.palette.background.default, " inset !important"),
        WebkitTextFillColor: "".concat(theme.palette.blueColors.main, " !important"),
        '& .Mui-error': {
            color: "".concat(theme.palette.redColors.main, " !important"),
            border: "2px solid ".concat(theme.palette.redColors.main),
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '&::-webkit-input-placeholder': {
            color: "".concat(theme.palette.blueColors.main, " !important"),
            opacity: 1,
        },
    }); },
    emailInputInvalid: function (theme) { return ({
        color: "".concat(theme.palette.redColors.main, " !important"),
        border: "2px solid ".concat(theme.palette.redColors.main),
    }); },
    emailLabel: function (theme) { return ({
        display: 'block',
        fontSize: theme.spacing(2),
        marginTop: theme.spacing(1.25),
        marginRight: 0,
        marginLeft: 0,
        lineHeight: 1,
        marginBottom: theme.spacing(1.25),
        color: theme.palette.redColors.main,
    }); },
    subscribeButton: function (theme) {
        var _a;
        return (_a = {
                background: theme.palette.primary.light,
                color: theme.palette.background.default,
                fontSize: theme.spacing(2),
                height: theme.spacing(5.625),
                position: 'relative',
                width: 144,
                textTransform: 'capitalize',
                cursor: 'pointer',
                transition: 'background-color .2s',
                '&:disabled': {
                    color: "".concat(theme.palette.background.default, " !important"),
                },
                '&:hover': {
                    backgroundColor: theme.palette.primary.contrastText,
                },
                '&:active': {
                    backgroundColor: theme.palette.greyColors.contrastText,
                }
            },
            _a[theme.breakpoints.down('sm')] = {
                left: 0,
            },
            _a);
    },
    subscribeButtonDoneIcon: function (theme) { return ({
        marginRight: theme.spacing(0.5),
    }); },
    socialLinksContainer: function (theme) {
        var _a;
        return (_a = {
                padding: theme.spacing(4, 2),
                '& > div': {
                    padding: theme.spacing(0, 2.5),
                    '& > a > svg': {
                        fill: theme.palette.primary.main,
                    },
                }
            },
            _a[theme.breakpoints.down('sm')] = {
                padding: theme.spacing(3.5, 0),
                '& > div': {
                    flexBasis: '14%',
                    maxWidth: '14%',
                    padding: theme.spacing(0, 1.5),
                    '& > a > svg': {
                        height: theme.spacing(4),
                        width: theme.spacing(4),
                    },
                },
            },
            _a);
    },
    footerNavigation: function (theme) {
        var _a;
        return (_a = {
                padding: theme.spacing(0, 2),
                marginBottom: 10,
                display: 'grid',
                gridTemplateColumns: 'repeat(5,1fr)',
                gridColumnGap: '15px',
                '& > ul > li': {
                    letterSpacing: '0.06em',
                    marginBottom: '23px',
                    textAlign: 'left',
                }
            },
            _a[theme.breakpoints.down('sm')] = {
                padding: theme.spacing(0),
                gridTemplateColumns: 'repeat(1,1fr)',
                '& > ul': {
                    borderBottom: "1px solid ".concat(theme.palette.secondary.dark),
                    marginBottom: theme.spacing(0),
                    position: 'relative',
                    width: '100%',
                    '&.active': {
                        '& > li': {
                            display: 'block',
                            '& #panel-text': {
                                '&:after': {
                                    transform: 'rotate(45deg) !important',
                                },
                            },
                        },
                    },
                    '& > li': {
                        display: 'none',
                        marginBottom: theme.spacing(2.5),
                        cursor: 'pointer',
                        '&:first-child': {
                            display: 'block',
                            padding: theme.spacing(2.875, 0),
                            marginBottom: theme.spacing(0),
                        },
                    },
                },
            },
            _a);
    },
    footerNavigationTypography: function (theme) {
        var _a;
        return (_a = {
                fontSize: theme.spacing(1.75),
                fontWeight: 400,
                letterSpacing: '3.11px',
                lineHeight: '18px',
                color: theme.palette.greyColors.dark,
                textTransform: 'uppercase'
            },
            _a[theme.breakpoints.down('sm')] = {
                padding: theme.spacing(0, 2),
                '&:after': {
                    borderBottom: "4px solid ".concat(theme.palette.secondary.dark),
                    borderRight: "4px solid ".concat(theme.palette.secondary.dark),
                    content: '""',
                    height: theme.spacing(1.25),
                    position: 'absolute',
                    right: theme.spacing(2),
                    transform: 'rotate(-45deg)',
                    width: theme.spacing(1.25),
                },
            },
            _a);
    },
    footerNavigationLink: function (theme) {
        var _a;
        return (_a = {
                color: "".concat(theme.palette.blueColors.main, " !important"),
                fontSize: theme.spacing(2),
                fontWeight: 700
            },
            _a[theme.breakpoints.down('sm')] = {
                padding: theme.spacing(0, 2),
            },
            _a);
    },
    copyRightText: function (theme) { return ({
        fontSize: theme.spacing(1.75),
        textAlign: 'center',
    }); },
};
export default footerSX;
