/* eslint-disable no-underscore-dangle */
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/browser';
import SentryConfig from './sentryconfig';
// import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './themes/defaultTheme';
import Loader from './components/Loader';
import MainLayout from './Routes/MainLayout';
var environment = process.env.NODE_ENV === 'production'
    ? 'Frontend-ReactJS-Production'
    : 'Frontend-ReactJS-Development';
Sentry.init(SentryConfig(environment));
var Application = function () { return (React.createElement(StyledEngineProvider, { injectFirst: true },
    React.createElement(ThemeProvider, { theme: theme },
        React.createElement(BrowserRouter, null,
            React.createElement(Suspense, { fallback: React.createElement(Loader, null) },
                React.createElement(MainLayout, null)))))); };
// TODO: configure Redux store with Sagas
var root = document.getElementById('app');
// if (root.hasChildNodes() === true) {
//   // If it's an SSR, we use hydrate to get fast page loads by just
//   // attaching event listeners after the initial render
// Loadable.preloadReady().then(() => {
//   hydrate(<Application />, root);
// });
// } else {
// If we're not running on the server, just render like normal
render(React.createElement(Application, null), root);
// }
// render(
//   <BrowserRouter>
//     <MainLayout />
//   </BrowserRouter>,
//   document.getElementById('app'),
// );
