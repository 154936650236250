var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import attr1 from './attr_1';
import attr2 from './attr_2';
import attr3 from './attr_3';
import attr4 from './attr_4';
import attr5 from './attr_5';
import attr6 from './attr_6';
import attr7 from './attr_7';
import attr8 from './attr_8';
import attr9 from './attr_9';
import attr14 from './attr_14';
import attr15 from './attr_15';
import attr16 from './attr_16';
import bright_shade from './bright_shade';
import dappled_shade from './dappled_shade';
import drought_tolerant from './drought_tolerant';
import dry_in_summer from './dry_in_summer';
import dry_in_winter from './dry_in_winter';
import dry_when_dormant from './dry_when_dormant';
import even_moisture from './even_moisture';
import high from './high';
import low from './low';
import mostly_shade from './mostly_shade';
import mostly_sun from './mostly_sun';
import occasional from './occasional';
import plant_size from './plant_size';
import regular from './regular';
import shade from './shade';
import standing_water from './standing_water';
import sun from './sun';
import zones from './zones';
var attributes = {
    attr1: attr1,
    attr2: attr2,
    attr3: attr3,
    attr4: attr4,
    attr5: attr5,
    attr6: attr6,
    attr7: attr7,
    attr8: attr8,
    attr9: attr9,
    attr14: attr14,
    attr15: attr15,
    attr16: attr16,
    bright_shade: bright_shade,
    dappled_shade: dappled_shade,
    drought_tolerant: drought_tolerant,
    dry_in_summer: dry_in_summer,
    dry_in_winter: dry_in_winter,
    dry_when_dormant: dry_when_dormant,
    even_moisture: even_moisture,
    high: high,
    low: low,
    mostly_shade: mostly_shade,
    mostly_sun: mostly_sun,
    occasional: occasional,
    plant_size: plant_size,
    regular: regular,
    shade: shade,
    standing_water: standing_water,
    sun: sun,
    zones: zones,
};
export var AttributeIcon = function (_a) {
    var name = _a.name, props = __rest(_a, ["name"]);
    var IconComponent = attributes[name];
    return IconComponent ? React.createElement(IconComponent, __assign({}, props)) : null;
};
export { bright_shade as BrightShade };
export { dappled_shade as DappledShade };
export { drought_tolerant as DroughtTolerant };
export { dry_in_summer as DryInSummer };
export { dry_in_winter as DryInWinter };
export { dry_when_dormant as DryWhenDormant };
export { even_moisture as EvenMoisture };
export { high as High };
export { low as Low };
export { mostly_shade as MostlyShade };
export { mostly_sun as MostlySun };
export { occasional as Occasional };
export { plant_size as PlantSize };
export { regular as Regular };
export { shade as Shade };
export { standing_water as StandingWater };
export { sun as Sun };
export { zones as Zones };
export { default as IcnArrowR } from './icn_arrow_r';
export { default as IcnClearFilter } from './icn_clear_filter';
export { default as IcnGearFilter } from './icn_gear_filter';
export { default as IcnSearch } from './icn_search';
export { default as IcnSearchClear } from './icn_search_clear';
