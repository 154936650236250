import React from 'react';
import startsWith from 'lodash/startsWith';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import PromoHeader from '../Promotion/PromoHeader';
import NavigationList from '../Navigation';
import MobileNavigation from '../Navigation/MobileNavigation';
var HeaderSX = {
    toolBarRegular: function (theme) {
        var _a;
        return (_a = {},
            _a[theme.breakpoints.down('mdMedMin')] = {
                padding: theme.spacing(0, 1.5),
            },
            _a[theme.breakpoints.between('mdMedMin', 'mdMedMax')] = {
                padding: theme.spacing(0, 3.75),
            },
            _a);
    },
    toolBarRegularCart: function (theme) {
        var _a;
        return (_a = {},
            _a[theme.breakpoints.down('mdMedMin')] = {
                padding: theme.spacing(0, 2),
                minHeight: theme.spacing(10),
            },
            _a);
    },
    linksContainer: function (theme) {
        var _a;
        return (_a = {},
            _a[theme.breakpoints.down('mdMedMin')] = {
                display: 'none',
            },
            _a);
    },
};
export default function MainHeader(props) {
    var _a = React.useState(false), mobileOpen = _a[0], setMobileOpen = _a[1];
    var _b = React.useState(true), notCartPage = _b[0], setNotCartPage = _b[1];
    var navigationLinks = React.useState([
        {
            link: '/',
            text: 'Shop',
        },
        {
            link: 'https://plantlust.com/blog/ordering/',
            text: 'Order',
        },
        {
            link: 'https://plantlust.com/blog/sell-on-plantlust-com/',
            text: 'Sell',
        },
        {
            link: 'https://plantlust.com/blog/',
            text: 'blog',
        },
    ])[0];
    var cartCount = props.cartCount, activePromo = props.activePromo, enablePromoHeader = props.enablePromoHeader, scrolled = props.scrolled, showNurseryHeader = props.showNurseryHeader, navigation = props.navigation;
    function toggleMenu() {
        var changeWrapperClass = props.changeWrapperClass;
        var newMobileOpen = !mobileOpen;
        setMobileOpen(newMobileOpen);
        document.body.style.overflow = typeof window !== undefined && window.innerWidth < 992 && newMobileOpen ? 'hidden' : 'auto';
        changeWrapperClass();
    }
    function handleCartClick(e) {
        var openCartOnClick = props.openCartOnClick;
        e.preventDefault();
        openCartOnClick();
    }
    React.useEffect(function () {
        setNotCartPage(!startsWith(window.location.pathname, '/cart'));
    }, []);
    return (React.createElement(Box, { sx: {
            flexGrow: 1,
        } },
        notCartPage && (React.createElement(PromoHeader, { activePromo: activePromo, enablePromoHeader: enablePromoHeader, scrolled: scrolled })),
        React.createElement(AppBar, { position: "static", elevation: 0, sx: {
                borderTop: 'none',
                justifyContent: 'center',
                backgroundColor: 'background.default',
                zIndex: 999,
                flexDirection: 'row',
            } },
            React.createElement(Toolbar, { disableGutters: true, sx: [{
                        width: '100%',
                        maxWidth: 1104,
                        minHeight: '48px !important',
                        justifyContent: 'space-between',
                    }, HeaderSX.toolBarRegular, !notCartPage && HeaderSX.toolBarRegularCart] },
                notCartPage && (React.createElement(IconButton, { onClick: toggleMenu, sx: {
                        mr: 2,
                        display: { xs: 'inline-flex', mdMedMin: 'none' },
                        '& > svg': {
                            width: '1.5em',
                            height: '1.5em',
                            fontSize: '1.25rem',
                        },
                    }, disableRipple: true, disableFocusRipple: true, disableTouchRipple: true, "aria-label": "Plant Lust Menu" }, mobileOpen ? React.createElement(CloseIcon, null)
                    : React.createElement(MenuIcon, null))),
                React.createElement(IconButton, { href: "/", sx: {
                        padding: 0,
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }, disableRipple: true, disableFocusRipple: true, disableTouchRipple: true, "aria-label": "Plant Lust Logo" },
                    React.createElement(Avatar, { alt: "Plant Lust", sx: {
                            width: 122,
                            height: notCartPage ? 56 : 80,
                            '& > img': {
                                objectFit: 'contain',
                            },
                        }, variant: "square", src: "".concat(process.env.STATIC_PATH, "/img/pl_logo_dark.svg") })),
                notCartPage && (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { sx: HeaderSX.linksContainer, container: true, alignItems: "center", justifyContent: "flex-end" }, navigationLinks.map(function (navItem) { return (React.createElement(Link, { key: navItem.link, href: navItem.link, sx: {
                            color: function (theme) { return "".concat(theme.palette.primary.light, " !important"); },
                            fontSize: 12,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            letterSpacing: 3,
                            py: 0,
                            px: 3.125,
                        }, underline: "none" }, navItem.text)); })),
                    React.createElement(Box, { component: "span", sx: {
                            color: 'primary.light',
                            textIndent: 8,
                            fontSize: 11,
                            fontWeight: 700,
                            width: 44,
                            height: 55,
                            cursor: 'pointer',
                            lineHeight: 3.5,
                            background: "url(".concat(process.env.STATIC_PATH, "/img/pl_mobile_cart_icon.svg) center center no-repeat"),
                            backgroundSize: '33px 30px',
                        }, onClick: handleCartClick, onKeyDown: handleCartClick, role: "button", tabIndex: -1 }, cartCount))))),
        React.createElement(Divider, { sx: { width: '100%' } }),
        !showNurseryHeader && notCartPage && (React.createElement(NavigationList, { navigation: navigation })),
        notCartPage && (React.createElement(MobileNavigation, { navigation: navigation, mobileOpen: mobileOpen, navigationLinks: navigationLinks, setMobileOpen: setMobileOpen }))));
}
MainHeader.defaultProps = {
    openCartOnClick: function () { },
    enablePromoHeader: function (promoHeight) { },
};
