var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import loadable from '@loadable/component';
import startsWith from 'lodash/startsWith';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import replace from 'lodash/replace';
import find from 'lodash/find';
import { Route, Switch, withRouter, } from 'react-router-dom';
import createStyles from '@material-ui/styles/createStyles';
import withStyles from '@material-ui/styles/withStyles';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import * as Sentry from '@sentry/browser';
import SearchBar from '../components/Search/SearchBar';
import Loader from '../components/Loader';
import IndexPage from '../components/IndexPage/IndexPage';
import { breakpoints, cleanSearch, isPDPPage, resetParam, } from '../utils/helpers';
import api from '../services/api';
import Store from '../stores/store';
import shopifyAPI from '../services/shopifyAPI';
import { fetchCart } from '../utils/graphQLMutations';
import CustomButton from '../components/CommonComponents/CustomButton';
import ShopifyCartIcon from '../themes/icons/ShopifyCartIcon';
import NurseryHeader from '../components/Nursery/NurseryHeader';
import NavigationList from '../components/Navigation';
import ShopifyCart from '../components/Shopify/ShopifyCart';
import MainHeader from '../components/Header';
import Footer from '../components/Footer';
var SearchLayout = loadable(function () { return import(/* webpackChunkName: "search_layout" */ './SearchLayout'); }, { fallback: React.createElement(Loader, null) });
var FeaturedLayout = loadable(function () { return import(/* webpackChunkName: "featured_layout" */ './FeaturedLayout'); }, { fallback: React.createElement(Loader, null) });
var DetailLayout = loadable(function () { return import(/* webpackChunkName: "detail_layout" */ './DetailLayout'); }, { fallback: React.createElement(Loader, null) });
var NurseryLayout = loadable(function () { return import(/* webpackChunkName: "nursery_layout" */ './NurseryLayout'); }, { fallback: React.createElement(Loader, null) });
var Checkout = loadable(function () { return import(/* webpackChunkName: "checkout_layout" */ './Checkout'); }, { fallback: React.createElement(Loader, null) });
var GenusLayout = loadable(function () { return import(/* webpackChunkName: "genus_layout" */ './GenusLayout'); }, { fallback: React.createElement(Loader, null) });
var TagsLayout = loadable(function () { return import(/* webpackChunkName: "tags_layout" */ './TagsLayout'); }, { fallback: React.createElement(Loader, null) });
var AttributeLayout = loadable(function () { return import(/* webpackChunkName: "attribute_layout" */ './AttributeLayout'); }, { fallback: React.createElement(Loader, null) });
var NavigationLayout = loadable(function () { return import(/* webpackChunkName: "navigation_layout" */ './NavigationLayout'); }, { fallback: React.createElement(Loader, null) });
var CategoryLayout = loadable(function () { return import(/* webpackChunkName: "category_layout" */ './CategoryLayout'); }, { fallback: React.createElement(Loader, null) });
var styles = function (_a) {
    var palette = _a.palette, spacing = _a.spacing;
    return createStyles({
        searchBarContainer: {
            margin: 'auto',
            padding: spacing(6.875, 3.75, 0),
        },
        searchBar: {},
        toggleButton: {
            position: 'fixed',
            right: 0,
            top: '50%',
            marginTop: spacing(-3),
            minWidth: 0,
            width: spacing(6.25),
            fontSize: spacing(2),
            fontWeight: 700,
            backgroundColor: palette.primary.light,
            color: palette.background.default,
            display: 'block',
            padding: spacing(1.25, 0),
            borderRadius: spacing(0.5, 0, 0, 0.5),
            lineHeight: '22px',
            '&:hover': {
                backgroundColor: palette.primary.contrastText,
            },
        },
        toggleButtonIcon: {
            margin: 0,
            marginTop: spacing(1.25),
        },
        '@media (max-width: 768px)': {
            toggleButton: {
                display: 'none',
            },
        },
        '@media (max-width: 939px)': {
            searchBarContainer: {
                padding: spacing(0, 1.875),
            },
        },
        '@media (min-width: 768px)': {
            searchBarContainer: {
                padding: spacing(0, 2),
            },
            searchBar: {
                borderBottom: "1px solid ".concat(palette.secondary.contrastText),
                maxWidth: 1104,
                margin: '0 auto',
            },
        },
        '@media (min-width: 1104px)': {
            searchBarContainer: {
                padding: spacing(0),
            },
        },
    });
};
var MainLayout = /** @class */ (function (_super) {
    __extends(MainLayout, _super);
    function MainLayout(props) {
        var _this = _super.call(this, props) || this;
        _this.nurseryHeaderRef = React.createRef();
        _this.setCommonData = function (commonData) {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            var zones = commonData.zones, promo_link = commonData.promo_link;
            _this.setState({
                zones: zones,
                activePromo: promo_link,
            });
        };
        _this.setNavigationData = function (navigationData) {
            var navigation = navigationData.navigation;
            _this.setState({
                navigationData: navigation,
            });
        };
        _this.setDetailsNavigationData = function (navigationData) {
            if (!navigationData) {
                api.home.getNavigationData().then(function (mainNavData) { return _this.setNavigationData(mainNavData); });
            }
            else {
                _this.setState({
                    navigationData: navigationData,
                });
            }
        };
        _this.setCartData = function (cart) { return __awaiter(_this, void 0, void 0, function () {
            var qty, newCheckCart;
            return __generator(this, function (_a) {
                qty = 0;
                cart.cart.nurseries.forEach(function (nursery) {
                    nursery.items.forEach(function (item) {
                        qty += item.quantity;
                    });
                });
                newCheckCart = cart.cart;
                newCheckCart.nurseries = cart.cart.nurseries
                    .map(function (nursery) {
                    var newNursery = nursery;
                    newNursery.checked = true;
                    newNursery.shippingLoading = true;
                    return newNursery;
                });
                this.setState({
                    checkout: newCheckCart,
                    cartCount: qty,
                });
                return [2 /*return*/];
            });
        }); };
        _this.addToShopifyCart = function (variant, increase_quantity, djangoPk) { return __awaiter(_this, void 0, void 0, function () {
            var lineItems;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        lineItems = {
                            plant_id: djangoPk,
                            increase_quantity: increase_quantity,
                            variant_id: variant.id,
                        };
                        return [4 /*yield*/, api.checkout.addItems(lineItems).then(function (cart) {
                                var qty = 0;
                                cart.cart.nurseries.forEach(function (nursery) {
                                    nursery.items.forEach(function (item) {
                                        qty += item.quantity;
                                    });
                                });
                                var newCheckCart = cart.cart;
                                newCheckCart.nurseries = cart.cart.nurseries.map(function (nursery) {
                                    var newNursery = nursery;
                                    newNursery.checked = true;
                                    return newNursery;
                                });
                                _this.setState({
                                    cartOpen: true,
                                    checkout: newCheckCart,
                                    cartCount: qty,
                                });
                            })
                                .catch(function (error) {
                                if (error.TypeError !== 'Failed to fetch')
                                    Sentry.captureException(error);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.changeQuantity = function (plant, modifyNursery, includeShipping, zipCode, province) { return __awaiter(_this, void 0, void 0, function () {
            var newPlant, checkout, newState;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newPlant = {
                            set_quantity: plant.quantity,
                            plant_id: plant.plant_id,
                            variant_id: plant.variant_id,
                        };
                        checkout = this.state.checkout;
                        newState = this.state;
                        newState.checkout.nurseries = checkout.nurseries.map(function (nursery) {
                            var item = nursery;
                            if (item.name === modifyNursery.name) {
                                item.shippingLoading = true;
                            }
                            return item;
                        });
                        this.setState(newState);
                        return [4 /*yield*/, api.checkout.addItems(newPlant).then(function (cart) { return __awaiter(_this, void 0, void 0, function () {
                                var qty, newCheckCart;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            qty = 0;
                                            cart.cart.nurseries.forEach(function (nursery) {
                                                nursery.items.forEach(function (item) {
                                                    qty += item.quantity;
                                                });
                                            });
                                            if (!!includeShipping) return [3 /*break*/, 1];
                                            newCheckCart = cart.cart;
                                            newCheckCart.nurseries = cart.cart.nurseries
                                                .map(function (nursery) {
                                                var newNursery = nursery;
                                                newNursery.checked = true;
                                                newNursery.shippingLoading = false;
                                                return newNursery;
                                            });
                                            this.setState({
                                                checkout: newCheckCart,
                                                cartCount: qty,
                                            });
                                            return [3 /*break*/, 3];
                                        case 1: return [4 /*yield*/, api.checkout.getCartList("?include_shipping=true&zip=".concat(zipCode, "&province=").concat(province)).then(function (cartList) {
                                                var shippedCheckCart = cartList.cart;
                                                shippedCheckCart.nurseries = cartList.cart.nurseries
                                                    .map(function (nursery) {
                                                    var newNursery = nursery;
                                                    newNursery.checked = true;
                                                    newNursery.shippingLoading = false;
                                                    return newNursery;
                                                });
                                                _this.setState({
                                                    checkout: shippedCheckCart,
                                                    cartCount: qty,
                                                });
                                            })
                                                .catch(function (error) {
                                                if (error.TypeError !== 'Failed to fetch')
                                                    Sentry.captureException(error);
                                            })];
                                        case 2:
                                            _a.sent();
                                            _a.label = 3;
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            }); })
                                .catch(function (error) {
                                if (error.TypeError !== 'Failed to fetch')
                                    Sentry.captureException(error);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.openCart = function () {
            _this.setState({
                cartOpen: true,
            });
        };
        _this.closeCart = function () {
            _this.setState({
                cartOpen: false,
            });
        };
        _this.scrollToSearch = function () {
            var _a, _b;
            var nurseryHeaderVisible = false;
            if (typeof window !== 'undefined') {
                if ((_a = _this.nurseryHeaderRef) === null || _a === void 0 ? void 0 : _a.current) {
                    if (window.getComputedStyle((_b = _this.nurseryHeaderRef) === null || _b === void 0 ? void 0 : _b.current).display === 'block') {
                        nurseryHeaderVisible = true;
                    }
                }
            }
            if (nurseryHeaderVisible) {
                if (window.innerWidth < breakpoints.screen_xs) {
                    window.scrollTo(0, 300);
                }
                if (window.innerWidth >= breakpoints.screen_xs
                    && window.innerWidth < breakpoints.screen_md) {
                    window.scrollTo(0, 450);
                }
                if (window.innerWidth >= breakpoints.screen_md
                    && window.innerWidth < breakpoints.screen_lg) {
                    window.scrollTo(0, 560);
                }
                if (window.innerWidth >= breakpoints.screen_lg) {
                    window.scrollTo(0, 700);
                }
            }
        };
        _this.setQueries = function (query, rawSearch, queryType, slug, url) {
            if (rawSearch === void 0) { rawSearch = ''; }
            if (queryType === void 0) { queryType = ''; }
            if (slug === void 0) { slug = ''; }
            if (url === void 0) { url = ''; }
            localStorage.setItem('scrollPosition', '0');
            var history = _this.props.history;
            var newQuery = query;
            if (queryType === 'Nursery') {
                Store.setNursery(rawSearch);
                if (slug !== null) {
                    Store.setNurserySlug(slug);
                    Store.setIsNursery(true);
                }
            }
            gtag('event', 'search', query);
            Store.setSearchTerm(rawSearch);
            if (newQuery.indexOf('&zone=null') !== -1) {
                newQuery = newQuery.replace('&zone=null', '');
                Store.setActiveZone('0');
            }
            if (Store.activeZone !== '0' && newQuery.indexOf('zone') === -1) {
                newQuery = "".concat(newQuery, "&zone=").concat(Store.activeZone);
            }
            var originType = 'search';
            if (window.location.pathname.indexOf('/plants/') !== -1) {
                originType = 'plant_detail';
            }
            if (window.location.pathname.indexOf('/nurseries/') !== -1) {
                originType = 'nursery';
            }
            if (window.location.pathname === '/') {
                originType = 'home';
            }
            if (Store.isNursery) {
                switch (originType) {
                    case 'plant_detail':
                        if (!Store.nurserySlug) {
                            Store.setNurserySlug(Store.nursery
                                .toLowerCase()
                                .split(' ')
                                .join('-'));
                        }
                        if (newQuery.indexOf('nurseries=') === -1) {
                            window.location.href = "/nurseries/".concat(Store.nurserySlug, "/?").concat(newQuery);
                        }
                        else
                            window.location.href = "/nurseries/".concat(Store.nurserySlug);
                        break;
                    case 'nursery':
                        if (!slug) {
                            history.push("".concat(window.location.pathname, "?").concat(newQuery));
                            _this.scrollToSearch();
                        }
                        else {
                            history.push("/nurseries/".concat(Store.nurserySlug));
                        }
                        gtag('event', 'page_view');
                        break;
                    case 'home':
                    case 'search':
                    default:
                        window.location.href = "/nurseries/".concat(Store.nurserySlug, "/");
                        break;
                }
            }
            else if (url !== '') {
                history.push(url, { getAPIData: true });
            }
            else if (window.location.href.indexOf('/search/') !== -1) {
                var newSearch = window.location.search.replace('?', '');
                if (newSearch === newQuery) {
                    return;
                }
                history.push("/search/?".concat(newQuery), { getAPIData: true });
                gtag('event', 'page_view');
            }
            else {
                window.location.href = "/search/?".concat(newQuery);
            }
            localStorage.removeItem('scrollPosition');
        };
        _this.zoneSetHandler = function (zone) {
            var _a = _this.props, location = _a.location, history = _a.history;
            var searchString = '';
            var search = cleanSearch(location.search);
            var path = location.pathname;
            if (zone === '0') {
                searchString = resetParam(search, 'zone', false);
            }
            else {
                searchString = resetParam(search, 'zone', zone);
            }
            if (Store.origSearchTerm !== ''
                && Store.spellingSuggestion.spelling_suggestion != null) {
                searchString = searchString.replace(Store.origSearchTerm, Store.spellingSuggestion.spelling_suggestion);
                Store.setOrigSearchTerm('');
            }
            if (window.location.href.indexOf('/search/') !== -1
                || window.location.href.indexOf('/featured/') !== -1
                || window.location.href.indexOf('nurseries') !== -1
                || window.location.href.indexOf('/plants/genus/') !== -1
                || window.location.href.indexOf('/plants/tags/') !== -1
                || !isPDPPage()) {
                if (path.length - 1 === path.lastIndexOf('/')) {
                    history.push("".concat(path, "?").concat(searchString));
                }
                else {
                    history.push("".concat(path, "/?").concat(searchString));
                }
                gtag('event', 'page_view');
            }
            else {
                window.location.href = "/search/?".concat(searchString);
            }
        };
        _this.paginationHandler = function () {
            var elementTop = document.getElementById('windowTop');
            if (elementTop)
                elementTop.scrollIntoView();
            gtag('event', 'page_view');
        };
        _this.setFilter = function (key, value, filters) {
            if (filters === void 0) { filters = []; }
            var _a = _this.props, location = _a.location, history = _a.history;
            var searchString = '';
            var re = new RegExp('([0-9]=[0-9])');
            var filterValue = "".concat(key, "=").concat(value);
            var search = cleanSearch(location.search);
            if (key && value) {
                if (search.indexOf('filtered=True') === -1) {
                    if (search.length > 0) {
                        searchString = "".concat(search, "&").concat(filterValue, "&filtered=True");
                    }
                    else {
                        searchString = "".concat(filterValue, "&filtered=True");
                    }
                }
                else {
                    var numFilters_1 = 0;
                    if (includes(search.split('&'), filterValue)) {
                        searchString = remove(search.split('&'), function (filter) { return filter !== filterValue; }).join('&');
                        searchString = replace(searchString, '&&', '&');
                        var params = searchString.split('&');
                        params.forEach(function (param) {
                            if (re.test(param)) {
                                numFilters_1 += 1;
                            }
                        });
                        if (numFilters_1 === 0) {
                            searchString = replace(searchString, /[&?]*filtered=True/g, '');
                        }
                    }
                    else if (search.length > 0) {
                        searchString = "".concat(search, "&").concat(filterValue);
                    }
                    else {
                        searchString = filterValue;
                    }
                }
            }
            else if (!key && !value) {
                var newString = replace(search, '&filtered=True', '');
                var params = newString.split('&');
                var searchArray = params.map(function (param) {
                    if (!re.test(param)) {
                        return param;
                    }
                    return null;
                });
                searchArray.forEach(function (param, i) {
                    if (param !== null) {
                        if (i === 0) {
                            searchString += param;
                        }
                        else {
                            searchString += "&".concat(param);
                        }
                    }
                });
            }
            if (searchString.substr(1, 1) === '&') {
                searchString = searchString.substr(1);
            }
            if (searchString.substr(-1) === '&') {
                searchString = searchString.slice(0, -1);
            }
            var path = location.pathname;
            if (filters && filters.length > 0) {
                if (find(filters, function (x) { return x.indexOf('Nursery') !== -1; })
                    && location.search.split('?')[1]) {
                    Store.setIsNursery(false);
                    Store.setNursery('');
                    Store.setNurserySlug('');
                    _this.showNurseryHeader(false);
                    location.pathname = '/search/';
                    path = '';
                }
                if (find(filters, function (x) { return x.indexOf('zone') !== -1; })) {
                    var i = searchString.indexOf('&zone') !== -1
                        ? searchString.indexOf('&zone')
                        : searchString.indexOf('zone');
                    searchString = searchString.slice(0, i);
                }
            }
            if (Store.origSearchTerm !== ''
                && Store.spellingSuggestion.spelling_suggestion != null) {
                searchString = searchString.replace(Store.origSearchTerm, Store.spellingSuggestion.spelling_suggestion);
                Store.setOrigSearchTerm('');
            }
            searchString = searchString.startsWith('&') ? searchString.slice(1) : searchString;
            if (path.length - 1 === path.lastIndexOf('/')) {
                history.push("".concat(path, "?").concat(searchString));
            }
            else {
                history.push("".concat(path, "/?").concat(searchString));
            }
            gtag('event', 'page_view');
        };
        _this.changeWrapperClass = function () {
            _this.setState(function (prevState) { return ({
                mobNavOpen: !prevState.mobNavOpen,
            }); });
        };
        _this.updateNurseryHeader = function (nurseryHeaderData) {
            var navigationData = _this.state.navigationData;
            var newNavigationData = nurseryHeaderData.navigation.length > 0
                ? nurseryHeaderData.navigation : navigationData;
            _this.setState({
                nurseryHeaderData: nurseryHeaderData,
                navigationData: newNavigationData,
            });
        };
        _this.showNurseryHeader = function (showNurseryHeader) {
            _this.setState({
                showNurseryHeader: showNurseryHeader,
            });
        };
        _this.enablePromoHeader = function (promoHeight) {
            _this.setState({ promoHeight: promoHeight });
        };
        _this.state = {
            zones: [
                {
                    description: '',
                    order: 0,
                    zone: '',
                    color: '',
                },
            ],
            activePromo: null,
            commonDataLoaded: false,
            cartOpen: false,
            cartCount: 0,
            scrolled: false,
            mobNavOpen: false,
            showNurseryHeader: false,
            nurseryHeaderData: {
                name: '',
                navigation: null,
                contributor_type: [],
                location: '',
                location_short: '',
                email: '',
                url: '',
                phone: '',
                avatar: '',
                description: '',
                shopify_handle: '',
            },
            checkout: {
                shipping_zip: null,
                total: null,
                nurseries: [],
            },
            promoHeight: 0,
            navigationData: null,
            heroes: [],
        };
        return _this;
    }
    MainLayout.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var element, shopifyCartId;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (window.location.pathname === '/' || !startsWith(window.location.pathname, '/nurseries')) {
                            api.home.getNavigationData().then(function (navigationData) { return _this.setNavigationData(navigationData); });
                            localStorage.removeItem('scrollPosition');
                        }
                        element = document.getElementById('heroes');
                        if (element) {
                            this.setState({
                                heroes: JSON.parse(element.textContent),
                            });
                        }
                        shopifyCartId = localStorage.getItem('shopifyCartId');
                        if (!shopifyCartId) return [3 /*break*/, 2];
                        return [4 /*yield*/, shopifyAPI.request(fetchCart, { variables: { shopifyCartId: shopifyCartId } }).then(function (_a) {
                                var data = _a.data;
                                return __awaiter(_this, void 0, void 0, function () {
                                    var _this = this;
                                    return __generator(this, function (_b) {
                                        console.log('shopify cart id check data', data);
                                        if (data.node === null) {
                                            console.log('shopify cart id check data - null');
                                            // No response with this cart id, means that the checkout process was completed
                                            // (or the cart expired after a few days). Empty our local cart session then load the common data
                                            api.checkout.clearCart().then(function () {
                                                localStorage.setItem('shopifyCartId', '');
                                                localStorage.setItem('accessToken', '');
                                                localStorage.setItem('expiresAt', '');
                                                Promise.all([api.home.getCommonData(), api.checkout.getCartList()])
                                                    .then(function (data) {
                                                    _this.setCommonData(data[0]);
                                                    _this.setCartData(data[1]);
                                                    _this.setState({
                                                        commonDataLoaded: true,
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (error.TypeError !== 'Failed to fetch')
                                                        Sentry.captureException(error);
                                                });
                                            });
                                        }
                                        else {
                                            console.log('shopify cart id check data - exists');
                                            Promise.all([api.home.getCommonData(), api.checkout.getCartList()])
                                                .then(function (data) {
                                                _this.setCommonData(data[0]);
                                                _this.setCartData(data[1]);
                                                _this.setState({
                                                    commonDataLoaded: true,
                                                });
                                            })
                                                .catch(function (error) {
                                                if (error.TypeError !== 'Failed to fetch')
                                                    Sentry.captureException(error);
                                            });
                                        }
                                        return [2 /*return*/];
                                    });
                                });
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        Promise.all([api.home.getCommonData(), api.checkout.getCartList()])
                            .then(function (data) {
                            _this.setCommonData(data[0]);
                            _this.setCartData(data[1]);
                            _this.setState({
                                commonDataLoaded: true,
                            });
                        })
                            .catch(function (error) {
                            if (error.TypeError !== 'Failed to fetch')
                                Sentry.captureException(error);
                        });
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MainLayout.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        var _this = this;
        if (!startsWith(nextProps.location.pathname, '/nurseries')) {
            api.home.getNavigationData()
                .then(function (newNavigationData) { return _this.setNavigationData(newNavigationData); });
        }
    };
    MainLayout.prototype.UNSAFE_componentWillUpdate = function (nextState) {
        var scrolled = this.state.scrolled;
        if (scrolled !== nextState.scrolled) {
            if (nextState.scrolled) {
                if (window.innerWidth >= breakpoints.screen_xs) {
                    if (!Store.isNursery) {
                        window.scrollTo(0, 60);
                    }
                }
            }
        }
    };
    MainLayout.prototype.render = function () {
        var _this = this;
        var _a, _b;
        var _c = this.state, scrolled = _c.scrolled, mobNavOpen = _c.mobNavOpen, showNurseryHeader = _c.showNurseryHeader, zones = _c.zones, activePromo = _c.activePromo, promoHeight = _c.promoHeight, nurseryHeaderData = _c.nurseryHeaderData, checkout = _c.checkout, cartOpen = _c.cartOpen, commonDataLoaded = _c.commonDataLoaded, navigationData = _c.navigationData, cartCount = _c.cartCount, heroes = _c.heroes;
        var _d = this.props, history = _d.history, classes = _d.classes;
        var nurseryHeaderVisible = false;
        if (typeof window !== 'undefined') {
            if ((_a = this.nurseryHeaderRef) === null || _a === void 0 ? void 0 : _a.current) {
                if (window.getComputedStyle((_b = this.nurseryHeaderRef) === null || _b === void 0 ? void 0 : _b.current).display === 'block') {
                    nurseryHeaderVisible = true;
                }
            }
        }
        if (commonDataLoaded) {
            var searchStyle = {
                marginTop: '0px',
            };
            if (!showNurseryHeader || !nurseryHeaderVisible) {
                searchStyle = {
                    marginTop: promoHeight ? "".concat(promoHeight + 58, "px") : '58px',
                };
            }
            return (React.createElement("div", { className: clsx('wrap', mobNavOpen && 'mobNavOpen') },
                React.createElement("div", { className: clsx('header__wrap fullwidth', showNurseryHeader && 'followMeBar') },
                    React.createElement(MainHeader, { activePromo: activePromo, openCartOnClick: this.openCart, navigation: navigationData, changeWrapperClass: this.changeWrapperClass, enablePromoHeader: this.enablePromoHeader, scrolled: scrolled, showNurseryHeader: showNurseryHeader, cartCount: cartCount })),
                showNurseryHeader && (React.createElement(React.Fragment, null,
                    React.createElement(NurseryHeader, { ref: this.nurseryHeaderRef, data: nurseryHeaderData, promoHeight: promoHeight || 0, scrolled: scrolled, mobNavOpen: mobNavOpen, heroes: heroes }),
                    React.createElement(NavigationList, { navigation: navigationData }))),
                React.createElement("div", { className: "content fullwidth", style: searchStyle },
                    !startsWith(window.location.pathname, '/cart') && (React.createElement("div", { className: "fullwidth_search followMeBar" },
                        React.createElement("div", { className: "search_bar" },
                            React.createElement("div", { className: classes.searchBarContainer },
                                React.createElement(SearchBar, { history: history, searchTerm: Store.searchTerm, setQueries: this.setQueries, zones: zones, zoneSetHandler: this.zoneSetHandler, activeZone: Store.activeZone, nursery: Store.nursery, showNurseryHeader: this.showNurseryHeader }))))),
                    React.createElement(Switch, null,
                        React.createElement(Route, { path: "/", exact: true, render: function () { return React.createElement(IndexPage, null); } }),
                        React.createElement(Route, { exact: true, path: "/category/:primaryNav/:secondaryNav?/", render: function (props) { return (React.createElement(NavigationLayout, __assign({}, props))); } }),
                        React.createElement(Route, { path: "/category/", render: function (props) { return (React.createElement(CategoryLayout, __assign({ navigation: navigationData }, props))); } }),
                        React.createElement(Route, { path: "/search", render: function (props) { return (React.createElement(SearchLayout, __assign({ paginationHandler: _this.paginationHandler, setFilter: _this.setFilter, addToShopifyCart: _this.addToShopifyCart }, props))); } }),
                        React.createElement(Route, { path: "/featured/:featuredId/:featureSlug/", render: function (props) { return (React.createElement(FeaturedLayout, __assign({ paginationHandler: _this.paginationHandler, setFilter: _this.setFilter, addToShopifyCart: _this.addToShopifyCart, setQueries: _this.setQueries }, props))); } }),
                        React.createElement(Route, { path: "/plants/genus/:genusName/", render: function (props) { return (React.createElement(GenusLayout, __assign({ paginationHandler: _this.paginationHandler, setFilter: _this.setFilter, addToShopifyCart: _this.addToShopifyCart, title: "".concat(props.match.params.genusName.charAt(0).toUpperCase() + props.match.params.genusName.slice(1), " from multiple nurseries on Plant Lust") }, props))); } }),
                        React.createElement(Route, { path: "/plants/tags/:tagName/", render: function (props) { return (React.createElement(TagsLayout, __assign({ paginationHandler: _this.paginationHandler, setFilter: _this.setFilter, addToShopifyCart: _this.addToShopifyCart, setQueries: _this.setQueries, title: "Plant Lust - Search in tag ".concat(props.match.params.tagName.charAt(0).toUpperCase() + props.match.params.tagName.slice(1)) }, props))); } }),
                        React.createElement(Route, { path: "/plants/:plantId(\\d+)/:plantName/", render: function (props) { return (React.createElement(DetailLayout, __assign({ addToShopifyCart: _this.addToShopifyCart, scrolled: scrolled, setNavigationData: _this.setDetailsNavigationData }, props))); } }),
                        React.createElement(Route, { path: "/plants/:attributeSlug/:valueSlug/", render: function (props) { return (React.createElement(AttributeLayout, __assign({ paginationHandler: _this.paginationHandler, setFilter: _this.setFilter, addToShopifyCart: _this.addToShopifyCart }, props))); } }),
                        React.createElement(Route, { path: "/nurseries/:nursery/", render: function (props) { return (React.createElement(NurseryLayout, __assign({ updateNurseryHeader: _this.updateNurseryHeader, showNurseryHeader: _this.showNurseryHeader, paginationHandler: _this.paginationHandler, setFilter: _this.setFilter, addToShopifyCart: _this.addToShopifyCart, setQueries: _this.setQueries }, props))); } }),
                        React.createElement(Route, { path: "/cart/", render: function (props) { return (React.createElement(Checkout, __assign({ checkout: checkout, count: cartCount, changeQuantity: _this.changeQuantity }, props))); } }))),
                React.createElement(Footer, null),
                React.createElement(ShopifyCart, { checkout: checkout, changeQuantity: this.changeQuantity, count: cartCount, openCart: cartOpen, closeCart: this.closeCart }),
                cartCount > 0 && window.location.href.indexOf('/cart/') === -1 && (React.createElement(CustomButton, { onClick: this.openCart, buttonText: cartCount, buttonClassObj: {
                        root: classes.toggleButton,
                        endIcon: classes.toggleButtonIcon,
                    }, endIcon: React.createElement(ShopifyCartIcon, null) }))));
        }
        return React.createElement(Loader, null);
    };
    MainLayout = __decorate([
        observer
    ], MainLayout);
    return MainLayout;
}(React.Component));
export default withStyles(styles)(withRouter(MainLayout));
