var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function shade(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "-8 -8 62 62" }),
        React.createElement("path", { d: "M41.2,11.3 C40.3,9.7 39.2,8.3 37.9,7 C37.1,6.1 36.1,5.3 35.2,4.6 C31.6,1.9 27.1,0.3 22.2,0.3 C17.3,0.3 12.9,1.9 9.2,4.6 C8.2,5.3 7.3,6.1 6.5,7 C5.2,8.3 4.2,9.7 3.2,11.3 C1.3,14.6 0.2,18.4 0.2,22.4 C0.2,26.4 1.3,30.3 3.2,33.5 C4.1,35.1 5.2,36.5 6.5,37.8 C7.3,38.7 8.3,39.5 9.2,40.2 C12.8,42.9 17.3,44.5 22.2,44.5 C27.1,44.5 31.5,42.9 35.2,40.2 C36.2,39.5 37.1,38.7 37.9,37.8 C39.2,36.5 40.2,35.1 41.2,33.5 C43.1,30.2 44.2,26.4 44.2,22.4 C44.2,18.4 43.1,14.6 41.2,11.3 Z M22.2,4.7 C25.4,4.7 28.4,5.6 31,7.1 L13.4,7.1 C16,5.5 19,4.7 22.2,4.7 Z M8.4,11.3 L36,11.3 C36.6,12 37.1,12.8 37.6,13.7 L6.8,13.7 C7.3,12.8 7.8,12 8.4,11.3 Z M22.2,40.2 C19,40.2 16,39.3 13.4,37.8 L31,37.8 C28.4,39.3 25.4,40.2 22.2,40.2 Z M36,33.5 L8.4,33.5 C7.8,32.8 7.3,32 6.8,31.1 L37.6,31.1 C37.1,32 36.6,32.8 36,33.5 Z M39.3,26.9 L5,26.9 C4.8,26.1 4.6,25.3 4.5,24.5 L39.7,24.5 C39.7,25.3 39.5,26.1 39.3,26.9 Z M4.6,20.3 C4.7,19.5 4.9,18.7 5.1,17.9 L39.4,17.9 C39.6,18.7 39.8,19.5 39.9,20.3 L4.6,20.3 Z", id: "s_shade", fill: "inherit", fillRule: "nonzero" })));
}
