var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function icn_arrow_r(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 18 14" }),
        React.createElement("polygon", { points: "0 8 16 8 16 6 0 6" }),
        React.createElement("polygon", { points: "10.707 13.707 9.293 12.293 14.586 7 9.293 1.707 10.707 0.293 17.414 7" })));
}
