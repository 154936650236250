var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function icn_search(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 40 40" }),
        React.createElement("path", { d: "M11.7,25.6l5-4.9c-0.7-0.9-1.1-2-1.1-3.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2\n      c0-3.1,2.6-5.6,5.7-5.6c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c3.1,0,5.7,2.5,5.7,5.6c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2\n      c0,3.1-2.6,5.6-5.7,5.6c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-1.1,0-2.1-0.3-2.9-0.8l-5.1,5c-0.2,0.2-0.5,0.3-0.8,0.3\n      s-0.6-0.1-0.8-0.3C11.2,26.7,11.2,26,11.7,25.6z M21.5,20.8c0.1,0,0.1,0,0.2,0c1.9,0,3.4-1.5,3.4-3.3c0-0.1,0-0.1,0-0.2\n      c0-0.1,0-0.1,0-0.2c0-1.8-1.5-3.3-3.4-3.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-1.9,0-3.4,1.5-3.4,3.3c0,0.1,0,0.1,0,0.2\n      c0,0.1,0,0.1,0,0.2c0,1.8,1.5,3.3,3.4,3.3C21.4,20.7,21.4,20.7,21.5,20.8z" })));
}
