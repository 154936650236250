import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import { captureException } from '@sentry/browser';
import api from '../../services/api';
import { validateEmail } from '../../utils/helpers';
import classes from './FooterStyles';
import ConfirmationBackdrop from '../CommonComponents/ConfirmationBackdrop';
import Facebook from '../../themes/icons/Facebook';
import Instagram from '../../themes/icons/Instagram';
import Pinterest from '../../themes/icons/Pinterest';
export default function Footer() {
    var currentYear = useState(function () { return new Date().getFullYear(); })[0];
    var _a = useState(''), emailValue = _a[0], setEmailValue = _a[1];
    var _b = useState(false), emailError = _b[0], setEmailError = _b[1];
    var _c = useState(''), emailErrorText = _c[0], setEmailErrorText = _c[1];
    var _d = useState(false), confirmOpen = _d[0], setConfirmOpen = _d[1];
    var _e = useState(false), userSubscribed = _e[0], setUserSubscribed = _e[1];
    var _f = useState(''), activePanel = _f[0], setActivePanel = _f[1];
    var footerPanels = useState([
        {
            name: 'help',
            links: [{ link: 'help', text: 'Need Help' },
                { link: 'https://plantlust.com/blog/contact-us/', text: 'Contact Us' },
                { link: 'https://plantlust.com/blog/order-status/', text: 'Order Status' },
                { link: 'https://plantlust.com/blog/refunds-and-replacements/', text: 'Refunds and Replacements' },
                { link: 'https://plantlust.com/blog/faq/', text: 'FAQ' },
                { link: 'https://plantlust.com/blog/feedback/', text: 'Feedback' }],
        },
        {
            name: 'links',
            links: [{ link: 'links', text: 'Quick Links' },
                { link: '/genus_index/a/', text: 'Plant Index' },
                { link: 'https://plantlust.com/blog/gift-cards/', text: 'Gift Cards' },
                { link: 'https://plantlust.com/blog/return-policy/', text: 'Return Policy' },
                { link: '/nurseries/', text: 'Nurseries' }],
        },
        {
            name: 'order',
            links: [{ link: 'order', text: 'Ordering Plants' },
                { link: 'https://plantlust.com/blog/ordering/', text: 'Shipping' },
                { link: 'https://plantlust.com/blog/plant-care-instructions/', text: 'Plant Care Instructions' },
                { link: 'https://plantlust.com/blog/landscape-orders/', text: 'Landscape Orders' }],
        },
        {
            name: 'contributors',
            links: [{ link: 'contributors', text: 'For Contributors' },
                { link: 'https://plantlust.com/blog/nursery-faq/', text: 'Nursery FAQ' },
                { link: 'https://plantlust.com/blog/sell-on-plantlust-com/', text: 'Sell Mail Order Plants' },
                { link: 'https://plantlust.com/blog/list-your-inventory/', text: 'List Your Inventory' },
                { link: 'https://plantlust.com/blog/contribute-photos/', text: 'Contribute Photos' }],
        },
        {
            name: 'info',
            links: [{ link: 'info', text: 'Company info' },
                { link: 'https://plantlust.com/blog/about-us/', text: 'About Us' },
                { link: 'https://plantlust.com/blog/terms-of-use/', text: 'Terms of Use' },
                { link: 'https://plantlust.com/blog/privacy-policy/', text: 'Privacy Policy' }],
        },
    ])[0];
    var notCartPage = true;
    function onEmailChange(e) {
        setEmailValue(e.target.value);
    }
    function clearErrors() {
        setEmailError(false);
        setEmailErrorText('');
    }
    function subscribeUser(e) {
        e.preventDefault();
        var email = document.forms.namedItem('mc_embedded_subscribe_form').email;
        if (email.value === '') {
            setEmailError(true);
            setEmailErrorText('Email is required');
        }
        else if (!validateEmail(email.value)) {
            setEmailError(true);
            setEmailErrorText('Email is invalid');
        }
        else {
            clearErrors();
            var data = {
                email: email.value,
                status: 'subscribed',
            };
            api.newsLetter.subscribe(data).then(function (res) {
                if (res.success
                    || (!res.success && res.message === 'Member Exists')) {
                    setUserSubscribed(true);
                    setConfirmOpen(true);
                }
            })
                .catch(function (error) {
                if (error.TypeError !== 'Failed to fetch')
                    captureException(error);
            });
        }
    }
    function closeModal(e) {
        e.preventDefault();
        setConfirmOpen(false);
    }
    function confirmModal() {
        var mainMessage = userSubscribed ? 'Subscribed Successfully!' : 'Something went wrong!';
        var secondMessages = userSubscribed && ['You are now subscribed to the Plantlust newsletter.'];
        if (confirmOpen) {
            return (React.createElement(ConfirmationBackdrop, { open: confirmOpen, mainMessage: mainMessage, secondMessages: secondMessages, close: closeModal }));
        }
        return null;
    }
    function togglePanel(panel) {
        var panelName = panel;
        if (activePanel === panel)
            panelName = '';
        setActivePanel(panelName);
    }
    return (React.createElement(Grid, { sx: classes.footerWrapper },
        React.createElement(Grid, { sx: classes.footerContainer },
            notCartPage && (React.createElement(React.Fragment, null,
                React.createElement(Grid, { sx: classes.newsletterSignup },
                    React.createElement(Typography, { variant: "h5", sx: classes.mailingListTypography }, "Mailing List"),
                    React.createElement(Typography, { component: "span", sx: classes.mailingListText }, "Sign Up for updates on new plant arrivals and deals"),
                    React.createElement("div", null,
                        React.createElement("form", { method: "post", id: "mc-embedded-subscribe-form", name: "mc_embedded_subscribe_form", className: "signup_form", onSubmit: subscribeUser, noValidate: true },
                            React.createElement(Grid, { container: true, wrap: "nowrap", justifyContent: "center", id: "mc_embed_signup_scroll" },
                                React.createElement(Box, { sx: classes.emailInputContainer },
                                    React.createElement(TextField, { variant: "outlined", required: true, fullWidth: true, placeholder: "Email Address", type: "email", value: emailValue, onChange: onEmailChange, name: "email", sx: classes.emailInputRoot, helperText: emailErrorText, FormHelperTextProps: {
                                            sx: classes.emailLabel,
                                        }, InputLabelProps: {
                                            margin: 'dense',
                                        }, id: "mce-EMAIL", InputProps: {
                                            sx: {
                                                input: classes.emailInput,
                                                error: classes.emailInputInvalid,
                                            },
                                            color: 'secondary',
                                            error: emailError,
                                            inputProps: {
                                                maxLength: 320,
                                            },
                                        } })),
                                React.createElement(Button, { type: "submit", disabled: userSubscribed, sx: classes.subscribeButton, size: "small", startIcon: userSubscribed && React.createElement(DoneIcon, null), disableRipple: true }, userSubscribed ? 'Added' : 'Join'))),
                        confirmModal())),
                React.createElement(Grid, { container: true, direction: "row", justifyContent: "center", sx: classes.socialLinksContainer },
                    React.createElement(Grid, { item: true, xs: 1, sx: {
                            '& > a > svg': {
                                fill: function (theme) { return theme.palette.primary.main; },
                            },
                        } },
                        React.createElement("a", { href: "https://facebook.com/plantlust", target: "_blank", rel: "noopener noreferrer" },
                            React.createElement(Facebook, null))),
                    React.createElement(Grid, { item: true, xs: 1, sx: {
                            '& > a > svg': {
                                fill: function (theme) { return theme.palette.primary.main; },
                            },
                        } },
                        React.createElement("a", { href: "https://instagram.com/plant_lust", target: "_blank", rel: "noopener noreferrer" },
                            React.createElement(Instagram, null))),
                    React.createElement(Grid, { item: true, xs: 1, sx: {
                            '& > a > svg': {
                                fill: function (theme) { return theme.palette.primary.main; },
                            },
                        } },
                        React.createElement("a", { href: "https://pinterest.com/plantlust", target: "_blank", rel: "noopener noreferrer" },
                            React.createElement(Pinterest, null)))))),
            React.createElement(Box, { sx: classes.footerNavigation }, footerPanels.map(function (panel) { return (React.createElement("ul", { key: panel.name, className: activePanel === panel.name ? 'active' : undefined }, panel.links.map(function (innerPanel, index) { return (index === 0 ? (React.createElement("li", { key: innerPanel.text, className: "expand-parent", onClick: function () { return togglePanel(panel.name); }, onKeyDown: function () { return togglePanel(panel.name); } },
                React.createElement(Typography, { variant: "h5", id: "panel-text", sx: classes.footerNavigationTypography }, innerPanel.text))) : (React.createElement("li", { key: innerPanel.text },
                React.createElement(Link, { sx: classes.footerNavigationLink, href: innerPanel.link }, innerPanel.text)))); }))); })),
            React.createElement(Typography, { sx: classes.copyRightText },
                "\u00A9 2010\u2014",
                currentYear,
                ' ',
                "Plant Lust, LLC All Rights Reserved"))));
}
