var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import List from '@mui/material/List';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import startsWith from 'lodash/startsWith';
import { isPDPPage } from '../../utils/helpers';
var classes = {
    popper: function (theme) {
        var _a;
        return (_a = {},
            _a[theme.breakpoints.down('xsMax')] = {
                width: '100%',
            },
            _a);
    },
};
function ZoneFilter(props) {
    var featuredPage = typeof window !== 'undefined' ? startsWith(window.location.pathname, '/featured') : false;
    var zones = props.zones, activeZone = props.activeZone, zoneSetHandler = props.zoneSetHandler;
    var _a = React.useState('All USDA Zones'), selectedZone = _a[0], setSelectedZone = _a[1];
    var _b = React.useState('0'), selectedZoneId = _b[0], setSelectedZoneId = _b[1];
    React.useEffect(function () {
        if (selectedZoneId !== activeZone) {
            var activeDescription_1 = 'All USDA Zones';
            zones.forEach(function (item) {
                if (item.zone === activeZone) {
                    activeDescription_1 = "".concat(activeZone, " ").concat(item.description);
                }
            });
            setSelectedZone(activeDescription_1);
        }
        setSelectedZoneId(activeZone);
    }, [activeZone, selectedZoneId, zones]);
    var _c = React.useState(false), ddActive = _c[0], setddActive = _c[1];
    var anchorRef = React.useRef(null);
    function handleToggle() {
        setddActive(function (prevOpen) { return !prevOpen; });
    }
    function handleClose(event) {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setddActive(false);
    }
    var prevddActive = React.useRef(ddActive);
    React.useEffect(function () {
        var _a;
        if (prevddActive.current === true && ddActive === false) {
            (_a = anchorRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        prevddActive.current = ddActive;
    }, [ddActive]);
    function zoneSelected(zoneId, changedZone) {
        if (selectedZoneId !== zoneId) {
            setSelectedZone(changedZone);
            setSelectedZoneId(zoneId);
            zoneSetHandler(zoneId);
        }
        setddActive(false);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { ref: anchorRef, id: "composition-button", onClick: handleToggle, "aria-controls": ddActive ? 'composition-menu' : undefined, "aria-expanded": ddActive ? 'true' : undefined, "aria-haspopup": "true", variant: "outlined", endIcon: ddActive ? React.createElement(ArrowDropUpIcon, { fontSize: "large" }) : React.createElement(ArrowDropDownIcon, { fontSize: "large" }), disableElevation: true, disableRipple: true, disableFocusRipple: true, sx: {
                display: (isPDPPage() || typeof window === 'undefined') && 'none',
                color: 'primary.dark',
                textAlign: 'left',
                textTransform: 'none',
                fontWeight: 'bold',
                bgcolor: 'background.default',
                borderRadius: 1.25,
                border: 1,
                position: 'relative',
                borderColor: 'secondary.contrastText',
                height: 42,
                justifyContent: 'space-between',
                width: '100%',
                p: '0px 12px',
                m: '15px 0 0 0',
                ml: {
                    sm: 2,
                },
                mt: {
                    sm: 0,
                },
                mr: {
                    sm: featuredPage ? 3.75 : 0,
                    lg: featuredPage && 10,
                },
                minWidth: {
                    sm: 180,
                    md: 224,
                },
                maxWidth: {
                    sm: 180,
                    md: 224,
                },
                '& .MuiButton-endIcon>*:nth-of-type(1)': {
                    fontSize: 26,
                },
            } }, selectedZone),
        React.createElement(Popper, { open: ddActive, anchorEl: anchorRef.current, role: undefined, placement: "bottom-start", transition: true, disablePortal: true, sx: classes.popper }, function (_a) {
            var TransitionProps = _a.TransitionProps, placement = _a.placement;
            return (React.createElement(Grow, __assign({}, TransitionProps, { style: {
                    transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                } }),
                React.createElement(Paper, { sx: {
                        mt: -0.125,
                        zIndex: 1,
                        bgcolor: 'background.default',
                    } },
                    React.createElement(ClickAwayListener, { onClickAway: handleClose },
                        React.createElement(List, { disablePadding: true, dense: true, sx: {
                                width: '100%',
                                overflow: 'scroll',
                                borderRadius: '0px 0px 5px 5px',
                                height: {
                                    xsMax: 500,
                                    xs: 280,
                                },
                                minWidth: {
                                    xs: '100%',
                                    sm: 180,
                                    md: 224,
                                },
                                maxWidth: {
                                    xs: '100%',
                                    sm: 180,
                                    md: 224,
                                },
                            } },
                            React.createElement(ListItemButton, { selected: selectedZoneId === '0', onClick: function () { return zoneSelected('0', 'All USDA Zones'); }, sx: {
                                    p: '12px 10px',
                                    borderLeft: 8,
                                    borderLeftColor: '#808285',
                                    color: '#808285',
                                    bgcolor: 'background.default',
                                    '&.Mui-selected': {
                                        color: 'background.default',
                                        bgcolor: '#808285',
                                        ':hover': {
                                            bgcolor: '#808285',
                                        },
                                    },
                                    ':hover': {
                                        color: 'background.default',
                                        bgcolor: '#808285',
                                    },
                                }, onKeyDown: function () { return zoneSelected('0', 'All USDA Zones'); }, disableRipple: true, disableTouchRipple: true, disableGutters: true },
                                React.createElement(ListItemText, { sx: {
                                        mt: 0,
                                        mb: 0,
                                        '& .MuiTypography-root': {
                                            typography: {
                                                fontSize: 12,
                                                lineHeight: 1,
                                            },
                                        },
                                    }, primary: "All USDA Zones" })), zones === null || zones === void 0 ? void 0 :
                            zones.map(function (item) { return (React.createElement(ListItemButton, { sx: {
                                    p: '12px 10px',
                                    borderLeft: 8,
                                    borderLeftColor: "".concat(item.color),
                                    color: '#808285',
                                    bgcolor: 'background.default',
                                    '&.Mui-selected': {
                                        color: 'background.default',
                                        bgcolor: item.color,
                                        ':hover': {
                                            bgcolor: item.color,
                                        },
                                    },
                                    ':hover': {
                                        color: 'background.default',
                                        bgcolor: item.color,
                                    },
                                }, key: item.zone, selected: selectedZoneId === item.zone, onClick: function () { return zoneSelected(item.zone, "".concat(item.zone, " ").concat(item.description)); }, onKeyDown: function () { return zoneSelected(item.zone, "".concat(item.zone, " ").concat(item.description)); }, disableRipple: true, disableTouchRipple: true, disableGutters: true },
                                React.createElement(ListItemText, { sx: {
                                        mt: 0,
                                        mb: 0,
                                        '& .MuiTypography-root': {
                                            typography: {
                                                fontSize: 12,
                                                lineHeight: 1,
                                            },
                                        },
                                    }, primary: "".concat(item.zone, " ").concat(item.description) }))); }))))));
        })));
}
export default ZoneFilter;
