import React from 'react';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { numberFormat } from '../../utils/helpers';
function ShippingMethodsRadioGroup(props) {
    var methods = props.methods, setShippingPrice = props.setShippingPrice, selectedNurseries = props.selectedNurseries;
    var _a = React.useState(methods), shippingMethods = _a[0], setShippingMethods = _a[1];
    var _b = React.useState(0), indexValue = _b[0], setIndexValue = _b[1];
    var _c = React.useState(0), prevIndex = _c[0], setPrevIndex = _c[1];
    var _d = React.useState(''), value = _d[0], setValue = _d[1];
    React.useEffect(function () {
        setShippingMethods(methods);
        if (methods.length > 0) {
            setValue(methods[0].service_name);
        }
        if (selectedNurseries === 1) {
            setIndexValue(prevIndex);
            setValue(methods[indexValue].service_name);
        }
        else {
            setIndexValue(0);
            setValue(methods[0].service_name);
        }
        setShippingPrice(methods[indexValue].total_price / 100, indexValue);
    }, [indexValue, methods, prevIndex, selectedNurseries, setShippingPrice, shippingMethods]);
    function handleChange(event) {
        var totalPrice = shippingMethods.find(function (method) { return method.service_name
            === event.target.value; });
        setValue(event.target.value);
        setIndexValue(shippingMethods.indexOf(totalPrice));
        setPrevIndex(shippingMethods.indexOf(totalPrice));
        setShippingPrice(shippingMethods[shippingMethods.indexOf(totalPrice)].total_price / 100, shippingMethods.indexOf(totalPrice));
    }
    return (React.createElement(Grid, { container: true, sx: { pt: 3 } },
        React.createElement(RadioGroup, { "aria-label": shippingMethods[indexValue].service_name, name: shippingMethods[indexValue].service_name, value: value, onChange: handleChange }, shippingMethods === null || shippingMethods === void 0 ? void 0 : shippingMethods.map(function (method, index) {
            var disabled = shippingMethods.length > 1 && selectedNurseries > 1 && index !== 0;
            var serviceLabel = "".concat(method.service_name, " - $").concat(numberFormat(method.total_price / 100));
            if (disabled) {
                serviceLabel = (React.createElement(React.Fragment, null,
                    React.createElement("b", null,
                        method.service_name,
                        ' ',
                        "- $",
                        numberFormat(method.total_price / 100)),
                    React.createElement("br", null),
                    "UNAVAILABLE: the lowest priced option will automatically be applied when cart contains plants from mulltiple nurseries"));
            }
            return (React.createElement(FormControlLabel, { disabled: disabled, key: method.service_name, value: method.service_name, control: React.createElement(Radio, { disabled: disabled, sx: { '&.Mui-disabled': { pt: 0 } }, disableRipple: true, size: "small" }), label: serviceLabel, sx: {
                    alignItems: disabled ? 'flex-start' : 'center',
                    '& .MuiFormControlLabel-label': {
                        fontSize: 14,
                        color: 'blueColors.main',
                        lineHeight: '18px',
                        whiteSpace: 'pre-line',
                        textAlign: 'left',
                        '&.Mui-disabled': { color: 'blueColors.main' },
                    },
                } }));
        }))));
}
export default ShippingMethodsRadioGroup;
