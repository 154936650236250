var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function Location(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 24 24" }),
        React.createElement("rect", { id: "Rectangle", fill: "#FFFFFF", x: "0", y: "0", width: "24", height: "24" }),
        React.createElement("path", { d: "M22.857225,18.7419907 L1.0491,18.7419907 L1.0491,5.60226977 L1.125225,5.66254884 L11.625225,13.999293 C11.81835,14.1525953 12.08835,14.1525953 12.2811,13.999293 L22.857225,5.60226977 L22.857225,18.7419907 Z M21.890475,5.04115349 L11.952975,12.9258047 L2.01585,5.04115349 L21.890475,5.04115349 Z M23.0961,4.00003721 L0.810225,4.00003721 C0.3711,4.00003721 -0.00015,4.36803721 -0.00015,4.80413023 L-0.00015,18.979014 C-0.00015,19.415107 0.3711,19.783107 0.810225,19.783107 L23.0961,19.783107 C23.535225,19.783107 23.9061,19.415107 23.9061,18.979014 L23.9061,4.80413023 C23.9061,4.36803721 23.535225,4.00003721 23.0961,4.00003721 L23.0961,4.00003721 Z", id: "Fill-1", fill: "#000000" })));
}
