var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function standing_water(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 -2 70 70" }),
        React.createElement("path", { d: "M14.9,22.6c-7.7,0-14.5-4.8-14.8-5L-1,16.7l0.7-1.2c0.2-0.4,5.9-10,15-11.9C22.1,2,29.5,6,32.3,7.7\n      c0.6,0.3,1,0.9,1.1,1.6c0.1,0.6,0,1.3-0.3,1.8L33,11.3c-4.2,6-9.2,9.8-14.4,10.9C17.4,22.4,16.2,22.6,14.9,22.6z M3.1,15.9\n      c2.6,1.5,8.9,4.7,14.9,3.4c4.4-0.9,8.6-4.1,12.3-9.2c-2.5-1.5-8.8-4.7-14.9-3.4C9.3,7.8,4.8,13.4,3.1,15.9z M30.7,10.3\n      C30.7,10.3,30.7,10.3,30.7,10.3C30.7,10.3,30.7,10.3,30.7,10.3z" }),
        React.createElement("path", { d: "M48.6,17.8c-8.2,0-15.1-6.4-16.4-7.7c-0.5-0.5-0.7-1.1-0.7-1.8c0-0.7,0.3-1.3,0.7-1.8\n      c2.5-2.3,8.9-7.7,16.4-7.7c9.3,0,16.8,8.1,17.1,8.5l0.9,1l-0.9,1C65.4,9.6,58.1,17.8,48.6,17.8z M34.7,8.3\n      c1.8,1.7,7.4,6.5,13.9,6.5c6.3,0,11.7-4.4,13.9-6.5c-2.2-2-7.7-6.5-13.9-6.5C42.6,1.8,37.2,6.1,34.7,8.3z" }),
        React.createElement("path", { d: "M32.6,65.7c-0.8,0-1.5-0.7-1.5-1.5v-64c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v64\n      C34.1,65,33.4,65.7,32.6,65.7z" }),
        React.createElement("path", { d: "M58.5,51.8c-2,0-3.1-0.7-4-1.2c-0.7-0.4-1.2-0.8-2.4-0.8c-1.2,0-1.7,0.3-2.4,0.8c-0.9,0.5-2,1.2-4,1.2\n      s-3.1-0.7-4-1.2c-0.7-0.4-1.2-0.8-2.4-0.8s-1.7,0.3-2.4,0.8c-0.9,0.5-2,1.2-4,1.2s-3.1-0.7-4-1.2c-0.7-0.4-1.2-0.8-2.4-0.8\n      s-1.7,0.3-2.4,0.8c-0.9,0.5-2,1.2-4,1.2c-2,0-3.1-0.7-4-1.2c-0.7-0.4-1.2-0.8-2.4-0.8c-1.2,0-1.7,0.3-2.4,0.8\n      c-0.9,0.5-2,1.2-4,1.2c-2,0-3.1-0.7-4-1.2c-0.7-0.4-1.2-0.8-2.4-0.8c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5c2,0,3.1,0.7,4,1.2\n      c0.7,0.4,1.2,0.8,2.4,0.8c1.2,0,1.7-0.3,2.4-0.8c0.9-0.5,2-1.2,4-1.2c2,0,3.1,0.7,4,1.2c0.7,0.4,1.2,0.8,2.4,0.8\n      c1.2,0,1.7-0.3,2.4-0.8c0.9-0.5,2-1.2,4-1.2s3.1,0.7,4,1.2c0.7,0.4,1.2,0.8,2.4,0.8s1.7-0.3,2.4-0.8c0.9-0.5,2-1.2,4-1.2\n      s3.1,0.7,4,1.2c0.7,0.4,1.2,0.8,2.4,0.8s1.7-0.3,2.4-0.8c0.9-0.5,2-1.2,4-1.2c2,0,3.1,0.7,4,1.2c0.7,0.4,1.2,0.8,2.4,0.8\n      s1.7-0.3,2.4-0.8c0.9-0.5,2-1.2,4-1.2c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5c-1.2,0-1.7,0.3-2.4,0.8\n      C61.6,51.1,60.5,51.8,58.5,51.8z" }),
        React.createElement("path", { d: "M11.5,15.7c-0.6,0-1.2-0.4-1.4-0.9c-0.3-0.8,0.1-1.6,0.8-2C17.6,10.1,25,8.4,32.2,8C33,8,33.8,8.6,33.8,9.4\n      S33.2,11,32.4,11c-6.9,0.4-13.9,2-20.3,4.6C11.9,15.7,11.7,15.7,11.5,15.7z" }),
        React.createElement("path", { d: "M32.7,10.8c-0.6,0-1.2-0.4-1.4-1.1c-0.2-0.8,0.2-1.6,1-1.9c7.2-2.3,14.9-2.6,22.4-0.9c0.8,0.2,1.3,1,1.1,1.8\n      c-0.2,0.8-1,1.3-1.8,1.1C47,8.3,39.8,8.6,33.1,10.7C33,10.8,32.8,10.8,32.7,10.8z" })));
}
