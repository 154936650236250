var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function low(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 76 65" }),
        React.createElement("path", { d: "M35.3,1.3l-0.1,0.1l-0.1,0.1L17.4,31.9c-6,10.5-2.4,24,8.2,30s24,2.4,30-8.2\n    c1.9-3.3,2.9-7.1,2.9-11c0-3.8-1-7.5-2.9-10.9v-0.1L37.9,1.4l-0.2-0.2l-0.1-0.1L37.5,1l-0.2-0.1l-0.1-0.1L37,0.7l-0.1-0.1H37h-0.2\n    h-0.3h-0.1h-0.2h-0.1l-0.2,0.1l-0.1,0.1l-0.2,0.1L35.5,1L35.3,1.3z M36.6,5.6l16.2,27.9c5.1,9,2,20.4-6.9,25.5\n    c-9,5.1-20.4,2-25.5-6.9c-3.3-5.7-3.3-12.8,0-18.5L36.6,5.6z" }),
        React.createElement("path", { d: "M2.6,51.5h-2c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h2c0.8,0,1.5,0.7,1.5,1.5S3.4,51.5,2.6,51.5z" }),
        React.createElement("path", { d: "M62.6,51.5h-4c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h4c0.8,0,1.5,0.7,1.5,1.5S63.4,51.5,62.6,51.5z\n     M50.6,51.5h-4c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h4c0.8,0,1.5,0.7,1.5,1.5S51.4,51.5,50.6,51.5z M38.6,51.5h-4\n    c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h4c0.8,0,1.5,0.7,1.5,1.5S39.4,51.5,38.6,51.5z M26.6,51.5h-4c-0.8,0-1.5-0.7-1.5-1.5\n    s0.7-1.5,1.5-1.5h4c0.8,0,1.5,0.7,1.5,1.5S27.4,51.5,26.6,51.5z M14.6,51.5h-4c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h4\n    c0.8,0,1.5,0.7,1.5,1.5S15.4,51.5,14.6,51.5z" }),
        React.createElement("path", { d: "M72.6,51.5h-2c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h2c0.8,0,1.5,0.7,1.5,1.5S73.4,51.5,72.6,51.5z" }),
        React.createElement("polygon", { points: "20.8,55.4 23.9,58.8 30.3,61.9 36.9,63.4 44.9,61.4 51.3,56.6 52.2,55.4" })));
}
