var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function mostly_sun(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 64 64" }),
        React.createElement("path", { d: "M54.3,30.2 C54.2,29.1 54.1,28.1 53.8,27.1 C53.5,25.9 53.1,24.6 52.7,23.5 C52.2,22.4 51.7,21.4 51.1,20.4 C50.3,19.1 49.4,17.9 48.3,16.8 C44.2,12.4 38.4,9.7 32,9.7 C19.7,9.7 9.6,19.7 9.6,32.1 C9.6,44.4 19.6,54.5 32,54.5 C38.4,54.5 44.2,51.8 48.3,47.4 C49.3,46.3 50.3,45.1 51.1,43.8 C51.7,42.8 52.3,41.8 52.7,40.7 C53.2,39.5 53.6,38.3 53.8,37.1 C54,36.1 54.2,35 54.3,34 C54.3,33.4 54.4,32.8 54.4,32.2 C54.4,31.6 54.3,30.8 54.3,30.2 Z M49.3,37 L42.1,37 L42.1,40.6 L47.9,40.6 C47.3,41.7 46.6,42.8 45.8,43.7 L42.2,43.7 L42.2,47 C39.3,49 35.8,50.1 32.1,50.1 C22.2,50.1 14.1,42 14.1,32.1 C14.1,22.2 22.2,14.1 32.1,14.1 C35.8,14.1 39.3,15.2 42.2,17.2 L42.2,20.5 L45.8,20.5 C46.6,21.5 47.3,22.5 47.9,23.6 L42.1,23.6 L42.1,27.2 L49.3,27.2 C49.6,28.2 49.8,29.3 49.9,30.3 L42.1,30.3 L42.1,33.9 L49.9,33.9 C49.8,34.9 49.6,36 49.3,37 Z", id: "Shape" }),
        React.createElement("circle", { id: "Oval", cx: "32.1", cy: "2.9", r: "2.9" }),
        React.createElement("circle", { id: "Oval", transform: "translate(17.523800, 6.800500) rotate(-30.000728) translate(-17.523800, -6.800500) ", cx: "17.5238", cy: "6.8005", r: "2.8999362" }),
        React.createElement("circle", { id: "Oval", transform: "translate(6.834300, 17.403400) rotate(-59.999272) translate(-6.834300, -17.403400) ", cx: "6.8343", cy: "17.4034", r: "2.8999362" }),
        React.createElement("circle", { id: "Oval", cx: "2.9", cy: "31.9", r: "2.9" }),
        React.createElement("circle", { id: "Oval", transform: "translate(6.708400, 46.489000) rotate(-30.000728) translate(-6.708400, -46.489000) ", cx: "6.7084", cy: "46.489", r: "2.8999362" }),
        React.createElement("circle", { id: "Oval", transform: "translate(17.312400, 57.198500) rotate(-59.999272) translate(-17.312400, -57.198500) ", cx: "17.3124", cy: "57.1985", r: "2.8999362" }),
        React.createElement("circle", { id: "Oval", cx: "31.8", cy: "61.1", r: "2.9" }),
        React.createElement("circle", { id: "Oval", transform: "translate(46.397000, 57.304300) rotate(-30.000728) translate(-46.397000, -57.304300) ", cx: "46.397", cy: "57.3043", r: "2.8999362" }),
        React.createElement("circle", { id: "Oval", transform: "translate(57.107600, 46.720400) rotate(-59.999272) translate(-57.107600, -46.720400) ", cx: "57.1076", cy: "46.7204", r: "2.8999362" }),
        React.createElement("circle", { id: "Oval", cx: "61.1", cy: "32.2", r: "2.9" }),
        React.createElement("circle", { id: "Oval", transform: "translate(57.212300, 17.615800) rotate(-30.000728) translate(-57.212300, -17.615800) ", cx: "57.2123", cy: "17.6158", r: "2.8999362" }),
        React.createElement("circle", { id: "Oval", transform: "translate(46.629500, 6.925300) rotate(-59.999272) translate(-46.629500, -6.925300) ", cx: "46.6295", cy: "6.9253", r: "2.8999362" })));
}
