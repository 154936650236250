var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function occasional(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 71 92" }),
        React.createElement("path", { d: "M26.3,50.5 C26.3,48.3 25.7,46.1 24.6,44.2 L14.8,27 L14.7,26.8 L14.6,26.7 L14.5,26.6 L14.4,26.5 L13.9,26.3 L13.7,26.3 L13.6,26.3 L13.3,26.3 L13,26.3 L12.9,26.3 L12.7,26.4 L12.6,26.5 L12.5,26.6 L12.3,26.8 L12.2,26.9 L2.2,44.1 C-1.3,50.3 0.8,58.2 7,61.7 C13.2,65.2 21.1,63.1 24.6,56.9 C25.8,54.9 26.3,52.7 26.3,50.5 L26.3,50.5 Z M3.6,50.5 C3.6,48.8 4,47.1 4.9,45.6 L13.4,30.8 L21.9,45.6 C24.6,50.3 23,56.3 18.3,59 C13.6,61.7 7.6,60.1 4.9,55.4 C4.1,53.9 3.6,52.2 3.6,50.5 Z" }),
        React.createElement("path", { d: "M36.5,54.7 L36.4,54.5 L36.3,54.4 L36.2,54.3 L36.1,54.2 L35.7,54 L35.5,54 L35.3,54 L35,54 L34.8,54 L34.7,54 L34.6,54 L34.4,54.1 L34.3,54.2 L34.2,54.3 L34,54.5 L33.9,54.6 L24,71.8 C20.5,78 22.6,85.9 28.8,89.4 C35,92.9 42.9,90.8 46.4,84.6 C48.7,80.7 48.7,75.8 46.4,71.8 L36.5,54.7 Z" }),
        React.createElement("path", { d: "M36.5,1.1 L36.4,0.9 L36.3,0.8 L36.2,0.7 L36.1,0.6 L35.7,0.4 L35.5,0.4 L35.3,0.4 L35,0.4 L34.8,0.4 L34.7,0.4 L34.6,0.4 L34.4,0.5 L34.3,0.6 L34.2,0.7 L34,0.9 L33.9,1 L24,18.2 C20.5,24.4 22.6,32.3 28.8,35.8 C35,39.3 42.9,37.2 46.4,31 C48.7,27.1 48.7,22.2 46.4,18.2 L36.5,1.1 Z" }),
        React.createElement("path", { d: "M69.1,44.1 L59.2,27 L58.8,26.6 L58.6,26.5 L58.3,26.4 L58.2,26.4 L58.1,26.4 L58,26.4 L57.8,26.4 L57.5,26.4 L57.4,26.4 L57.3,26.4 L57.1,26.5 L57,26.6 L56.8,26.7 L56.6,26.9 L56.5,27 L46.6,44.2 C43.1,50.4 45.2,58.3 51.4,61.8 C57.6,65.3 65.5,63.2 69,57 C71.3,53 71.3,48.1 69.1,44.1 L69.1,44.1 Z M67.6,50.5 C67.6,55.9 63.2,60.3 57.8,60.3 C52.4,60.3 48,55.9 48,50.5 C48,48.8 48.4,47.1 49.3,45.6 L57.8,30.8 L66.3,45.6 C67.2,47.1 67.6,48.8 67.6,50.5 L67.6,50.5 Z" })));
}
