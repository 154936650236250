var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function bright_shade(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 0 65 65" }),
        React.createElement("path", { id: "Shape", d: "M54.4,31.3c-0.1-1.2-0.3-2.4-0.5-3.6c-0.3-1.1-0.6-2.1-1-3.1c-0.5-1.3-1.1-2.5-1.9-3.6\n  c-0.7-1.1-1.5-2.2-2.4-3.1c-1.2-1.4-2.6-2.6-4.2-3.6c-3.5-2.4-7.8-3.7-12.3-3.7c-12.3,0-22.4,10-22.4,22.4s10,22.4,22.4,22.4\n  c4.6,0,8.8-1.4,12.3-3.7c1.5-1,2.9-2.2,4.2-3.6c0.9-1,1.7-2,2.4-3.1s1.3-2.3,1.9-3.6c0.4-1,0.7-2.1,1-3.1c0.3-1.2,0.5-2.4,0.5-3.6\n  c0-0.5,0.1-1,0.1-1.6C54.5,32.5,54.4,31.8,54.4,31.3z M49.4,38.1c-0.3,1.1-0.8,2.1-1.3,3.1H22v3.6h23.6c-1,1.2-2.2,2.2-3.6,3.1\n  H22.2c-4.9-3.2-8.1-8.8-8.1-15s3.2-11.8,8.1-15h19.9c1.3,0.9,2.5,1.9,3.6,3.1H22v3.6h26.1c0.5,1,0.9,2,1.3,3.1H22v3.6h28.1\n  c0,0.5,0.1,1,0.1,1.6c0,0.6,0,1-0.1,1.6H22v3.6H49.4L49.4,38.1z" }),
        React.createElement("circle", { id: "Oval", cx: "32.2", cy: "3.8", r: "2.9" }),
        React.createElement("ellipse", { id: "Oval_1_", transform: "matrix(0.866 -0.5 0.5 0.866 -1.4148 9.8597)", cx: "17.7", cy: "7.6", rx: "2.9", ry: "2.9" }),
        React.createElement("ellipse", { id: "Oval_2_", transform: "matrix(0.5 -0.866 0.866 0.5 -12.2548 15.1071)", cx: "7", cy: "18.2", rx: "2.9", ry: "2.9" }),
        React.createElement("circle", { id: "Oval_3_", cx: "3", cy: "32.8", r: "2.9" }),
        React.createElement("ellipse", { id: "Oval_4_", transform: "matrix(0.866 -0.5 0.5 0.866 -22.7451 9.7328)", cx: "6.8", cy: "47.3", rx: "2.9", ry: "2.9" }),
        React.createElement("ellipse", { id: "Oval_5_", transform: "matrix(0.5 -0.866 0.866 0.5 -41.5793 44.0785)", cx: "17.4", cy: "58", rx: "2.9", ry: "2.9" }),
        React.createElement("circle", { id: "Oval_6_", cx: "32", cy: "62", r: "2.9" }),
        React.createElement("ellipse", { id: "Oval_7_", transform: "matrix(0.866 -0.5 0.5 0.866 -22.8354 31.0998)", cx: "46.6", cy: "58.2", rx: "2.9", ry: "2.9" }),
        React.createElement("ellipse", { id: "Oval_8_", transform: "matrix(0.5 -0.866 0.866 0.5 -12.5079 73.3029)", cx: "57.2", cy: "47.5", rx: "2.9", ry: "2.9" }),
        React.createElement("circle", { id: "Oval_9_", cx: "61.2", cy: "33", r: "2.9" }),
        React.createElement("ellipse", { id: "Oval_10_", transform: "matrix(0.866 -0.5 0.5 0.866 -1.505 31.1534)", cx: "57.4", cy: "18.4", rx: "2.9", ry: "2.9" }),
        React.createElement("ellipse", { id: "Oval_11_", transform: "matrix(0.5 -0.866 0.866 0.5 16.7166 44.3316)", cx: "46.8", cy: "7.7", rx: "2.9", ry: "2.9" })));
}
