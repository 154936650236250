var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export default function dry_in_winter(props) {
    return (React.createElement(SvgIcon, __assign({}, props, { viewBox: "0 -9 65 77" }),
        React.createElement("path", { d: "M48.2,19.5c-0.6,0-1-0.4-1-1v-18c0-0.6,0.4-1,1-1s1,0.4,1,1v18C49.2,19.1,48.8,19.5,48.2,19.5z" }),
        React.createElement("path", { d: "M54.7,16.8c-0.3,0-0.5-0.1-0.7-0.3L41.3,3.8c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l12.7,12.7\n    c0.4,0.4,0.4,1,0,1.4C55.2,16.7,55,16.8,54.7,16.8z" }),
        React.createElement("path", { d: "M57.4,10.4h-18c-0.6,0-1-0.4-1-1s0.4-1,1-1h18c0.6,0,1,0.4,1,1S58,10.4,57.4,10.4z" }),
        React.createElement("path", { d: "M42,16.6c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4L54,2.2c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L42.7,16.3\n    C42.5,16.5,42.3,16.6,42,16.6z" }),
        React.createElement("path", { d: "M44.8,25.7L44.8,25.7c-4.3-3.5-9.6-5.6-15.1-6v-1.8c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v1.8\n    c-5.5,0.4-10.8,2.5-15.1,6c-4.4,3.6-7.6,8.6-9,14.1l-0.9,3.4L3,40.5c1.6-1.9,4.6-3,7.9-3s6.3,1.2,7.9,3.2l0.6,0.5l0.5-0.6\n    c1.5-1.6,3.6-2.6,5.8-2.9v22.4c0,3.8,3.1,6.9,6.9,6.9c3.8,0,6.9-3.1,6.9-6.9l0,0c0-1.1-0.9-2-2-2s-2,0.9-2,2\n    c0.1,1.6-1.1,3-2.7,3.1c-1.6,0.1-3-1.1-3.1-2.7c0-0.1,0-0.2,0-0.3V37.7c2.3,0.3,4.4,1.3,5.9,3l0.6,0.5l0.5-0.6\n    c1.6-1.9,4.6-3,7.8-3s6.3,1.2,7.8,3l2.2,2.7l-0.9-3.4C52.4,34.3,49.2,29.4,44.8,25.7z M46.6,33.6c-3.6-0.4-7.3,0.3-10.5,2.2\n    c-2.5-1.6-5.5-2.4-8.5-2.3c-3,0-5.9,0.8-8.5,2.3c-3.1-1.9-6.8-2.7-10.5-2.2c7.2-10.4,21.6-13,32-5.8C43,29.3,45,31.3,46.6,33.6z" })));
}
