import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/core/Skeleton';
export default function IndexPageLoading() {
    if (window.innerWidth < 768) {
        return (React.createElement(Box, { sx: {
                display: 'flex', flexDirection: 'column', justifyContent: 'center', my: 2, mx: 'auto',
            } },
            React.createElement(Skeleton, { variant: "rectangular", width: 340, height: 320, sx: {
                    my: 2, mx: 'auto', borderRadius: 1,
                } }),
            React.createElement(Box, { sx: {
                    width: '100%', display: 'flex', justifyContent: 'center', my: 0.5, mx: 'auto',
                } },
                React.createElement(Skeleton, { variant: "rectangular", width: 165, height: 160, sx: { borderRadius: 1, mr: 0.75 } }),
                React.createElement(Skeleton, { variant: "rectangular", width: 165, height: 160, sx: { borderRadius: 1, ml: 0.75 } }))));
    }
    return (React.createElement(Box, { sx: {
            display: 'flex', flexDirection: 'column', justifyContent: 'center', my: 2, mx: 'auto',
        } },
        React.createElement(Skeleton, { variant: "rectangular", width: 1120, height: 500, sx: {
                my: 2, mx: 'auto', borderRadius: 1,
            } }),
        React.createElement(Box, { sx: {
                width: '100%', display: 'flex', justifyContent: 'center', my: 2, mx: 'auto',
            } },
            React.createElement(Skeleton, { variant: "rectangular", width: 550, height: 350, sx: { borderRadius: 1, mr: 1.5 } }),
            React.createElement(Skeleton, { variant: "rectangular", width: 550, height: 350, sx: { borderRadius: 1, ml: 1.5 } }))));
}
