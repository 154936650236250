export var HOMEPAGE = '/';
export var INDEX_DATA = '/api/index_data/';
export var COMMON_DATA = '/api/common_data/';
export var SEARCH = '/searchq/?';
export var NURSURY = '/api/nursery/';
export var NURSERY_SEARCH = '/searchq/?nurseries=';
export var PLANTS = '/api/plants/';
export var FEATURE = '/api/featured/';
export var REQUEST_PLANT = '/plants/utils/request_plant/';
export var AUTO_SEARCH = '/search_auto/?query=';
export var NEWSLETTER_SUBSCRIBE = '/api/newsletter_subscribe/';
export var NAVIGATION_DATA = '/api/navigation/';
export var ADD_ITEMS = '/api/cart/add/';
export var CART_CONTENTS = '/api/cart/list/';
export var CLEAR_CART = '/api/cart/clear/';
export var SHOPIFY_PUBLIC_ACCESS_TOKEN = 'c30eddf2f2686ec06759252a749049fc';
export var SHOPIFY_STORE_DOMAIN = 'https://plant-lust.myshopify.com';
export var SHOPIFY_VERSION = '2025-01';
