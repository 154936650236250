var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Button from '@mui/material/Button';
import CustomLoader from './CustomLoader';
var classes = {
    buttonRoot: function (theme) { return ({
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },
        '&:hover.Mui-disabled, & .Mui-disabled': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.background.default,
            opacity: 0.5,
        },
    }); },
    loaderRoot: function (theme) { return ({
        color: theme.palette.background.default,
        padding: theme.spacing(0, 0.65),
    }); },
};
export default function CustomButton(props) {
    var buttonText = props.buttonText, buttonLoading = props.buttonLoading, buttonClassObj = props.buttonClassObj, loaderAttributes = props.loaderAttributes, buttonClassSX = props.buttonClassSX, other = __rest(props, ["buttonText", "buttonLoading", "buttonClassObj", "loaderAttributes", "buttonClassSX"]);
    return (React.createElement(Button, __assign({ disableRipple: true, disableFocusRipple: true, classes: buttonClassObj, sx: [classes.buttonRoot, buttonClassSX] }, other),
        buttonLoading && (React.createElement(CustomLoader, { loaderClassName: classes.loaderRoot, loaderAttributes: loaderAttributes })),
        buttonText));
}
CustomButton.defaultProps = {
    buttonLoading: false,
    loaderAttributes: {
        size: 20,
        thickness: 5,
    },
    buttonClassObj: {},
    buttonClassSX: {},
};
