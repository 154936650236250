import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
var useStyles = makeStyles(function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var palette = _a.palette, spacing = _a.spacing, breakpoints = _a.breakpoints;
    return createStyles({
        navWrapper: (_b = {
                margin: 'auto',
                borderBottom: "1px solid ".concat(palette.secondary.contrastText),
                '& > *': {
                    transition: '0.3s',
                }
            },
            _b[breakpoints.down('sm')] = {
                textAlign: 'center',
                zIndex: 100,
                background: palette.background.default,
            },
            _b),
        navWrapperHidden: {
            display: 'none',
        },
        minHeight: (_c = {
                minHeight: 632,
                borderBottom: '1px solid #D8D8D8'
            },
            _c[breakpoints.between('md', 'mdMax')] = {
                minHeight: 500,
            },
            _c[breakpoints.between('sm', 'mdMin')] = {
                minHeight: 390,
            },
            _c[breakpoints.down('xsMax')] = {
                minHeight: 290,
            },
            _c),
        nurseryHeader: (_d = {
                textAlign: 'left',
                height: 632
            },
            _d[breakpoints.down('mdMin')] = {
                padding: spacing(0, 3.75),
                height: 390,
            },
            _d[breakpoints.between('md', 'mdMax')] = {
                height: 500,
            },
            _d[breakpoints.down('xsMax')] = {
                position: 'relative',
                height: 'auto',
                padding: 0,
                top: 0,
            },
            _d),
        nurseryAvatar: (_e = {
                width: 120,
                height: 120,
                overflow: 'hidden',
                borderRadius: '50%',
                color: "".concat(palette.primary.main, " !important"),
                marginBottom: spacing(3.5)
            },
            _e[breakpoints.between('md', 'mdMax')] = {
                width: 100,
                height: 100,
                marginBottom: spacing(1.5),
            },
            _e[breakpoints.between('sm', 'mdMin')] = {
                width: 70,
                height: 70,
                marginBottom: spacing(1.75),
            },
            _e[breakpoints.down('xsMax')] = {
                width: 80,
                height: 80,
                display: 'inline-block',
                marginBottom: spacing(1.75),
            },
            _e['& > span'] = {
                display: 'flex',
                width: '100%',
                height: '100%',
                backgroundColor: palette.primary.light,
                color: palette.background.default,
                fontSize: 46,
                alignItems: 'center',
                justifyContent: 'center',
            },
            _e['& > img'] = {
                width: '100%',
                height: '100%',
            },
            _e),
        nurseryTypographyH1: (_f = {
                color: palette.primary.dark,
                fontSize: spacing(4.5),
                lineHeight: '44px',
                fontWeight: 'bold',
                textTransform: 'capitalize',
                '& > a': {
                    color: palette.primary.dark,
                }
            },
            _f[breakpoints.between('md', 'mdMax')] = {
                fontSize: spacing(3.5),
                lineHeight: '38px',
            },
            _f[breakpoints.between('sm', 'mdMin')] = {
                fontSize: spacing(3),
                lineHeight: '30px',
            },
            _f[breakpoints.down('xsMax')] = {
                fontSize: spacing(3),
                lineHeight: '32px',
            },
            _f),
        nurseryHeaderWrapper: (_g = {
                paddingLeft: spacing(2),
                paddingRight: spacing(3.75),
                paddingTop: spacing(5)
            },
            _g[breakpoints.up('lg')] = {
                width: 500,
            },
            _g[breakpoints.between('sm', 'mdMin')] = {
                paddingLeft: 0,
                paddingRight: spacing(1.25),
                width: 318,
                paddingTop: spacing(2.5),
                boxSizing: 'initial',
            },
            _g[breakpoints.between('md', 'mdMax')] = {
                paddingTop: spacing(5),
                paddingLeft: spacing(3.75),
                width: 518,
            },
            _g[breakpoints.down('xsMax')] = {
                width: '100%',
                paddingTop: 124,
                textAlign: 'center',
                height: 'auto',
            },
            _g),
        nurseryHeaderGroup: (_h = {
                height: '30%'
            },
            _h[breakpoints.down('xsMax')] = {
                height: 'auto',
            },
            _h),
        nureryDetails: (_j = {
                height: '70%',
                '& > button': {
                    display: 'none',
                }
            },
            _j[breakpoints.down('xsMax')] = {
                display: 'block',
                height: 'auto',
                overflow: 'hidden',
                maxHeight: spacing(2.5),
                paddingLeft: spacing(2),
                marginBottom: spacing(2.25),
                '&.open': {
                    maxHeight: 1000,
                    '& > button': {},
                },
                '& > button': {
                    display: 'inline-flex',
                    lineHeight: '15px',
                    fontSize: spacing(1.5),
                    fontWeight: 'bold',
                    letterSpacing: '2.4px',
                    color: palette.primary.light,
                    textTransform: 'uppercase',
                    marginBottom: spacing(3.75),
                    padding: 0,
                },
            },
            _j),
        nurseryHeaderTypeList: (_k = {
                lineHeight: 2.33,
                marginBottom: spacing(1.5)
            },
            _k[breakpoints.down('xsMax')] = {
                textAlign: 'left',
            },
            _k[breakpoints.between('sm', 'mdMin')] = {
                marginBottom: 0,
                lineHeight: 1.75,
            },
            _k['& > li'] = {
                display: 'inline-block',
                fontSize: spacing(1.5),
                fontWeight: 'bold',
                letterSpacing: '1.7px',
                textTransform: 'uppercase',
                '&:before': {
                    content: '"•"',
                    marginLeft: spacing(1.25),
                    marginRight: spacing(1.25),
                },
                '&:first-child:before': {
                    display: 'none',
                },
            },
            _k),
        nurseryHeaderDescription: (_l = {
                fontSize: spacing(1.75),
                lineHeight: 2,
                color: palette.primary.dark,
                marginBottom: 50
            },
            _l[breakpoints.between('md', 'mdMax')] = {
                marginBottom: spacing(5),
                lineHeight: 1.8,
            },
            _l[breakpoints.between('sm', 'mdMin')] = {
                lineHeight: 1.5,
                marginBottom: spacing(1.25),
            },
            _l[breakpoints.down('xsMax')] = {
                marginBottom: spacing(1.25),
            },
            _l),
        nurseryHeaderInfoList: (_m = {},
            _m[breakpoints.down('xsMax')] = {
                textAlign: 'left',
            },
            _m['& > li'] = (_o = {
                    display: 'inline-block',
                    width: '50%',
                    fontSize: spacing(1.5),
                    fontWeight: 'bold',
                    letterSpacing: '2.4px',
                    color: palette.primary.light,
                    lineHeight: '30px',
                    textTransform: 'uppercase',
                    margin: spacing(0.75, 0)
                },
                _o[breakpoints.between('sm', 'mdMin')] = {
                    display: 'block',
                    width: '100%',
                    fontSize: spacing(1.5),
                    letterSpacing: '1px',
                },
                _o[breakpoints.down('xsMax')] = {
                    width: '100%',
                },
                _o['& > a'] = {
                    color: palette.primary.light,
                },
                _o['& > svg'] = (_p = {
                        width: spacing(3),
                        height: spacing(3.75),
                        float: 'left',
                        marginRight: spacing(1.25)
                    },
                    _p[breakpoints.between('sm', 'mdMin')] = {
                        marginRight: spacing(0.75),
                    },
                    _p),
                _o),
            _m),
        nurseryHeaderImages: (_q = {
                display: 'flex',
                flexWrap: 'wrap',
                width: 632,
                minWidth: 632,
                position: 'absolute',
                right: 0,
                '& > img': {
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    width: 316,
                    height: 316,
                }
            },
            _q[breakpoints.between('md', 'mdMax')] = {
                width: 500,
                minWidth: 500,
                '& > img': {
                    width: 250,
                    height: 250,
                },
            },
            _q[breakpoints.between('sm', 'mdMin')] = {
                width: 390,
                minWidth: 390,
                '& > img': {
                    width: 195,
                    height: 195,
                },
            },
            _q[breakpoints.down('xsMax')] = {
                top: 0,
                left: 0,
                right: 0,
                height: 100,
                width: 'auto',
                minWidth: 'initial',
                '& > img': {
                    width: '25%',
                    height: '100%',
                },
            },
            _q),
    });
});
export default useStyles;
